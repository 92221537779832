import { EntryBody } from "../components/Entry/EntryBody";

export const not_instructions: { [id: string]: any } = {
  id: "not_instructions",
  title: <>Not Instructions But (Language) Games</>,
  date: "11.06.23",
  Body: (
    <EntryBody
      paragraphs={[
        <div className="font-mono">
          Calling instructions to the new "multitask" pretraining and finetuning
          paradigms is a subtle but fatal mistake.
        </div>,
        <div className="font-mono">
          I am researching NLP not to create an AI Assistant, but to study
          language.
        </div>,
        <div className="font-mono">
          I do not want to instruct a system when I do instruction-tuning.
        </div>,
        <div className="font-mono">
          I want to make more explicit or clear the rules (probabilistic
          relationships) in a certain part (language-game) of our use of
          language.
        </div>,
        <div className="font-mono">
          The words we use to name new concepts frame the way we think about
          these concepts.
        </div>,
        <div className="font-mono">
          Naming NLP techniques after industry objectives is handing one of the
          most potentially beautiful fields humans have created to the capital
          (as future-entity-god). This is probably inevitable, but at least I
          want to engage on the beauty of the field on my lifetime, c"mon!
        </div>,
        <div className="font-mono">
          NLP has to draw from philosophy, not from industry, at least for the
          naming conventions and way investigations are conducted. (Don"t tell
          them I said this though I love them GPUs!!)
        </div>,
      ]}
    />
  ),
};
