import { Entry } from "../../components/Entry/Entry";
import { cover } from "../../entries/cover";

export const Cover = () => {
  return (
    <div className="flex flex-col items-center w-full h-full  p-5 pr-2 pl-2 sm:pr-5 sm:pl-5 space-y-1">
      <img
        className="border w-96 mb-10 hover:opacity-50 transition-opacity duration-1000"
        src={`${process.env.PUBLIC_URL}/assets/manga_beetles.webp`}
        alt="the_possibility_of_water_beetles"
      />
      <Entry title={cover.title} date={cover.date} Body={cover.Body} />
    </div>
  );
};
