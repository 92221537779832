import { EntryBody } from "../components/Entry/EntryBody";

export const notes_wsl: { [id: string]: any } = {
  id: "notes_wsl",
  title: <>Notes on Wissenschaftlehre and related texts, by Fichte</>,
  date: "2023",

  Body: (
    <EntryBody
      paragraphs={[
        <div className="font-mono"></div>,

        <div className="font-mono">
          CONCERNING THE CONCEPT OF WISSENSCHAFTLEHRE
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          p159 one Grundsatz (proposition certain a priori to its connection
          with others) in each science ~ foundational principle
        </div>,

        <div className="font-mono">
          p163 Wissenschaftlehre: (i) possibility of any foundational principles
          (ii) demostrate Grundsatz for all possible sciences
        </div>,

        <div className="font-mono">
          p165 _content_ of a proposition: which one knows something about;
          _form_: what one knows about this something
        </div>,

        <div className="font-mono">
          p173 positive criterion of exhaustion of WSL Grundsatz: foundational
          principle is final result
        </div>,

        <div className="font-mono">
          p176 ... in the foundational principle of a particular science an
          action that had been permitted to remain free within the WSL becomes
          determined
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          &gt; space/point -&gt; determine freedome by making a line -&gt;
          geometry
        </div>,

        <div className="font-mono">
          &gt; experiment of observig an object given a particular law -&gt;
          natural sciences
        </div>,

        <div className="font-mono">
          &gt; determination of separation of content & form (abstraction) -&gt;
          logic, form becomes the form of the form itself, as its content
          (reflection)
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          p182 This is how the WSL, qua systematic science, comes into being:
          like al possible sciences, it arises by means of a specific
          determination of freedom, and in this case the specific determination
          of freedom is the free [not necessary] action of raising to
          conciousness the manner in which the human mind as such acts ... the
          object of these other sciences is itself a free action, whereas the
          object of the WSL are necessary actions
        </div>,

        <div className="font-mono">
          Random notes: transcendental apperception ~ I think; empirical
          apperception ~ inner state; unity of apperception ~ transcendental
          self (necessary for experience)
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          FOUNDATION OF THE ENTIRE WISSENSCHAFTLEHRE
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          §1 Tathandlung ~ F/Act: I as self-positing (activity) and posited
          (product)
        </div>,

        <div className="font-mono">
          p201 A=A / A is A -&gt; one ascribes oneself the power to posit
          something purely & simply -&gt; posit "if A exists, then A exists"
          (necessary connection X) not whether A exists, only _form_ -&gt; X
          posited in I -&gt; "if A is posited in the I, then A is posited &
          therefore exists"
        </div>,

        <div className="font-mono">
          p203 I positing itself through itself is pure activity of the I, I am
          F/Act
        </div>,

        <div className="font-mono">
          p205 The I originally posits its own being purely and simply. Whatever
          is posited through the mere positing of anything (apply A=A to it)
          posseses _reality_ & belongs to the essence of the I
        </div>,

        <div className="font-mono">
          §2 p207 principle of contradiction not analitically derivable from
          principle of identity ...
        </div>,

        <div className="font-mono">
          p208 (because) positing in opposition (Gegensetzen) is not contained
          in positing (but opposed to it); conditioned in terms of content (to
          posit not-A need to posit A); conditioned by the unity of conciousness
          (not-A, A posited in the same I to be opposed)
        </div>,

        <div className="font-mono">
          p209 the form of this original positing in opposition (I is not not-I)
          is ... purely & simply unconditioned and its content conditioned (I)
          _negation_
        </div>,

        <div className="font-mono">
          §3 I & Not-I annul eachother -&gt; limiting (contains reality and
          negation -&gt; more abstraction) -&gt;
        </div>,

        <div className="font-mono">
          p213 -&gt; the I and the Not-I are posited as divisible, category of
          _quantifiability_
        </div>,

        <div className="font-mono">
          p214 the I posits in the I a divisible Not-I in opposition to the
          divisible I ... No philosophy can go beyond this cognition; but every
          well grounded philosophy should return to it.
        </div>,

        <div className="font-mono">
          p216 antithesis - seek characteristic feature through which they are
          posited as opposites
        </div>,

        <div className="font-mono">
          synthesis - seek characteristic in things posited in opposition in
          which they are the same
        </div>,

        <div className="font-mono">
          ... are logical rules derived from third foundational principle
        </div>,

        <div className="font-mono">
          p217 all these other synthesis must be contained in the original,
          highest synthesis [of the limited I & the limited Not-I]
        </div>,

        <div className="font-mono">
          p220 thetic judgements are grounded in the positing of the I
          schlechtin; "A is beautiful" thetic because no ideal to compare to
          -&gt; "infinite" judgement
        </div>,

        <div className="font-mono">
          further down the page of the notebook we find: fe -&gt; dolla -&gt;
          cyber?! (fe means faith)
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          PART II - FOUNDATIONS OF THEORETICAL KNOWLEDGE
        </div>,
      ]}
    />
  ),
};
