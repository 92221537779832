
import { EntryBody } from "../components/Entry/EntryBody";

export const notes_heidegger: { [id: string]: any } = {

    id: "notes_heidegger",
    title: <>Notes on Heidegger</>,
    date: "2023-2024",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
Being & Time: Introduction

</div>,

<div className="font-mono">
Pages from the Basic Writtings book. (red one)

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
43. Hegel ... defines "Being" as the "indeterminate Immediate"

</div>,

<div className="font-mono">
    problem of unity of Being in contrast to the manifold of "categories" with material content (raised by aristotle)

</div>,

<div className="font-mono">
44. The indefinability fof Being does not dispense with the question of its meaning but forces it upon us

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
II. The formal structure of the question of Being

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
45. Questioning is a knowing search for beings in their thatness & whatness

</div>,

<div className="font-mono">
    questioning has _what it asks_

</div>,

<div className="font-mono">
    questioning needs previous guidance from what it seeks. The meaning of Being must therefore already be available to us in a certain way

</div>,

<div className="font-mono">
    [Da-sein "there being"]

</div>,

<div className="font-mono">
46. the Being of beings "is" itself not a being

</div>,

<div className="font-mono">
47. ... beings themselves turn out to be what is _interrogated_ in the question of Being

</div>,

<div className="font-mono">
48. This being which we ourselves in each case are and which includes the inquiry among the possibilities of its Being we formulate terminologically as Dasein

</div>,

<div className="font-mono">
    The explicit & lucid formulation of the question of the meaning of Being requires a prior suitable explication of a being (Dasein) with regard to its Being

</div>,

<div className="font-mono">
49. ... the being that has the character of Dasein has a relation to the question of Being itself

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
III. The ontological priority of the question of Being

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
50. Being is always the Being of a being. The totality of beings can, wrt its various domains, become the field where definite areas of knowledge are exposed & delimited ... thematic objects of scientific investigations (ontic)

</div>,

<div className="font-mono">
51. The real movement of the sciences takes place in the revision of these basic concepts ... mathematics ... "crisisi in its foundations"

</div>,

<div className="font-mono">
52. Fundamental concepts are determinations in which the area of knowledge underlying all thematic objects of a science attain an understanding that precedes & guides all positive investigation

</div>,

<div className="font-mono">
53. All ontology ... system of categories ... remains fundamentaly blind ... if it has not clarified the meaning of Being

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
IV. The ontic priority of the question of Being

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
53. "ontological" refers to the Being of beings

</div>,

<div className="font-mono">
    "ontic" ... dealing with beings without raising the ontological question (sciences)

</div>,

<div className="font-mono">
54. Dasein is a being that does not simply occur among other beings. Rather it is ontically distinguished by the fact that in its Being this being is concerned _about_ its very being. [the first AI is sentient blowout with the google priest, the conversation heavily focused on this, something truly fundamental of what we think human nature to be?]

</div>,

<div className="font-mono">
55. Dasein always understands itself in terms of its existence, in terms of its possibility to be itself and not be itself.

</div>,

<div className="font-mono">
    Sciences & disciplines are ways of Being of Dasein in which Dasein relates also to beings that it need not itself be. But _being in a world_ belongs essentially to Dasein.

</div>,

<div className="font-mono">
    \*existentielle (existentiell) the way Dasein ... exists by realizig or ignoring its various (ontic) possibilities

</div>,

<div className="font-mono">
    existenzial (existential) the possibility of Dasein to inquire into the structure of life (existentialit"at)

</div>,

<div className="font-mono">
56. Only when philosophical research & inquiry themselves are grasped in an existentiell way -as a possibility of being of each existing Dasein- does it become possible at all to disclose the existentiality of existence... get hold... of a sufficiently grounded set of ontological problems

</div>,

<div className="font-mono">
    he psyche ta onta pos (panta) estin - The soul of man is in a certain way (all) beings

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Being & Time - Division one

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
... to transcribe from notebook ...

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Being & Time - Division Two

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
DASEIN AND TEMPORALITY

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§45. The Outcome of the Preparatory Fundamental Analysis of Dasein, and the Task of a Primordial Existential Interpretation of this Entity 

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Ontological investigation is a possible kind of interpreting, which we have described as the working-out and appropiation of an understanding. ... In ontological Interpretation an entity is to be laid bare with regard to its own state of Being

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Can we progress from the result we have obtained - that the being of Dasein is care - to the question of the primordial unity of this structural whole?

</div>,

<div className="font-mono">
... existence as potentiality-for-Being ... mine, ... free for authenticity or for inauthenticity

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
In starting with average everydayness, our Interpretation has heretofore been confined to the analysis of such existing as is either undifferentiated or inauthentic.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Everydayness is precisely that Being which is 'between' birth and death. ... as long as Dasein exists it must ... as such potentiality, not yet be something.

</div>,

<div className="font-mono">
If the Interpretation of Dasein is to become primordial ... must first have brought to ligh existentially the Being of Dasein in its possiilities of authenticity and totality.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
... Dasein"s Being-at-an-end in death, and therewith its Being-a-whole ... But as something of the character of Dasein, death is only an existentiell Being towards death [Sein zum Tode].

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
the primordial ontological basis for Dasein"s existentiality is temporality

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
I DASEIN"S POSSIBILITY OF BEING-A-WHOLE, AND BEING-TOWARDS-DEATH 

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§46. The Seeming Impossibility of Getting Dasein"s Being-a-whole into our Grasp Ontologically and Determining its Character 

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
... care is that which forms the totality of Dasein"s structural whole. Yet the primary item in care is the 'ahead-of-itself', and this means that in each case Dasein exists for the sake of itself. 

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
It is essential to the basic constitution of Dasein that there is constantly something still to be settled ... lack of totality ... something still outstanding in one"s potentiality-for-Being.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
As long as Dasein is an entity, it has never reached its 'wholeness'. But if it gains such 'wholeness', this gain becomes the utter loss of Being-in-the-world.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
This question - both the existentiell question of whether a potentiality-for-Being-a-whole is possible, and the existential question of the state-of-Being of 'end' and 'totality'- ...

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§47. The Possibility of Experienceing the Death of Others, and the Possibility of Getting a Whole Dasein into our Grasp

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The end of the entity qua Dasein is the beggining of the same entity qua something present-at-hand.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
... 'more' than a lifeless material Thing ... something unalive, which has lost its life.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
... one presupposes when one is of the opinion that any Dasein may be substituted for another at random, so that what cannot be experienced in one"s own Dasein is accessible in that of a stranger.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
... the fact that one Dasein can be represented by another belogns to its possibilities of Being in Being-with-one-another in the world.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
'One is' what one does. ... (everyday manner in which we join with one another in absorption in the 'world of our concern) ... Here one Dasein can and must, within certain limits, 'be' another Dasein.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
In 'ending' .. there is ... no representing.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
ending of anything that is alive 'perishing'

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§48. That which is Still Outstanding; the End; Totality

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
1. there belongs to Dasein ... a 'not yet' which it will be - that which is constantly still outstanding; 2. the coming-to-its-end of what-is-not-yet-at-an-end (in which what is still outstanding is liquidated as regards its Being) has the character of no-longer-Dasein; 3. coming-to-an-end implies a mode of Being in which the particular Dasein simply cannot be represented by someone else.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
In Dasein there is undeniably a constant 'lack of totality' which finds an end with death. ... 'not-yet' ... outstanding ... is grounded upon a belonging to.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Entities for which anything is still outstanding have the kind of Being of something ready-to-hand.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The 'not-yet' which belongs to Dasein, however, is not just something which is provisionally and occasionally inaccessible to one"s own experience ... ; it 'is' not yet 'actual' at all.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
fruit ripening kek

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
245 ... ending, as stoppping, can signify either 'passing over into non-pressence-at-hand' (road) or else 'Being-present-at-hand only when the end comes' (last stroke of painting).

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
By none of these modes of ending can death be suitably characterized as the 'end' of Dasein. ... death, does not signify Dasein"s Bring-at-an-end, but a Being-towards-the-end of this entity. Death is a way to be, which Dasein takes over as soon as it is.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§49. How the Existential Analysis of Death is Distinguished from Other Possible Interpretations of this Phenomenon

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Death is a phenomenon of life. Life ... kind of Being to which there belongs a Being-in-the-world.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Underlying this biological-ontical exploration of death ... ask how the ontological essence of death is defined in terms of that of life.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
'dying' ... way of Being in which Dasein is towards its death.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
our analysis of death ... interprets the phenomenon merely in the way in which it enters into any particular Dasein as a possibility of Being.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§50. Preliminary Sketch of the Existential-ontological Structure of Death

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
'care' basic state of Dasein ... 'ahead-of-itself-Being-already-in (the world) as Being-alongside entites we encounter (within-the-world)' ... fundamental characteristics of Dasein"s Being: existence, in 'ahead-of-itself'; facticity, in the 'Being-already-in'; falling, in the 'Being-alongside'.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
'not-yet' has the character of something towards which Dasein comports itself.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
251 Death is the possibility of the absolute impossibility for Dasein. ... non-relational ... distinctively impending

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
anxiety ... disclosedness of the fact that Dasein exists as thrown Being towards its end.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Existence, facticity, and falling characterize Being-towards-the-end ... care.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
if Being-toards-death belongs primordially and essentially to Dasein"s Being, then must also be exhibitabl in everydayness 

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§51. Being-towards-death and the Everyday of Dasein

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
the Self of everydayness is the 'they' ... the way things have been publicly interpreted ... idle talk. ... ask how Being-towards-death is disclosed by the kind of understanding which, with its state-of-mind [mood], lurks in the idle talk of the 'they'.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
In Dasein"s public way of interpreting, it is said that 'one dies', because everyone els and oneself can talk himself into saying that 'in no case is it I myself', for this 'one is the 'nobody'.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
the 'they' provides a constant tranquilization about death

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The 'they' does not permit us the courage for anxiety in the face of death.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
But temptation, tranquilization, adn alienation are distinguishing marks of the kind of Being called 'falling'. As falling, everyday Being-towards-death is a constant fleeing in the face of death.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§52. Everyday Being-towards-the-end, and the Full Existential Conception of Death

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
...

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§53. Existential Projection of Authentic Being-towards-death

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
...

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Death ... possibility of the impossibility of any existence at all ... as far as possible from anything actual.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Anticipation ... the possibility of understanding one"s ownmost and uttermost potentiality-for-Being ... authentic existence.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
263 Death is Dasein"s ownmost possibility. .... This means that in anticipation any Dasein can have wrenched itself away from the 'they' already. But when one understands that this is something which Dasein 'can' have done, this only reveals its factical lostness in the everydayness of the they-self. &lt;- moneymaker!

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
non-relational ... The non-relational character of death, as understood in anticipation, individualizes Dasein down to itself. ... Dasein is authentically itself only to the extent that, as concernful Being-alongside and solicitous Being-with, it projects itself upon its ownost potentiality-for-Being rather than upon the possibility of the they-self.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
not to be outstripped ... understand that giving itself up impends for it as the uttermost possibility of its existence.

</div>,

<div className="font-mono">
Free for its ownmost possibilities, which are determined by the end and so are understood as finite, Dasein dispels the danger that it may, by its own finite understanding of existence, fail to recognize that it is getting outstripped by the existence-possibilities of Others

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
265 certainty in death different from in entities encountered within-the-world (evidence) for formal objects (apodeicitc), indefinite

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
In anticipating the indefinite certainty of death, Dasein opens itself to a constant threat arising out of its own 'there'. ... But the state of mind which can hold open the utter and constant threat to itself arising from Dasein"s ownmost individualized Being, is anxiety. 

</div>,

<div className="font-mono">
[this relates it to being the mood that allows u to "see thw world"? the mood that separates you from others, individualizes, beause only in death (or authentic being) one is individual, that is why the they disregards it (disregards the possibility of non-relational and not to be outstripped, because they take all possibilities to be outstrippable (so that you get one of 'their' possibilities))]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
266 Being-towards-death is essentially anxiety.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
anticipation reveals to Dasein its lostness in the they-self, and brings it face to face with the possibility of being itself, pirmarily unsupported by concernful solicitude, but of being itself, rather, in an impassioned (((freedom towards death))) - a freedom which has been released from the Illusions of the 'they', and which is factical, certain of itself, and anxious.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Does Dasein ever factically throw itself into such a Being-towards-death? (or is it only ontological possibility)

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
II Dasein"s Attestation of an Authentic Potentiality-for-Being, and Resoluteness

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§54. The Problem of How an Authentic Existentiell Possibility is Attested

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
For the most part I myself am not the who' of Dasein; the they-self is its 'who'. Authentic Being-one"s-Self takes the definite form of an existentiell modification of the 'they'.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Dasein ... brings itself back from its lostness in the 'they' ... by making up for not choosing.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
269 Conscience gives us 'something' to understand; it discloses. ... Calling is a mode of discourse/ The call of conscience has the character of an appeal to Dasein by calling it to its ownmost potentiality-for-Being-its-Self; and this is done by summoning it to its ownmost Being-guilty.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Accordingly our existential Interpretation needs to be confirmed by a critique of the way in which conscience is ordinarily interpreted. 

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
... the choosing to chose a king* of Being-one"s-Self ... 'resoluteness.

</div>,

<div className="font-mono">
*[this is an honest typo from 'kind' but i leave it here, iss funny!]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§55. The Existential-ontological Foundations of Conscience

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Conscience discloses ... Being of the "there" as disclosedness.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Dasein ... can listen to Others. Losing itself in the publicness and the idle talk of the 'they', it fails to hear its own Self in listening to the they-Self.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
... calling as a mode of discourse .. not a picture ... the 'voive is taken rather as a giving-to-understand.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§56. The character of conscience as a call

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">


</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
.........

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
III DASEIN"S AUTHENTIC POTENTIALITY-FOR-BEING-A-WHOLE, AND TEMPORALITY AS THE ONTOLOGICAL MEANING OF CARE 

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§61. A Preliminary Sketch of the Methodological Step from the Definition of Dasein"s Authentic Being-a-whole to the Laying-bare of Temporality as a Phenomenon 

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
... Being-towards-death is anticipation. ... Dasein"s authentic potentiality-for-Being ... resolutness.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
In our existential Interpretation, the entitiy whic has been presented to us as our theme has Dasein"s kind of Being, and cannot be pieced together into something present-at-hand out of pieces which are present-at-hand.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§62. Anticipatory Resoluteness as the Way in which Dasein"s Potentiality-for-Being-a-whole has Existential Authenticity 

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">


</div>,

<div className="font-mono">


</div>,

<div className="font-mono">


</div>,

<div className="font-mono">


</div>,

<div className="font-mono">


</div>,

<div className="font-mono">


</div>,

<div className="font-mono">


</div>,

<div className="font-mono">


</div>,

<div className="font-mono">


</div>,

<div className="font-mono">


</div>,

<div className="font-mono">


</div>,

<div className="font-mono">


</div>,

<div className="font-mono">


</div>,

<div className="font-mono">


</div>,

<div className="font-mono">


</div>,

<div className="font-mono">


</div>,

<div className="font-mono">


</div>,

<div className="font-mono">


</div>,

<div className="font-mono">


</div>,

<div className="font-mono">


</div>,

<div className="font-mono">


</div>,

      ]}
    />
  ),
};
