
import { EntryBody } from "../components/Entry/EntryBody";

export const notes_frege: { [id: string]: any } = {

    id: "notes_frege",
    title: <>Notes on different readings; Frege</>,
    date: "Sept 23",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
a dear friend"s interest in philosophy dwindled down heavily after reading some Frege.... i can see why kek

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
UEBER SINN UND BEDEUTUNG (ON SENSE AND REFERENCE)

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
25 Identity: I use this word strictly and understand "a=b" to have the sen same as b" or "a and b coincide."

</div>,

<div className="font-mono">
26 A difference [between a=a and a=b] can arise only if the difference between the signs corresponds to a difference in the mode of presentation of that which is designated

</div>,

<div className="font-mono">
referent: that to which the sign refers

</div>,

<div className="font-mono">
_sense_ of teh sign: wherein the mode of presentation is contained

</div>,

<div className="font-mono">
27/28 To every expression belonging to a complete totality of signs, there should certainly correspond a definite sense; but natural languages often do not satisfy this condition, and one must be content if the same word has the same sense in the same contex

</div>,

<div className="font-mono">
28 In reported speech, words are used _indirectly_ ... The indirect referent of a word is accordingly its customary sense.

</div>,

<div className="font-mono">
29 If the referent of a sign is an object pe by the senses, my conception of it is an internsl image

</div>,

<div className="font-mono">
30 exact comparisons between conceptions is not possible ... we cannot have both conceptions together in the same consciousness

</div>,

<div className="font-mono">
We can now recognize three levels of difference between words, expressions, or whole sentences. The difference may concern at most the conceptions, or the sense but not the referent, or, finally, the referent as well.

</div>,

<div className="font-mono">
31 A proper name (word, sign, sign combination, expression) _expresses_ its sense, _refers to_ or _designates_ its referent

</div>,

<div className="font-mono">
32 Thought: By a thought I understand not the subjective performance of thinking but its objective content, which is capable of being the common property of several thinkers

</div>,

<div className="font-mono">
33 ... why do we want every proper name to have not only a sense, but also a referent? ... Because ... we are concerned with its truth value

</div>,

<div className="font-mono">
34 ... the truth value of a sentence as its referent [true & false as objects]

</div>,

<div className="font-mono">
... examples of noun/adj/adv clauses and their effect on truth value/if they have referent etc ...

</div>,

<div className="font-mono">
41 A logically complete language (Begriffsschrift) should satisfy the conditions, that every expression grammatically well constructed as a proper name out of signs already introduced shall in fact designate an object, and that no new sign shall be introduced as a proper name without having a referent assured

</div>,

<div className="font-mono">
46 The subordinate clause usually has for its sense not a thought, but only a part of one, and consequently no truth value as referent.

</div>,

<div className="font-mono">
47 The clause expresses more through its connection with another than it does in isolation

</div>,

<div className="font-mono">
49 a subordinate clause may not always be replaced by another of equal truth value without harm to the truth of the whole sentence structure

</div>,

<div className="font-mono">
50 judgement: the advance from the thought to its truth value

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
THE THOUGHT: A LOGICAL INQUIRY

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p289 THE word "true" indicates the aim of logic as does "beautiful" that of aesthetics or "good" that of ethics

</div>,

<div className="font-mono">
p290 I assign to logic the task of discovering the laws of truth, not of assertion or thought. The meaning of the word "true" is explained by the laws of truth.

</div>,

<div className="font-mono">
p291 So the attempt to explain truth as correspondence collapses (idea != reality).

</div>,

<div className="font-mono">
p292 "My idea corresponds to Cologne Cathedral" is a sentence and the question now arises of the truth of this sentence. So what is improperly called the truth of pictures and ideas is reduced to the truth of sentences.

</div>,

<div className="font-mono">
I call a thought something for which the question of truth arises ... The thought, in itself immaterial, clothes itself in the material garment of a sentence and thereby becomes comprehensible to us. We say a sentence expresses a thought

</div>,

<div className="font-mono">
being true is not a material, perceptible property

</div>,

<div className="font-mono">
p293 [the sense of] sentences in which we communicate or state something [is a thought]

</div>,

<div className="font-mono">
p294

</div>,

<div className="font-mono">
(1) the apprehension of a thought-thinking,

</div>,

<div className="font-mono">
(2) the recognition of the truth of a thought-judgment,

</div>,

<div className="font-mono">
(3) the manifestation of this judgment-assertion

</div>,

<div className="font-mono">
p295 What is called mood, fragrance, illumination in a poem, what is portrayed 'by cadence and rhythm, does not belong to the thought

</div>,

<div className="font-mono">
p296 [examples for non-linear rules of langauge??] A sentence can be transformed by changing the verb from active to passive and making the object the subject at the same time. In the same way the dative may be changed into the nomin4tive while "give" is replhced by "receive".

</div>,

      ]}
    />
  ),
};
