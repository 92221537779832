import { EntryBody } from "../components/Entry/EntryBody";

export const a_funny_reflection_on_cells: { [id: string]: any } = {
  id: "a_funny_reflection_on_cells",
  title: <>A Funny Reflection on Cells :P</>,
  date: "13/7/23",

  Body: (
    <EntryBody
      paragraphs={[
        <div className="font-mono"></div>,

        <div className="font-mono">
          I was eyeing how massive mediocrity is necessary for wealth
          accumulation.
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          (Just as active creativity needs to be surrounded by vast amounts of
          passivity, in order to be.)
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          Then I realized a neuron surely thinks the cells in our biceps are
          mediocre.
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          Maybe the history we are in is much bigger.
        </div>,
      ]}
    />
  ),
};
