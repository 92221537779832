
import { EntryBody } from "../components/Entry/EntryBody";

export const danger_and_saving_of_language_modeling: { [id: string]: any } = {

    id: "danger_and_saving_of_language_modeling",
    title: <>The Danger and Saving of Language Modeling</>,
    date: "12 Dec 2023",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
The existential risk of AI is not that of a chaos in Apochalypse, but that of an [apparent] ordering of our language [and eventuallly our experience], the very thing where any freedom can be found.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
What is the effect of money in the objects of experience? Money is a partial ordering on everything that can be, every object is now comparable and compared to every other, and the Scale of this comparison is out of control of any one human. [It is weird to think that now objects in respectively subjective human experiences are not inmediately ordered by those same humans!] Now on thinking about objects, we think of value in the money Scale. If this is brought to the extreme, we are losing freedom, or at least something where freedom may be: in thinking about the chaos of objects in experience.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
What is the effect of language modeling in language? Given a context, a LM gives a probability to each sentence. Given a question, a LM is a Scale of sentences, a partial ordering of all possible answers, by the probability that it assigns to them. LMs are going to be the gate to knowledge (not to data, important observation), because language is knowledge ['is' here meant as identity]. Then, the answer to a question is not a chaos to explore, but rather an ordered set of sentences, stronger: a necessarily ordered set of sentences. Answers as standing-reserve, lined-up with every question in every knowledge. When this becomes the standard way of answering: what is the point of questioning?!

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
On current times, there is a clear medicine for the existential risk of AI: control generation, shift the distributions of answers to accepted ranges, remove unpredictable behaviours, be a helpful assistant. An algorithm that models language inside a GPU is not going to detonate a warhead, but it is surely going to order language. The medicine for the existential risk of AI is the existential risk of AI. If language is ordered, what is the point of thinking?!

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
[A parallel idea will run here with "What is the effect of 'AGI' on human experience?", but I prefer to run with language.]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Note: Written after reading "The Question Concerning Technology" by Heidegger

</div>,

      ]}
    />
  ),
};
