
import { EntryBody } from "../components/Entry/EntryBody";

export const notes_philosophical_investigations: { [id: string]: any } = {

    id: "notes_philosophical_investigations",
    title: <>Notes: Philosophical Investigations by Wittgenstein</>,
    date: "2023",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">
This are (almost) verbatim extracts from Philosophical Investigations by Wittgenstein, for personal use.

</div>,

<div className="font-mono">
I was transcribing these from my notebook to VSC & github copilot kept autocompleting the correct quotes kek.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Part I

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p4 Here the teaching of language is not explanation but training.

</div>,

<div className="font-mono">
p5 With different training the same ostensive teahcing of these words would have effected a quite different understanding. (how does "ostension" relate to MLM?)

</div>,

<div className="font-mono">
p7 meaning something is like attaching a label to a thing ... reckon the samples as instruments of language

</div>,

<div className="font-mono">
p8 and to imagine a language menas to imagine a form of life

</div>,

<div className="font-mono">
§27 we are brought up, trained, to ask: "What is that called?"—upon which the name is given (language-game)

</div>,

<div className="font-mono">
§29,30 how he 'takes' the (ostensive) definition is seen in the use that he makes of the word

</div>,

<div className="font-mono">
§31 only someone who already knows how to do something with it can significantly ask a name

</div>,

<div className="font-mono">
§36 where our language suggests a body and there is none: there, we should like to say, is a spirit. (point to a thing/point to a colour)

</div>,

<div className="font-mono">
§38 ... a tendency to sublime the logic of our language

</div>,

<div className="font-mono">
§40 "a word has no meaning if nothing corresponds to it" (confusion meaning vs bearer of name)

</div>,

<div className="font-mono">
§43 the meaning of a wrod is its use in the language. the meaning of a name is sometimes explained by pointing to its bearer.

</div>,

<div className="font-mono">
§?? The demostrative "this" can never be without a bearer. not a name, for a name is not used with, but only explained by means of the gesture of pointing

</div>,

<div className="font-mono">
§58 restrict the term "name" to what cannot occur in the combination "x exists"

</div>,

<div className="font-mono">
§65 No essence of language in langauge-games, just different relationships between one another

</div>,

<div className="font-mono">
§66 Don"t think, but look! (can we use LLMs to look at language?)

</div>,

<div className="font-mono">
§67 'family resemblances' (similarities, games from a family)

</div>,

<div className="font-mono">
§75 isn"t my knowledge, my concept of a game, completely expressed in the explanations that I could give?

</div>,

<div className="font-mono">
Moses/N no fixed meaning -- can this happen on LLms?!

</div>,

<div className="font-mono">
if anyone utters a sentence & means or understands it he is operatig a calculus according to definite rules

</div>,

<div className="font-mono">
§83 language as ball-games in beack comparison

</div>,

<div className="font-mono">
§87 an explanation serves to remove ... a misunderstanding ... that would occur but for the explanation, not every one I can imagine

</div>,

<div className="font-mono">
§88 not a ingle diea of exactness has been laid down

</div>,

<div className="font-mono">
§89 in what sense is logic something sublime?

</div>,

<div className="font-mono">
§90 we feel as if we had to penetrate phenomena, our ivestigation, however, is directed not towards phenomena but ... towards the 'posibilities' of phenomena ... kind of statements we make about phenomena ... our investigation is therefore a grammatical one

</div>,

<div className="font-mono">
§94 the tendency to assume a pure intermediary between the propositional signs and the facts

</div>,

<div className="font-mono">
§97 thought is surrounded by a halo. in essence, logic presents ... the a priori order of the world ... the order of possibilities, which must be common to both world & thought ... utterly simple ...

</div>,

<div className="font-mono">
§101 we want to say there can"t be any vagueness in logic ... the ideal 'must' be found in reality

</div>,

<div className="font-mono">
§107 the more narrowly we examine actual language, the sharper becomes the conflict between it & our requirement (crystalline purity of logic)

</div>,

<div className="font-mono">
§108 the question "What is a word really?" is analogous to "What is a piece in chess?"

</div>,

<div className="font-mono">
§109 Philosophy is a battle against the bewitchment of our intelligence by means of language

</div>,

<div className="font-mono">
§111 The problems arising through a misinterpretation of our forms of language have the character of depth.

</div>,

<div className="font-mono">
§116 When philosophers use a word ... & try to grasp the essence of the thing ... is the word ever actually used in this way in the language-game which is its original home?

</div>,

<div className="font-mono">
§125 we lay down rules ... follow the rules, things do not turn our as we assumed ... we are ... tangled in our own rules

</div>,

<div className="font-mono">
-----

</div>,

<div className="font-mono">
§136 "This is how things are" as the general form of propositions ... proposition when in our language we apply the calculus of truth functions to it.

</div>,

<div className="font-mono">
§137 "Ask yourself if you can say 'is true' after it. if these words fit, it"s a proposition" (LLMs cna do this)

</div>,

<div className="font-mono">
§142 It is only in normal cases that the use of a word is clearly prescribed.

</div>,

<div className="font-mono">
§150 the grammar of ... 'knows' is ... closely related to that of 'can', 'is able to', 'understands' 59e. grammatical investigation 

</div>,

<div className="font-mono">
§155 but for us it is the circumstances under which we had such an experience that justify him in saying in such a case that he understands, that he knows how to go on

</div>,

<div className="font-mono">
§157 very LLM/MT beginnig to a seires of examples around 'reading'

</div>,

<div className="font-mono">
§167 ... the look of a word is familiar to us in the same kind of way as its sound. characteristicsd around reading ... letters as reason, influence, being guided

</div>,

<div className="font-mono">
§175 ... while I"m being guided everything is quite simple, I notice nothing special ... afterwards no description satisfies me ... I feel as if there must have been something else

</div>,

<div className="font-mono">
§177 I should like to say 'I experience the because' ... because when I reflect on what I experience in such case (guided) I look at it through the medium of the concept because ('influence', 'cause', 'connexion')

</div>,

<div className="font-mono">
§182 examples of the grammar of 'to fit', 'to be able', 'to understand'

</div>,

<div className="font-mono">
§193 mahcine-as-symbol ... movements of the machine as if they had to be already present ... possibility of movement

</div>,

<div className="font-mono">
§196 in our failure to understand the use of a word we take it as the expression of a queer process (time, mind)

</div>,

<div className="font-mono">
§199 to obey a rule, to make a report, to give an order, to play a game of chess, are customs (uses, intuitions) ... to understand a sentence is to understand a language ... to understand a language means to be master of a technique

</div>,

<div className="font-mono">
§201 paradox: no course of action could be determined by a rule, because every course of action can be made out to accord (confilct) with the rule ... grasping a rule ... not an interpretation ... 'obeying a rule' 'going against it'

</div>,

<div className="font-mono">
§202 'obeying a rule' is a practice

</div>,

<div className="font-mono">
§206 the common behaviour of mankind is the system of reference by means of which we interpret an unknown language

</div>,

<div className="font-mono">
... series & rules ...

</div>,

<div className="font-mono">
§232 these are not my experiences of acting from inspiration and according to rule, they are grammatical notes.

</div>,

<div className="font-mono">
§241 It is what human beings say that is true and false, and they agree in the language they use. That is not agreement in opinions but in form of life

</div>,

<div className="font-mono">
§242 if language is to be a means of communication there must be agreement not only in definitions but also ... in judgements (method of measurement vs obtained meausres)

</div>,

<div className="font-mono">
§244 the verbal expression of pain replaces crying & does not describe it 

</div>,

<div className="font-mono">
§246 i *know* i am in pain. What is it supposed to mean -- except perhaps that I *am* in pain?

</div>,

<div className="font-mono">
§251 'I can"t imagine the opposite' ... defence against something whose form makes it look like an empirical proposition, but which is really a grammatical one. ... "Every rod has a length" ~ negation of proposition a priori ... "This body has extension"

</div>,

<div className="font-mono">
§255 The philosopher"s treatment of a question is like the treatment of an illness

</div>,

<div className="font-mono">
§257 when we speak of someone"s having given a name (private) to pain, what is presupposed is the existence of the grammar of the word 'pain'

</div>,

<div className="font-mono">
§268 right hand giving left hand money ~ private definitions 

</div>,

<div className="font-mono">
§288 if I assume the abrogation of the normal language-game with the expression of a sensation, I need a criterion of identity for the sensation, & then the possibility of error also exists

</div>,

<div className="font-mono">
§293 if we construe the grammar of the expression of sensation on the model of 'object & name' the object drops out of consideration as irrelevant (beetle in private box example) 

</div>,

<div className="font-mono">
§305 what we deny is that the picture of the inner process gives us the correct idea of the use of the word 'to remember'

</div>,

<div className="font-mono">
§317 misleading parallels: the expression of pain is a cry -- the expression of thought, a proposition.

</div>,

<div className="font-mono">
§322 what the expression means is not answered by such a description; and this misleads us into concluding that understanding is a specific indefinable experience

</div>,

<div className="font-mono">
§329 the language itself is the vehicle of thought. When I think in language, there aren"t meanings going through my mind in addition to the verbal expressions

</div>,

<div className="font-mono">
§337 an intention is embedded in its situation, in human customs and institutions ... insofar as I do intend the construction of a sentence in advance, that is made possible by the fact that I can speak the language in question

</div>,

<div className="font-mono">
§344 "If people always said things only to themselves, then they would merely be doing *always* what as it is they only do *sometimes*" ... tell us & the rest of his behaviour ... he can speak

</div>,

<div className="font-mono">
§345 if no orders were ever obeyed ... the concept 'order' would have lost its purpose

</div>,

<div className="font-mono">
§346 but couldn"t we imagine God suddenly giving a parrot understanding, and it is now saying things to itself? But here it is an important fact that I imagined a deity in order to imagine this.

</div>,

<div className="font-mono">
§352 thinking plays us a queer trick ... excluded middle ... (is 777 in the decimals of pi)

</div>,

<div className="font-mono">
...

</div>,

<div className="font-mono">
§445 it is in language that an expectation & its fulfilment make contact

</div>,

<div className="font-mono">
§446 ... but language abstrats from thia difference, for it speaks of a red patch whether it is there or not

</div>,

<div className="font-mono">
§452 ... if you see the expression of an expectation, you see what is being expected

</div>,

<div className="font-mono">
...

</div>,

<div className="font-mono">
§500 when a sentence is called senseless, it is not as it were its sense that is senseless. But a combination of words is being excluded from the language, withdrawn from circulation.

</div>,

<div className="font-mono">
§512 "word-language allows of senseless combinations of words, but the language of imagining does not allow us to imagine anything sneseless."

</div>,

<div className="font-mono">
§531 We speak of understanding a sentence in the sense in which it can be replaced by another which says the same; but also in the sense in which it can"t be replaced by any other. (understanding a poem)

</div>,

<div className="font-mono">
§569 language is an instrument. its concepts are instruments ... ehich concepts we employ matters ... if calculations in some system /// demand more time & trouble than ... possible

</div>,

<div className="font-mono">
§571 misleading parallels: psychology treats of processes in the psychical sphere, as does physics in the physical

</div>,

<div className="font-mono">
§574 believing is not thinking (a grammatical remark)

</div>,

<div className="font-mono">
§585 when someone says "I hope he"ll come" -- is this a report about his state of mind or a manifestation of his hope?

</div>,

<div className="font-mono">
§593 a main cause of philosophical disease -- a one-sided diet: one nourishes one"s thinking with only one kind of example

</div>,

<div className="font-mono">
§607 guessing time example -- atmosphere of the sentence

</div>,

<div className="font-mono">
§632 we can often predict a man"s actions from his expression of a decision. An important langauge-game

</div>,

<div className="font-mono">
§ ... decisions, intentions in past

</div>,

<div className="font-mono">
§652 the reader of the narrative (example) understands this "... he supplies the meaning, he guesses it ..." ... no ... the main thing he guesses at is context ... if you want to understand a sentence, you have to imagine the physical significance, the states of mind involved.

</div>,

<div className="font-mono">
§654 our mistake is to look for an explanation where we ought to look at what happens as a 'proto-phenomenon'. That is, where we ought to have said: *this language game is played*.

</div>,

<div className="font-mono">
§655 the question is not one of explaining a language-game by means of our experiences, but of noting a language-game

</div>,

<div className="font-mono">
§656 ... look on the language-games as the *primary* thing. And look on the feelings, etc, as you look on a way of regarding the language-game, as interpretation.

</div>,

<div className="font-mono">
§ ... meaning someone

</div>,

<div className="font-mono">
§692 "to mean it" does not mean: to think of it

</div>,

<div className="font-mono">
§693 ... how different the grammar of the verb "to mean" is from that of "to think"! and nothing is more wrong-headed than calling meaning a mental activity! (~ raising prices of butter)

</div>,

<div className="font-mono">
§

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Part II

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
vi. the meaning of a word is not the experience one has in hearing or saying it, and the sense of a sentence is not a complex of such experiences ... thus the atmosphere that is inseparable from its object -- is not an atmosphere.

</div>,

<div className="font-mono">
vii. "The mind seems able to give a word meaning" -- isn"t this as if I were to say "The carbon atoms in benzene seem to lie at the coreners of a hexagon?" But this is not something that seems to be so, it is a picture ... the picture ... already points to a particular use. This is how it takes us in.

</div>,

<div className="font-mono">
ix. Describing my state of mind (of fear, say) is something I do in a particular context (just as it takes a particular context to make a certain action into an experiment) ... "I am afraid" not always a description of a state of mind

</div>,

<div className="font-mono">
x. Moore"s paradox ("P & I believe Not-P") One can mistrust one"s own sense, but not one"s own belief

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Two uses of the word "see": "What do you see here?" "I see this" + description/copy; "I see a likeness between these two faces" ... The importance of this is the categorical difference between the two objects of sight.

</div>,

<div className="font-mono">
... duck/rabbit drawings ...

</div>,

<div className="font-mono">
p200 ... look at all that can be meant by "description of what is seen" -- But this is just what is called description of what is seen. There is not *one genuine* proper case of such description -- the rest being just vague ...

</div>,

<div className="font-mono">
... enormous danger to make fine distinctions ... as ... when one tries to define the concept of a material object in terms of 'what is really seen' -- What we have rather to do is to *accept* the everyday langauge-game, and to note *false* accounts of the matter *as* false. The primitive language-game ehich children are taught needs no justification; attemps at justification need to be rejected.

</div>,

<div className="font-mono">
... aspects of seeing ...

</div>,

<div className="font-mono">
p206 do not ask yourself "how does it work for me?" -- Ask "what do I know about someone else?"

</div>,

<div className="font-mono">
p207 ... always get rid of the idea of the private object int his way: assume that it constantly changes, but that you do not notice the change because your memory constantly deceives you.

</div>,

<div className="font-mono">
p209 it is only if someone can do, has learnt, is master of, such-and-such, that it makes sense to say he has had *this* experience ... we talk, we utter words, and only *later* get a picture of their life

</div>,

<div className="font-mono">
p210 the aspect presents a physiognomy which then passes always

</div>,

<div className="font-mono">
p212 ... what I perceive in the dawning of an aspect is not a property of the object, but an internal relation between it and other objects (ML?!) ... as if 'seeing the sign in this context' were an echo of a thought ... to interpret is to think, to do something; seeing is a state

</div>,

<div className="font-mono">
p217 meaning is as little an experience as intending ... they have no experience-content ... the intention *with which* one acts does not 'accompnay' the action any more than the thought 'accompanies' speech.

</div>,

<div className="font-mono">
p218 meaning is not a process ... calculation is not an experiment

</div>,

<div className="font-mono">
p219 (when a word comes to me) what do I reproduce? -- characteristic accompaniments. Primary: gestures, faces, tones of voice ... tip of the tongue ...

</div>,

<div className="font-mono">
p220 A hypothesis, such as that such-and-such went on in our bodies when we make internal calculations, si only of interest to us in that it points to a possible use of the expression "I said ... to myself"; namely that of inferring the physiological process from the expression.

</div>,

<div className="font-mono">
p222 The criteria for the truth of the *confession* that I thought such-and-such are not the criteria for a time *description* of a process.

</div>,

<div className="font-mono">
p223 It a llion could talk, we could not understand him.

</div>,

<div className="font-mono">
p224 We remain unconciouss of the prodigious diversity of all the everydau language-games, because the clothing of our language makes everything alike. Something new (spontaneous, 'specific') is always a language-game.

</div>,

<div className="font-mono">
p225 Ask, not: "What goes on in us when we are certain that ...?" -- bit: How is the 'certainty that this is the case' manifested in human action?

</div>,

<div className="font-mono">
p226 What has to be accepted, the given, is -- so one could say -- *forms of life*.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
xii. facts of nature & grammar
</div>,

      ]}
    />
  ),
};
