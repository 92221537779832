
import { EntryBody } from "../components/Entry/EntryBody";

export const tools_in_the_world: { [id: string]: any } = {

    id: "tools_in_the_world",
    title: <>Some explosive reason on tools in the world</>,
    date: "18 Jan 24",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
What a soul-crushing anxiety just invaded me while walking back home in the cold lightless afternoon and challenging myself to point something (in the street) for which I could not find an use. That is, something that I could not easily build an "X is useful for Y" sentence.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
One example may be trash. But who likes trash? Trash is surrounded by "get rid of", "remove" and "useless". These expressions have a very interesting effect. That who wants to "get rid of war" wishes, in a sense, to not use that word anymore. The language game of "getting rid of" results in the removal of words from other language games, like a meta operator for the change of rules. "Getting rid of asbestos" comes with the almost absolute removal of asbestos from many language games (such as building discussions). Anyway, trash seems to be a word that is actively pushed out of laguage games, actually it is used literally to do that. When using the expression "Take out the trash" one is not using "corrupt politicians", "junkies" etc in the sentence, trash takes the place of the word of that which we want to remove.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Maybe I go walking to the countryside & see a silly little mushroom. What is it useful for? Well a parent would reply to their kid (because only a kid would wonder the usefulness of something, really): "This mushroom is an important part of the ecosystem!" which is language we use to highlight the usefulness of something for something else (practical: google "are an important part of", with the ").

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
What is even an ecosystem? Some sort of posit of togetherness just so a mushroom can be useful? If there is no ecosystem... the mushroom is trash. In fact, the sickly plants that struggle in the cracks of the concrete of a city are trash, meanwhile the same plant would be "part of the ecosystem" in the forest nearby.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Words as tools, now. The meaning of a word is defined by the language game in which it is used, so words can only have meaning in the "ecosystem", in this case it is clear. And seems like the notion of ecosystem fits very well to "understanding a sentence is understanding a language". The togetherness, the necessary relationship, because words are only defined in that they are used differently than other words [distributional].

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Question: is the posit of the ecosystem linked to the mushroom an effect of early development of language? (observe how witty"s more primitive langauge games are [have to be] focused around using things) Is there an actual ecosystem that we then go ahead to refer to with the word? (Unlikely?) Is it some sort of prior this relationship in the form of use?

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Regardless of our reality, in language the ecosystem seems to be undoubtedly there. There is an explicit "optimization" process in language, related to the carrying of information, in which only words that are used are there. So there is no trash in language, per-se. In the world, this is more questionable.
</div>,

      ]}
    />
  ),
};
