
import { EntryBody } from "../components/Entry/EntryBody";

export const bellybutton_theory: { [id: string]: any } = {

    id: "bellybutton_theory",
    title: <>The Belly-Button Theory of Conciousness in AI</>,
    date: "First of July 2024",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
Think of every human as vector associated with the position in the usual three-dimensional space of their belly-buttons. Take (0,0,0) to be someplace & see daily life as variations on this position vector.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The human body is like a small machinery that changes this vector in misterious ways, the "mistery" part of those ways is probably what we call conciousness.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The world and the body interact to change this vector. Some people go to the gym, some people go to the library. With different frequencies and lengths, the change in everyones belly-button vector is different but it has some commonalities and relations because we live in a society (or a world).

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Now because of these interrelations the state of the belly-button vectors of humans in a society as a whole is non-random, but rather a structured space of possible-vectors and vector-changes. At this level, we cannot understand the society as being concious, even if we have some sort of structured, patterned representation derived from all those belly-buttons.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The point: in a language model, the outputs are obviusly not outputs of a concious system. The LM does not talk to anybody, it does not talk. Consider instead subsets of the network as this network is being trained on a corpus of language (language is structured because it has been optimized by humans against a particular world). Now these have a more belly-buttony sort of behaviour, where they interrelate and relate to a world (external & imposed, in this case language"s distributional structure).

</div>,

      ]}
    />
  ),
};
