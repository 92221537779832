
import { EntryBody } from "../components/Entry/EntryBody";

export const generics_hot_takes: { [id: string]: any } = {

    id: "generics_hot_takes",
    title: <>Generics Hot Takes</>,
    date: "Octoberr 23",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
Ostensive learning of words is the first case of generics. In a sense, the name as a predicate to an object [object understood as identifiable pattern in experience]. The first ever generic? The face of your mother and the "MaMa" sound. Some years later you learn that "Tigers have stripes".

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Gen seems like an operation that builds a semantic field with shorcuts. Normally we associate 2 words by co-occurrence in repeated occasions. In a generic, there is the perception that a new word is being brought to proximity to another by shortcutting repeated co-occurence. This is probably related with generalisations about how things generalise in the world, or generalisations on usefulness. This relation between two words accounts to a removal of the information of the predicate wrt the noun.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Consider: "Card draw effects in MTG are blue", this is a sentence many times uttered in mtg discussions, because, traditionally, good cards with the word "Draw" on them are blue. Now, the sentence "This new card-draw card is white" is high-information.

</div>,

<div className="font-mono">
When somebody is learning the game, for them to get to "card-draw is blue" they would need to observe, understand, maybe store, develop intuitions about thousands of cards. When their mtg-mentor tells them "Card-draw is blue", these two terms are linked into the semnatic field, without the need of more than one (maybe 0?!) co-occurence (probably the one that prompted the uttering of the sentence).

</div>,

<div className="font-mono">
Gen simply assigns "blue" to the selection of "card-draw cards" when operated by "are/are of colour".

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The generic as a shortcut to building the likelihood of word co-occurence, moving a word from a low-likelihood/0-likelihood to a selection, under some operator(argument, _).

</div>,

<div className="font-mono">
Gen: argument -&gt; selection of operator(argument, _), it is a function sending a word, to the selection of an operator, argument pair

</div>,

<div className="font-mono">
This way we can forget about the "truth-value" of a generic (as with everything else in language, folks): it is senseless to talk of truth here. Simply, if I state a generic: "Good card-draw is white" that is "false", what happens is that its information value will not correspond with the information value observed in the world. This accounts to, in information theory, that if one were to communicate events of good card-draw (say skimming through a card collection), if white is the 1-bit signal and blue is the 2-bit one, the code will be longer. Because communication is a social process, inefficient ways of conveying the information of the world are less likely to survive (everybody got places to be....)

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Here there seems to be a notion of normality (what is an average/normal card collection), but i do not believe this is too important, it is simply the way things are. Especially in language, empirical communication of codes through information.

</div>,

<div className="font-mono">
Here is where the truth of a generic seems to peep, but it is a misleading parallel!!! It is experience, or the world, that is (hence is true). But anything in language cannot be true, not in a sense of its relationship with the world, only in a sense that it was uttered in the world (which is pretty weak and uninteresting).

</div>,

      ]}
    />
  ),
};
