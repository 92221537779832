
import { EntryBody } from "../components/Entry/EntryBody";

export const notes_harris_comments: { [id: string]: any } = {

    id: "notes_harris_comments",
    title: <>Comments on Harris linguistic theory</>,
    date: "Octo 23",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
A partial order is a binary relation "≤" over a set P which is reflexive, antisymmetric, and transitive.

</div>,

<div className="font-mono">
Reflexivity: ∀ a ∈ P, a ≤ a

</div>,

<div className="font-mono">
Antisymmetry: ∀ a, b ∈ P, if (a ≤ b ∧ b ≤ a) then a = b

</div>,

<div className="font-mono">
Transitivity: ∀ a, b, c ∈ P, if (a ≤ b ∧ b ≤ c) then a ≤ c

</div>,

      ]}
    />
  ),
};
