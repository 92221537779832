import { FC } from "react";
import logo from "../../assets/logo192.png";

export const AppFooter: FC = () => {
  return (
    <div className="flex justify-center space-x-10 md:space-x-20 font-sans text-xs items-center -mt-2 text-gray-300  ">
      <p>...</p>
      <img className="w-6 h-6" src={logo} alt="cortext" />
      <p>ilyocoris@gmail.com</p>
    </div>
  );
};
