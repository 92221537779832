import { EntryBody } from "../components/Entry/EntryBody";

export const pictures_of_god_moneymaker_title: { [id: string]: any } = {
  id: "pictures_of_god_moneymaker_title",
  title: <>Pictures of God (Moneymaker Title)</>,
  date: "",

  Body: (
    <EntryBody
      paragraphs={[
        <div className="font-mono">
          Positing in opposition as basis of (spatial) experience. Not so clear
          in temporal experience.
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          From natural language the characteristics of positing in opposition
          (logic) can be deduced.
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          We use spatial expressions to refer to temporal events (ostensive
          language).
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          The grammar of positing in opposition is in time expressions. We posit
          in opposition time artifacts.
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          Yet this is not how our experience works. We cannot ground this
          opposite posited time artifacts.
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          We posit in opposition to the state of affairs something containing
          all possible state of affairs (this is because of grammar! not
          experience)
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          We name the set of
          state-of-affairs-but-the-one-which-is-posited-as-the-case destiny,
          God, causality, conspiracies, etc.
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          As this is not part of experience (the chair) but rather part of
          language, everybody can differ what word uses to name it (possible
          states of affairs that are not).
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          This is why the presence of God is revealed by Sermons. Or why
          conspiracies start on 4chan (a lot of OOD language there, hence the
          definition of conspiracy).
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          Note added afterwards: Maybe a more concrete example of this phenomena
          is that of death. "Where do dead people go?" is spatial grammar, yet
          it is obvious this question has no spatial answer. Nevertheless, this
          grammar suggests we should posit a somewhere (just as we would do when
          prompted with "Where is the chair?"), but since this positing is
          nonsensical, anything can be posited there. Hence the many differences
          in personal and cultural descriptions of the locus of deads.
        </div>,
      ]}
    />
  ),
};
