
import { EntryBody } from "../components/Entry/EntryBody";

export const notes_enquiry_concerning_human_understanding: { [id: string]: any } = {

    id: "notes_enquiry_concerning_human_understanding",
    title: <>Notes on An Enquiry Concerning Human Understanding, by Hume</>,
    date: "Sept 2023",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
II. OF THE ORIGIN OF IDEAS

</div>,

<div className="font-mono">
§13 When we think of a golden mountain, we only join two consistent ideas ... all our ideas ... are copies of our impressions

</div>,

<div className="font-mono">
(ideas as combination of tokens?)

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
III. OF THE ASSOCIATION OF IDEAS

</div>,

<div className="font-mono">
§18 Were the loosest & freest conversation to be transcribed, there would inmediately be observed something which connected it in all its transitions.

</div>,

<div className="font-mono">
... simple ideas ... bound together by some universal principle ... influence in all mankind

</div>,

<div className="font-mono">
§19 - Resemblance - Contiguity (in time or place) - Cause or Effect; [Contrast ~ Cause + Resemblance]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
IV. SCEPTICAL DOUBTS CONCERNING THE OPERATIONS OF THE UNDERSTANDING

</div>,

<div className="font-mono">
§20 objects of human reasn: Relations of Ideas & Matters of Fact

</div>,

<div className="font-mono">
§21 the contrary of every matter of fact is still possible

</div>,

<div className="font-mono">
§22 By means of [Cause & Effect] alone we can go beyond the evidence of our memory & senses

</div>,

<div className="font-mono">
... constantly supposed that there is a connection between present fact & that which is inferred from it

</div>,

<div className="font-mono">
§23 knowledge of [C&E] is not ... attained by reasonings a prioir; but arises entirely from experience

</div>,

<div className="font-mono">
§24 causes & effects are discoverable, not by reason but by experience

</div>,

<div className="font-mono">
§25 evert effect is a distinct event from the cause

</div>,

<div className="font-mono">
§32 ... from a number of uniform experiments, we _infer_ a connection between the sensible qualities & the secret powers ... on what process of argument this _inference_ is founded?It is impossible, therefore, that any arguments from experience can prove this resemblance of the past to the future, since all these arguments are founded on the supposition of that resemblance

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
V. SCEPTICAL SOLUTION OF THESE DOUBTS

</div>,

<div className="font-mono">
§36 Custom or Habit

</div>,

<div className="font-mono">
... after the constant conjunction of two objects ... we are determined by custom alone to expect the one from the appearance from the other

</div>,

<div className="font-mono">
... why we draw from 100 instances, an inference which we are not able to draw from one instance ... (reason incapable, conclusions on 1 circle apply to all)

</div>,

<div className="font-mono">
§38 All belief of matter of fact or real existence is derived merely from some object, preset to the memory or senses, and a customary conjunction between that & some other object

</div>,

<div className="font-mono">
§40 _belief_ is something felt by the mind, which distinguishes the ideas of the judgement from the fictions of the imagination

</div>,

<div className="font-mono">
§41 ... belief which arises from the relation of cause & effect

</div>,

<div className="font-mono">
sensible objects ... readily convey to these ideas to which they are related, and which they resemble (Roman Catholic cerimonies)

</div>,

<div className="font-mono">
§44 ... a kind of pre-established harmony between the course of nature & the succession of our ideas

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
IV. OF PROBABILITY

</div>,

<div className="font-mono">
§46 Through there be no such thing as _Chance_ in the world ... ignorance of the real cause of any event ... probability, which arises from a superiority of chances on any side

</div>,

<div className="font-mono">
(counterfactual?!) This concurrence of several views in one particular event begets inmediately, by an inexplicable contrivance of nature, the sentiment of belief ...

</div>,

<div className="font-mono">
§47 ... when we transfer the past to the future, in order to determine the effect, which will result from any cause, we transfer all the different events, in the same proportion as they appeared in the past

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
VII. OF THE IDEA OF NECESSARY CONNECTION

</div>,

<div className="font-mono">
§49 ... it is impossible for us to _think_ of antything, which we have not antecedently _felt_

</div>,

<div className="font-mono">
§50 to be fully acquainted, therefore, with the idea of power or necessary connection, let us examine its impression

</div>,

<div className="font-mono">
The scenes of the universe are continually shifting, and one object follows aother in an uninterrumped succession; but the power or force, which actuates the whole machine, is entirely concealed from us

</div>,

<div className="font-mono">
§52 ... our idea of power is not copied from any sentiment or conciousness of power within ourselves

</div>,

<div className="font-mono">
§54 ... the energy of the cause is as unintelligible ... and that we can only learn by experience the frequent _Conjunction_ of objects, without ever being able to comprehend anything like _Connexion_ between them

</div>,

<div className="font-mono">
§58 All events seem entirely loos & separate. One event follows another, but we never can observe a tie between them. They seem _cojoined_, but never _connected_.

</div>,

<div className="font-mono">
§59 after one instance ... we have observed a particular event follow upon another, we are not entitled to form a general rule ... But when one particular species of event has alwats ... been cojoined with another ... we ... call the one object _Cause_; the other, _Effect_

</div>,

<div className="font-mono">
... this idea of a necessary connexion among events arises from a number of similar instances which occur of the constant conjunction of these events

</div>,

<div className="font-mono">
idea of connexion ... _feels_ these events to be _connected_ in his imagination

</div>,

<div className="font-mono">
§60 The appearance of a cause always conveys the mind, by a customary transition, to the idea of the effect

</div>,

<div className="font-mono">
§61 quick rundown

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
VIII, OF LIBERTY & NECESSITY

</div>,

<div className="font-mono">
§63 necessirt & liberty ... the whol controversy has hirertho turned merely upon words

</div>,

<div className="font-mono">
§64 ... if all the scenes of nature were continually shifted in such manner that no two events have any resemblance to each other ... we should never ... have attained the least idea of necessity

</div>,

<div className="font-mono">
Beyond the constant _conjunction_ of similar objects & the consequent inference from one to the other, we have no notion of any necessity or connection

</div>,

<div className="font-mono">
§65 Mankind are so much the same, in all times & places

</div>,

<div className="font-mono">
§67 ... the contrariety of events may not proceed from any contingency inthe cause, but from the secret operation of contrary causes

</div>,

<div className="font-mono">
§69 this experimental inference & reasoning concluding the actions of others enters so much into human life that no man, while awake, is even a moment without employing it

</div>,

<div className="font-mono">
all mankind have always agreed in the doctrione of necessity

</div>,

<div className="font-mono">
§70 It seems almost impossible, therefore, to engage either in science or action of any kind without acknowledging the doctrine of necessity, and this _inference_ from motives to voluntary actions

</div>,

<div className="font-mono">
[prisoner example] connected chain of natural causes & voluntary actions; but the ind feels no difference between them

</div>,

<div className="font-mono">
§71 same necessity/causation (cojoined+inference) in objects & voluntary actions

</div>,

<div className="font-mono">
§72 (footnote) The necessity of any action ... is not ... a quality in the agent, but in any thinking or intelligent being, who may consider the action; and it consists chiefly in the determination of his thoughts to infer the existence of that action from some preceding object

</div>,

<div className="font-mono">
§74It is universally allowed that nothing exists without a cause of its existence, and that chance, when strictly examined, is a mere negative word, and means not any real power ... in nature.

</div>,

<div className="font-mono">
chance wrt causality ~ liberty wrt necessity

</div>,

<div className="font-mono">
impossible to _define_ cause without comprehending, as part of te definition, a necessary connexion with its effect (examine origin of this idea)

</div>,

<div className="font-mono">
§75 necessity may be defined in two ways, conformablyto the two definitions of _cause_ ... either in the constant conjunction of like objects or in the inference of the understanding from one object to another

</div>,

<div className="font-mono">
... necessity neede for morality (ierelate character & action)

</div>,

<div className="font-mono">
§78.79.80 rebuttal of argument that chain of events going back to Deity "collapses" morality or God"s perfection

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
IX. OF THE REASON OF ANIMALS

</div>,

<div className="font-mono">
§

</div>,

<div className="font-mono">
§

</div>,

<div className="font-mono">
§

</div>,

<div className="font-mono">
§

</div>,

<div className="font-mono">
§

</div>,

      ]}
    />
  ),
};
