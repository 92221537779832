import { ReactNode, FC } from "react";

interface Props {
  paragraphs: ReactNode[];
}
export const EntryBody: FC<Props> = ({ paragraphs }) => {
  return (
    <div className="flex flex-col items-even space-y-3">
      {paragraphs.map((p) => p)}
    </div>
  );
};
