import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Journal } from "../../pages/Journal/Journal";

export const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<Journal />} />
      <Route path="/:chapterId" element={<Journal />} />
      <Route path="/:chapterId/entry/:entryId" element={<Journal />} />
    </Routes>
  );
};
