import { VideoBody } from "../components/Entry/VideoBody";

export const video_being_time_i: { [id: string]: any } = {
  id: "video_tractatus",
  title: <></>,
  date: "",

  Body: (
    <VideoBody url="https://api-dump-v2.s3.eu-west-2.amazonaws.com/movie/660ed9ac992a79072e59f619.mp4" />
  ),
};
