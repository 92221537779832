import { PDFViewer } from "@react-pdf/renderer";

export const Chapter_Zero = () => {
  return (
    <div className="flex flex-col font-mono text-justify cursor-not-allowed items-center">
      <div
        className="lg:w-2/3 flex flex-col font-mono text-justify cursor-not-allowed"
        key="2021"
      >
        <div className="flex flex-row justify-between mb-7 border-b-2 space-x-10">
          <div className="text-xl">Summer Thoughts</div>{" "}
          <div className="text-lg">2021</div>
        </div>
        <div className="h-screen">
          <iframe
            src={`${process.env.PUBLIC_URL}/assets/summer_thoughts.pdf`}
            width="100%"
            height="100%"
          />
        </div>
        <div className="text-center mt-5">* * *</div>
      </div>
    </div>
  );
};
