
import { EntryBody } from "../components/Entry/EntryBody";

export const dialectic_disentanglement_generics: { [id: string]: any } = {

    id: "dialectic_disentanglement_generics",
    title: <>A Dialectic Disentanglement of Generics in Language & Experience</>,
    date: "18/19MAr24",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
SG.Lb11 (Simple Generics; Liebesman, 2011)

</div>,

<div className="font-mono">
GCA.Ls08 (Generics, Cognition and Acquisition; Leslie, 2008)

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
92: The logical form of generics is tripartite: quantifier, restrictor and matrix (scope) (like in "typically birds fly", but the operator is unpronounced). The restrictor specifies the domain and the scope the property. We have GENx[Restrictor(x)]∃y[Scope(x,y)]. (GCA.Ls08)

</div>,

<div className="font-mono">
7: What is even 'logical form'?!

</div>,

<div className="font-mono">
92: Oh... A representation that captures the underlying logical structure or meaning of the sentence, often abstracting away from surface-level syntax & lexical choices... a psychologically real level of linguistic irepresentation.

</div>,

<div className="font-mono">
7: OK. And what is GEN?

</div>,

<div className="font-mono">
92: GEN refers to the unpronounced operator that occurs in overtly non-quantified characterizing generics. Now the problem is how the GEN operator is to be semantically interpreted.

</div>,

<div className="font-mono">
7: Not yet! I am curious about this idea of an unpronounced operator.

</div>,

<div className="font-mono">
92: Turkish has a suffix that acts as a marker for evidentiality: (i)miş, which is unpronounced in english in many ocasions (it roughly translates to 'apparently' or 'I heard that').

</div>,

<div className="font-mono">
637: Let us take the case of generics where what is predicated can only be predicated of a kind, such as 'Dinosaurs are extinct' and 'Mosquitoes are widespread'. Here there cannot be a tripartite structure as the quantifier cannot, in a way, "pick out" those dinosaurs of which we predicate extinction.

</div>,

<div className="font-mono">
92: Those "kind selecting" generics are D-generics

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
... [this is to be completed]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
7: The fact that an algorithm can generate language up to coversational standards suggests that either an algorithm can have 'truth' and 'reference' as we understand them in humans, or that language does not need 'truth' and 'reference' to be explained. The second is better, as an algorithm does not have reference in the human sense towards the things we have reference to (although it may have reference in the human sense towards things that humans usually have no reference towards, in the sense that language is world for the LM) (what is reference in the human sense, sadly words will not suffice, u gotta be a human to understand).

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
7: Why the obsession with refering? What if generics are a co-occurence operator? 'Tigers are striped' would be a shortcut to alter the likelihoods of the words 'tiger' and 'stripe' beyond the current context. An existential, such as 'Tigers are in the front lawn', does not have this same effect, it only alters the likelihood of 'tiger' and 'front lawn' for as long as the context lasts. 

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
92: How would you interpret kind-predications, then? 'Mosquitoes are widespread'?

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
7:  Observe these predicates applicable to kinds but not to members: widespread, numerous, extinct, rare, common, indigenous to... They are all in relation to space, the members are 'here', 'there' or 'nowhere'. It seems natural that the spatial phenomenon are more fleshed out in language, as human existence is spatial; abstractions involving spatiality were probably an important success factor for language (big game hunting). Also spatial terms are indexical for (arbitrary) members of a class whereas things like stripes on a tiger are not.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
7: Ostensive learning of words depends on metaphysical units, both to define the word (sound delimitation) and to define what is the object that is being pointed at. By pointing a table and saying "table", this is like a generic construction that is associanting a(n impossible to define) kind to the sound 'table', the co-occurence of a table and 'table' is very strongly bound by ostension. Can we think of generics as ostension within language? 'Tigers are striped' is joining the word 'tiger' with striped. In psychological experiments that invent kinds and predicates "Sluborgs are skidbub": the nonsense sounds natural, similar to someone pointing to a book and saying a nonsense word like 'llibre', the relationship is understood.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
7: If we understand the likelihood modifications of a generic like a gradation, they get unified with existentials. The question is, what mechanism is responsible for the spatial and temporal and strength of the word binding in ostension, generics, existential, etc?! Here maybe Kant can save the day? Because we all have the same priors for experience, we (roughly) will represent the same things as units, and we can optimize language over the units of our representations.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
7: Language is built on context-dependent likelihoods. 'Tigers' and the experience of tigers, 'stripe' and the experience of stripes (lets say these words are learnt by ostension, they co-occur with a range of experiences), finally in language we have the relationship between 'tigers' and 'striped'. If after seeing one single tiger, someone says 'Tigers are striped', the likelihoods of 'tigers' and 'striped' are changed. The sentence 'This tiger is not striped', in a sense, the 'this' is bounding the co-occurence of 'tiger' and 'not striped' to a local spatial and temporal context.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
7: It makes a lot of sense that the long-binding is the default mode (the one that does not require an operator), because in ostension (which is the main way to learn a language in an early stage) all co-occurences expressed are long-context. When teaching new words, the co-occurence of a table and 'table' is meant to last foreeeever. In doing ostension by pointing and saying "This is a 'table'", the 'this' refers to the contextuality of the pointing (otherwise 'table' would be associated with the gesture).

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
7: Note how in 'tigers are on my lawn' and other classical examples of existentials with the same surface form as generics, the predicate is very ostensively bound to experience ('my lawn' is heavily corelated with a specific part of my experience, it is not as abstract as having stripes or as associating a sound to an abstract kind in word-learning). In existential readings, the co-occurence relation is short-lived in space & time, this is 'obvious' by the character of the predicate (character as in co-occurence with experience and other language). If one goes to the zoo & there is a bunch of white tigers, one will acceptably say: 'The tigers are albino' but never 'tigers are albino'. Here the 'the' is the marker of the context of the association. (A lot of dependence on dependence highlighted here).

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
7: The semantic diversity of generics then can be explained by the diversity of functions that language serves, that may require association of words for many different reasons. Assuming evolution, the sticking of striking predicates to particular kinds is of utmost importance that it should be done *before* an encounter with such kind occurs. "Tigers are dangerous" or "mosquitoes carry malaria" are accepted, because they link (forever) the likelihood of words, with an emphasis of a *before* encounter. The same mechanism associates 'Jake' and 'murderer' in "Jake is a murderer", if Jake kills once, but fails to associate 'Jake is a worrier' if Jake just worries once.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
(7: The calculus of modulating word co-occurence likelihoods is a bit like the wheel. A cart, a windmill, a potter"s wheel.... a particular calculus of motion that is used in many situations, with many functions, optimizing many experiences.) 

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
637, 92: QED! - they chant in a chorus: there is no GEN operator, and generics do not refer to kinds. It is language calculus (powered by the a priori unitification of experience) to shift the likelihoods of words (words in a weak sense) for a certain context!

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
7: Language is a way to build a common world with other Daseins(!?), and this 'common' is achieved by modulating co-occurences of words. As such, it is normal that language has many mechanisms that (meta?)modulate the likelihood of co-occurences between words, just as we have many ways of communicating our likelihoods of co-occurence between language and experience (by rising our eyebrows when somebody utters an unexpected sentence [low likelihood of word-world], or by pointing as we pronounce a word [high likelihood of word-world]). Adverbs like 'generally' or 'no', are within-langauge-operators that change co-occurence likelihoods, just as the previous examples were ousside-language-operators.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
(7: Many mathematical objects are the calculus of mathematical objects. An operation like a sum *is* a component of an object like a group, but also affects objects like numbers. Calculi of calculi! (until axioms ig))
</div>,

      ]}
    />
  ),
};
