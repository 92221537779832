
import { EntryBody } from "../components/Entry/EntryBody";

export const notes_understanding_understanding: { [id: string]: any } = {

    id: "notes_understanding_understanding",
    title: <>Notes on Understanding Understanding by Paul Ziff</>,
    date: "",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">
I Understanding

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
6 the inference he is able to make is as it were in some sense proportional to his understanding of S.

</div>,

<div className="font-mono">
8 Is being able to make an inference from S a necessary condition for understanding S?

</div>,

<div className="font-mono">
9 ... to uderstand S, he would have to be able to make not just one but all of an infinite series of inferences.

</div>,

<div className="font-mono">
11 if being able to make an inference from a statement to a statement one understands were a necessary condition for understanding any statement, no one would understand any statement.

</div>,

<div className="font-mono">
12 ... possibility of porviding paraphrases ... If one has a thorough grasp of the syntax of a language then even if one has virtually no knowledge of its semantics one can nonetheless readily provide paraphrases. ... it would follow that no nonhuman animal we know of ever understands naything that is said

</div>,

<div className="font-mono">
13 The robot-minded among us are wont to construe using words and understanding a use of words in terms of encoding and decoding processes.

</div>,

<div className="font-mono">
18 ... the character and form of the data processing that can culminate in understanding is today much of a mystery. ... make out the words ... analytic process

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
II What is Said

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
III The Logical Structure of English Sentences

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
... schematic representation of the English sentence as a sentence of formal logic, the sentences of which bare their structure to the eye.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
40 To rpovide an interpretation of a formal system is then to supply further rules that serve to establish relations between expressions of the formal system and various things or various matters.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
41 ... declarative sentences of English are not invariant either with respect to the expression of propositions or with respect to truth.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
45 ... the repetitive use of a verb in a discourse is equivalent to an adverbial modification of the verb. Sentential logic is not equipped to cope with adverbial modifiers.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
56 If one is to represent the various sentences of English in a quantificational logic then inevitably one is compelled to perform feats of conjuration, to produce abstract and special entities.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
IV Natural & Formal Languages

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Characteristically a formal language is constituted by a precisely specifiable set of expressions and various perfectly precise rules for their manipulation, combination and interpretation.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
59 ... von Neuman"s probabilistic logic provides an example of a formal language devised to cope with problems occasioned by the failure of transmission.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
60 'This bread tastes terrible' ... would have communicated the same imformation if in fact he had said 'This bed tastes terrible': the phonemic contrast between 'bed' and 'bread' is rendered redundant by the perceptual features of the situation. [low information interpretation]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
63 For a speaker to supply or rely on nonlinguistic inputs to the hearer is commonplace in the use of natural language. ... rely on the hearer"s having a special program ... the speaker"s linguistci input can be considerably compressed.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
64 There is no sharp line to be drawn between the specifically linguistic program and the special nonlinguistic program of a person who understands a particular dialect.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
65 [frame problem] Or consider the maze one maunders in in attempting to amke explicit a simple request 'Please tell me the time'. Where? Here. When? Now. According to what standard. Eastern Standard Time. ... (Yet if we are ever to make a conversational robot we shall have to make our way through such a maze as this.)

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
66 A vocabulary may be thought of as constituted by a finite explicit stre of words together with a set (possibly null) of morphologically productive devices. (x,x',x'',...)

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
67 ... no nonarbitrary specification of the vocabulary of a natural language, or dialect, or idiolect can possibly be effective.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
69 Perhaps the most inscrutable aspect of an ordinary person"s ability to use words is displayed in the ease and swiftness with which he finds the words to use: an ordinary speaker is a word-finding device par excellence.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
70 The words or word-semses of a natural language do not constitute a set in the set-theoretic sense of 'set'. The vocabulary of words and word-senses of a natural language is a continuous creation.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
75 ... the word then constitutes a coding device serving to ensure reasonable access to the stored plurality of senses

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
IV On H. P. Grice"s Account of Meaning

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
...

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
V A Response to 'Stimulus Meaning'

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
...

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
VIII Something about Conceptual Schemes

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
129 But communication gropes stumbles and eventually colapses when the forthright 'A cheetah can outrun a man' is so cautiously qualified.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
... 'A tiger is a large carnivore': isn"t a new-born tiger a tiger and yet hardly a large carnivore?

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
130 In each of these cases a difficulty lies lurking in an absence of specifications.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
131 Is it simply that further specifications are implicit in the discourse? But can"t what is implicit in discourse be made explicit? Yet when one attempts to detail, to enumerate some set of specifications that would serve to safeguard the truth of a remark like 'A cheetah can outrun a man', the task seems impossible. It is not merely that such a set of specifications would have to have indefinitely many and anyay prodigiously many members but its membership would have to be remarkably heterogeneous. 

</div>,

<div className="font-mono">
[the frame problem in generics]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
132 A specifications in terms of what is 'ordinary' is not apt to be illuminating if what os 'ordinary' is itself so singularly difficult to specify.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
133 An appeal to normality is bound to be futile when it consists of nothing more than the invocation of a label.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
136 One"s different conceptions of these different matters are related and interrelated in diverse ways; theyr form what may be called 'a conceptual scheme'.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
... in telling a hearer that a cheetah can outrun a man I offer him, as it were, a bit of a map of a portion of that field indicating certain relations between the indicated points.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">


</div>,

      ]}
    />
  ),
};
