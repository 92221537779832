
import { EntryBody } from "../components/Entry/EntryBody";

export const nets_as_metaphysical: { [id: string]: any } = {

    id: "nets_as_metaphysical",
    title: <>Neural Nets as Metaphysical Playground: An Introduction to the Idea this Journal Circles</>,
    date: "June 2023",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
Funny Note: This is the introduction of my PhD"s research proposal (which then went on to talk about hallucination in NMT kek), so it is quite sterilized.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
How do architectural priors enable a model to understand data? — is the research question at the heart of this application.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
A historical example that illustrates this question is the development of convolutional neural networks. Dense layers, both today and in the 90s, are empirically unable to efficiently extract, store and match patterns in visual data, images.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The intuitive justification given for the convolution in the introductory papers [cite to LeCun"s 1998, 1995 famous convo papers] is remarkable:

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
"... images or time frequency representations of speech have a strong 2D local structure: variables (or pixels) that are spatially or temporally nearby are highly correlated. ... _Convolutional Networks_ force the extraction of local features by restricting the receptive fields of hidden units to be local."

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
This suggests the spatial structure of images is not a pattern the model learns from the data, but an a priori necessary to understand it. Constrains in modeling a specific problem are nothing new or surprising, but here they take the shape of an abstraction of a cognitive task (spatial visual experience). In the convolution, we find an implicit theory that certain "spatial" constraints are _necessary_ a priori for a (computationally reasonably bounded) system to understand images.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
"Space is not an empirical concept that has been abstracted from outer experiences. ... Space is a necessary a priori presentation that underlies intuitions." [Cite to the Critique]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Both the introduction of the convolution and Kant's transcendental aesthetic use a similar abstraction to explain how is it possible to experience or understand space. This is an interesting observation that begs the question: _can we leverage these powerful theoretical abstractions of the mind as a guide for architecture design in neural networks?_

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Although this was an example from vision, I think these questions are better explored through language and the field of NLP.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Encoder, decoder, representation, attention, positional encoding ... are all names chosen to ground the success of engineering prowess on common intuitions of the cognitive task of language. Because it generates language, a transformer becomes a theory of language.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
...

</div>,

      ]}
    />
  ),
};
