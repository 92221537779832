
import { EntryBody } from "../components/Entry/EntryBody";

export const language_games_for_meaning: { [id: string]: any } = {

    id: "language_games_for_meaning",
    title: <>Language Games Fix the Probability of Sentences, thus Inducing Meaningful World-Picture Shifts!</>,
    date: "25 November 2023",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
[Some notes and clarifications are at the bottom to make the reading experience seamless!]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The meaning of words in order to form a sentence comes from the distributional structure in language, classes induced by co-occurrence and dependencies. [Following Harris ^^]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The meaning of a sentence is related to the fact that the sentence was uttered in a context and how likely we deem such sentence to have been uttered. This [part of] meaning is not compositional wrt the words in the sentence! (unless you want to be very exotic and take the sentence to be the whole of the world when it is uttered)

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The language game fixes the probability (i) the sentence should have, and then we shift our world-picture (ii) so that the sentence has that probability. The probability of a sentence as a whole is as key for understanding it [that is, making something out of it] as the meanings of the words and their structure.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Instead of using the word probability, maybe it is more clear to frame the exposition with the word "information". Either way: low probability -&gt; high information; high probability -&gt; low information. The examples can be re-read with this change in mind (while looking for more comprehensive manner of expression & formalization).

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
As shown by the senselessness of the previous sentences, it is a struggle to crystalize the idea circled. Here are some examples, especially the one about "There is a snake in the bathroom", which are hopefully illustrative of what is put forward.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Example I:

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Sweaty, livid, big eyes, screaming: panic language-game -&gt; listener expects an extremely low probability to what is said -&gt; "There is a snake in the bathroom" -&gt; the world-picture is that this is an unusual event, therefore should call the cops or something

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Rolls eyes, sighs: -&gt; bothersome language-game -&gt; listener expects a mid probability to what is said -&gt; "There is a snake in the bathroom" -&gt; the world-picture is that the guy lives in australia & gets snakes in the bathroom once in a while, no big deal but a bit of a pain to deal with

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Relaxed smile: -&gt; costumbrist description language game -&gt; listener expects a high probability to what is said -&gt; "There is a snake in the bathroom" -&gt; the world-picture is that there is a terrarium on the bathroom with a snake in it

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
[Observe here how the world-picture shifts to accomodate the probability of the sentence given the language game. Truly some meaning of the sentence is in this act of accomodating a probability for it that is fixed! The compositional & "referential" meaning of the sentence is the same in the three cases [a snake in the bathroom, dassit], but the forced probability that the language game gives it is what makes us shift our world-picture, which is where meaning is coming from here (meaning as use, what is the context in which this sentence would be used? should i call pest control, grab a broom or take out the phone to take a pic? the same sentence, the same reference)]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Now I see the proud owner of a bathroom snake terrarium come out of the bathroom livid and screaming "There is a snake in the bathroom". So this is the fear language game, therefore I have the sentence to be low probability, but it is not, it should be high probability since [my world picture tells me that] in fact there is a snake in the bathroom. I could then think that we were playing the joke language game, the joker pretends to play a language game but utters sentences that, with the probability established by the language-game, need a shift in the world-picture to be accomodated that is not possible. Then we all laugh (part of the language game!).

</div>,

<div className="font-mono">
What if the owner is not joking?! Then I shift my world-picture to accomodate the sentence "There is a snake in the bathroom" to be of low likelihood. Probably I imagine that there is a second snake in the bathroom, this one of deadly nature and free, that makes me rush to my phone & call pest control.

</div>,

<div className="font-mono">
Important to note here that, if we consider language alone, "There is a snake in the bathroom" is a high-probability sentence in the corpus of the owner, they probably utter it everytime they have someone over. Also wrt what the listener knows about the world, that there is a snake in the bathroom, this sentence is still high-probability. But it makes no sense to panic about something that is high-probability (maybe you are worried, or angry or afraid, but not panic), the panic language-game sets, forces, the uttered sentence to be low probability, even if the sentence itself is high-probability with regard to ways to slice language and language-use.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Another use of this same sentence in the introduction: &lt;Here are some examples, especially the one about "There is a snake in the bathroom"&gt;! When reading that sentence nobody froze and glanced towards the bathroom door, well because we are playing the "academic reading example" language game, where _all_ sentences are equiprobable, and, therefore, have no meaning [one has the same reaction to all possible sentences here, that of maybe assenting or "mh"].

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Example II:

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Book with a still life in the cover (or sth) -&gt; costumbrism language game -&gt; "He woke up to a blue sky" -&gt; high prob -&gt; the world has a blue sky. If the sentence had been "He woke up to a red sky" we may have thought the novel happened on an alien world with a red sky.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Book with a spaceship in the cover written by Larry Niven or sth -&gt; scifi language game -&gt; "He woke up to a blue sky" -&gt; low prob -&gt; the world-picture is that the sky has a different colour in that world than in ours, like red or green (because if it were blue, the sentence would be high prob). If the sentence had been "He woke up to a red sky" we would have been like ok, normal, its a scifi book what do you expect... a blue sky?!

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Example III:

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
I walk into a supermarket (buying language game) -&gt; "The cheese is £3.5" -&gt; superhigh prob -&gt; the sticker of the cheese says £3.5, "Sure" I tap my card

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
I walk into a supermarket (buying language game) -&gt; "The cheese is £3.5" -&gt; superhigh prob -&gt; the sticker of the cheese says £2.5 instead of £3.5, therefore the superhighprob sentence would be "The cheese is £2.5", now this is giving me a lot of information, is the cashier mistaken or lying to me? (playing another language game!?)

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
[The information that the cashier may be wrong, or lying, comes from me reconciling the world with a world-picture in which the sentence "The cheese is £3.5" is highprob but the sticker of the cheese says £2.5. Because I think the sentence by the cashier is not highprob, I can:

</div>,

<div className="font-mono">
(i) keep the language game as it is and shift the world-picture to accomodate it, by saying that the label on the cheese is wrong

</div>,

<div className="font-mono">
(ii) because I cannot sensibly shift my world-picture to one where the sentence has the prob established by the language game, we are simply playing another language game (lying)

</div>,

<div className="font-mono">
(iii) the cashier made a mistake at playing the language game, now we play the correction language game or something]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Example IV (for Tristan):

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Consider the recommendation letter language game. A recommendation letter contains highly unlikely sentences: "The student is smart/hard-working/brilliant...", unlikely because most students are not those things (in most world-pictures). The receiver of the letter shifts their world-picture so that to accomodate that these unlikely sentences, about the student, and therefore the student is in fact brilliant, etc... The more trust or respect in the recommender, the easier this shift.

</div>,

<div className="font-mono">
When the recommendation letter contains "The student has good handwritting", this sentence is one of low likelihood, as set by the language game. But it is hard to shift the world picture to one where "The student has good handwritting" is a low probability one. Most students have good handwritting (good enough to be students), therefore in order to shift our world-picture to accomodate for this sentence having low probability, we should have most students in that faculty to be bad handwritters, which is not something we are expected to do [these shifts are within some bounds of coherence in a theory of the individual about of how the world can work]. As with the cashier, we assume that whoever wrote the recommendation letter is playing another language game other than that of recommending, in this case one of satire or of missrecomendation.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
---

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Notes:

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
(i) Sadly, I am not ready yet to give a technical definition to what I am calling probability throughout the examples. Take it intuitively for now, formalization is coming!

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
(ii) The use of "world" and "world-picture" (as a possible world) should be taken in a very unproblematic way here, p-please. Maybe closer to experience and expected experince, which makes less metaphysical assumptions about physical worlds which are not the point here. A way to make it formal would be a subset of language (explanations) with the same information structure as that of the experienced world for the listener. &lt;- that this one is utterly unproblematic, left as exercise for the reader!

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
--- Unfinished stuff ---

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Example V:

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Teacher - Student case (?)

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
What is left to do: a formalization of this in terms of Information Theory & then booom new loss functions baby!

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
So we can understand this world-picture as a latent variable. Let the sentence be uttered by speaker A with prob p, and that same sentence have prob q for us given the current latent variable.

</div>,

<div className="font-mono">
The intuitive idea is that the social context optimizes p & q so that their are closer together, this is the basis of optimization for LMs, where cross-entropy is the main quantity being minimized. But if this were the case, societal structures would impede communication, rather than, as they empirically do, enable it. This is because information comes precisely from that difference between p & q.

</div>,

<div className="font-mono">
Given d = KL(p||q), the previous picture would update the world-picture latent so that p and q are closer, thus minimizing d. The argument here is that d is actually a constant, given the language game

</div>,

      ]}
    />
  ),
};
