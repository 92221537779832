
import { EntryBody } from "../components/Entry/EntryBody";

export const notes_bosse: { [id: string]: any } = {

    id: "notes_bosse",
    title: <>Notes on Bosse</>,
    date: "2024",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
Generics: some (non) specifics

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Generics generalise about members of a kind and attribute a link between them and some property, but they need not say much about the nature of that link.

</div>,

<div className="font-mono">
[because the link only establishes a metalinguistic characteristic of the words, and the non-generic generalisations where one is enough for the truth of the generic are contexts where those words cooccur]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
What is needed is experimental testing of which non-generic generalisations speakers take to make true the corresponding generics.

</div>,

<div className="font-mono">
[we can do this on LLMs!]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
What is important for my purposes is that the generalisations in question must be more fine-grained than generics in that they specify more of the flavour or quantificational force of the link between the kind and property in question.

</div>,

<div className="font-mono">
(8) 87% of Scots are vitamin D deficient

</div>,

<div className="font-mono">
(9) Most Scots are vitamin D deficient

</div>,

<div className="font-mono">
(10) Many Scots are vitamin D deficient

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
26 See Frawley (2013, pp. 69–74) on grammatical markers of non-specificity and Dahl (1995) and Chierchia (1995) on such markers in generics.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
What led Sterken and Nguyen to posit context-sensitivity or semantic incompleteness was the observation that tokens of generics appear to express different generalisations across contexts of utterance.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Even though generic tokens semantically express non-specific generalisations, they, in addition, convey more specific generalsisations through pragmatic means.

</div>,

<div className="font-mono">
[convey the lowest information non-generic generalisation (?!)]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
We can use generics to semantically express non-specific generalisations which interact with cognitive biases, prior beliefs, and conversational expectations to cause beliefs in more specific generalisations.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Generics provide particularly fertile grounds for implicatures because of their weak semantic contents. By positing a connection between kinds and properties, they provide enough contents to interact with cognitive biases, prior beliefs, and conversational relevance expectations.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Instead, I proposed a novel account, according to which generics quantify existentially over non-generic generalisations.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
----

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Stereotyping and generics

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Generics are linguistic items that express propositions: generic propositions. Such propositions can be expressed linguistically, but we can also take a variety of attitudes towards them. We can hope for them, dread them, expect them, or, we can believe them. Such beliefs are generic beliefs.

</div>,

<div className="font-mono">
[huhhhh.... what?]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Essentialising occurs when we infer that a given association between members of a kind and a property is grounded in and explained by further underlying, stable properties shared by members of a group.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
strong co-occurrence expectations arising from generic beliefs about social kinds that are reﬂected in the essentialisation data

</div>,

<div className="font-mono">
[&lt;o&gt; any citations? what data?]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
simply increasing the number of accurate beliefs about social kinds, and thus make us less likely to allow generic beliefs to ﬁll in the gaps
</div>,

      ]}
    />
  ),
};
