
import { EntryBody } from "../components/Entry/EntryBody";

export const groundwork_discussion_causality_lms: { [id: string]: any } = {

    id: "groundwork_discussion_causality_lms",
    title: <>Groundwork for Discussion on LM Causality</>,
    date: "Sept-Nov 2023",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
[LM stands for Language Model. SOTA language generation models are LMs, they predict the next token (word) given a context. It will be clear by the use throughout the Journal, but take LM in a general abstract way.]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
I

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
What is language for us is world for the LM.

</div>,

<div className="font-mono">
[Nothing can be said about causal capacities of LMs by reading their outputs as language (for us) that refers to human experience. They have to be understood as behaviour or world. In a sense: primary for the LM, and secondary for the human.]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Causality is form, not content.

</div>,

<div className="font-mono">
[Human content of causality are objects of experience (let"s say objects in a space-time). Causality is never those objects. The content of an LM are not objects in space-time in human experience but rather (broadly speaking) language, therefore even if an LM can generate language that talks about causal relations between objects for humans, the content of the LM is language itself.]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The exploration of LM causality is _within model and language_, not related with the content of human experience.

</div>,

<div className="font-mono">
[Causality in LMs is not to be investigated on how the language they generate relates to how a human would use that same language to refer to human experience. This would be akin to consider human causality as being about things-in-themselves rather than experience (take this metaphor in a simple manner, this is an illustration not a concerned metaphysical statement).]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
II

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
From Hume"s Enquire Concerning Human Understanding,

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§63 ... Beyond the constant _conjunction_ of similar objects & the consequent inference from one to the other, we have no notion of any necessity or connection

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
A bit more detailed:

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§61 ... when many uniform instances appear, and the same object is always followed by the same event; we then begin to entertain the notion of cause and connexion.

</div>,

<div className="font-mono">
For as this idea arises from a number of similar instances, and not from any single instance, it must arise from that circumstance, in which the number of instances differ from every individual instance. But this customary connexion or transition is the only circumstance in which they differ.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
[Note how this description is (grammatically?) similar to the description of the training of a neural network, as learning causality in a World (that of the training data).]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Causality is here defined as the repeated observation of cojoined objects allowing us to infer one from the other, as something purely learned from experience.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Causality is learned (habit or custom). This is a core metaphysical standpoint in the design of deep learning algorithms. The model trains (experiences?!) on vast amounts of data. And in language words are cojoined (otherwise they would not be meaningful, W & Harris).

</div>,

<div className="font-mono">
["The room is hot because of the..." "stove" generates the model. This comes from cojoined appearances of room and hot and stove in the training data. Causality for the model is within language & itself, it refers to the words(*), not to the space or temperature as they are in human experience.]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
III

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
In the meantime, let us take a look at an a priori in current (2023) transformer-based language models.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
A sentence is a sequence. Language [the LMs world] has dimensionality, one-dimensional, like time?

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
"We present time sequence by a line progressing _ad infinitum_, a line which the manifold constitutes a series only in one dimension. And from the properties of that line we infer the properties of time, except for one difference that the parts of the line are simultaneous whereas the parts of time are always sequential." (Critique B50; Kant)

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Unlike time! If we are to parallel to human experience, the dimension is spatial. Is it learned?

</div>,

<div className="font-mono">
[This dimension would be learned if the input sentence to the transformer was a bag of words. The LM would have to learn during training (data, experience) the order of words that language expresses.]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
"Since our model contains no recurrence and no convolution, in order for the model to make use of the order of the sequence, we must inject some information about the relative or absolute position of the tokens in the sequence. To this end, we add "positional encodings" to the input embeddings at the bottoms of the encoder and decoder stacks. The positional encodings have the same dimension d_model as the embeddings, so that the two can be summed." [Attention is All you Need; Vaswani]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The order of the sequence is explicitely encoded by an element in the architecture, before the model is trained and experiences (?) any data.

</div>,

<div className="font-mono">
The spatial dimension of language is an _a priori_ for the network. It is something that forms the representations of the input text (world), rather that a feature learned during training.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Learning during training would mean this relationship between words of the sentence is causal in the sense discussed so far, of cojoining, infering and custom.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
0

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Models experience.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
I use the word experience when something in my outer senses has an effect in my internal state.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
After a datapoint, the weights of the model change [backpropagation] and therefore the subsequent activations [internal state]; experience.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Models experience during training, not during inference!

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
IV

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Still holding Kant"s hand, let us advance further Within. Consider the third antimony:

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Thesis | There are in the world causes through freedom.

</div>,

<div className="font-mono">
Antithesis | There is no freedom, but all is nature.

</div>,

<div className="font-mono">
(Prolegomena §51; Kant)

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Which resolves to both being true, as "... if natural necessity is referred merely to appearances and freedom merely to things in themselves, no contradiction arises." (Idem §53c)

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
"The leaf of the tree is green." What is the cause of the word "green"? First, the apparition of "green" is in accordance with the laws of grammar (nature), it is a predicate adjective and if the sentence were in catalan "La fulla de l"arbre es verda." it would also explicitely agree with the gender of the subject.

</div>,

<div className="font-mono">
Moreover, the co-occurrences of leaf-tree-green in any corpus will be a distributional semantic cause. There was little freedom for the word green to appear in such context because of the distributional law in the corpus (nature).

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
As long as language is a thing in itself, all words are bound by the laws of language, as seen from within, both in terms of semantics and grammar.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
But language for us is not without world-context, the freedom in the language we use comes from its relationship to human experience. From language alone, that freedom is unintelligible, and all the effects of human-world causes in language, follow the rules of language (when only considering language).

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
[Inevitable. Immortal. Here we see a rule of spelling. We use im- instead of in- when the first letter is an m. This is because of the law of language. The cause of this cause, for example physiology of the human mouth, is unintelligible for a model.]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
"If ... appearances count as .... mere presentations connected according to empirical laws, then they must themselves still have bases that are not appearances. But such an intelligible cause is not, as regards to its causality, determined by appearances, although its effects appear and thus can be determined by other appearances. Hence this cause, along with its causality, is outside the series of empirical conditions, whereas its effects are encountered within the series." (Critique A537/B565; Kant)

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
We read the output of an LM, and we posit the relationship of that language with (our) human experience. Yet this relationship is only an unintelligible cause [from within language] of the laws [of nature/distributional] that shape LLM experience [input and output language].

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
"We see here that there is a particular dependence of co-occurrence which creates the events which are called language, and that this dependence necessarily gives these events the power to carry information. More specifically, the underlying structure of language is the structure of information ..., and ... the co-occurrence constraints which create information are a reflection of co-occurrence constraints in the perceived world." (A Theory of Language and Information p23, Harris)

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
It is not to be underestimated how much of our experience is in [the distributional structure of] language, purely because language carries information precisely about such experience [communication]. This and the naturality and fluidity of LM language output, make it hard for us not to expect there an explicit language-experience relationship, thus positing dogmatic slumber in Language Models.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
V

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
aaaaaaaa i hate TRUE and i hate FALSE! Nevertheless:

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
"&#123;proposition about the content of human world&#125; is True/False" is asking the model to do fruitless metaphysics, if it is read by a human as assertoric speech [making a statement or claim about the (human) world].

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Saying that a proposition is true is used when one, given a state of affairs, would use that proposition to describe such state. [is this conventional then?] The state of affairs of the (human) world is clear from within (human) experience, but unintelligible for a language model.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
True/False can co-appear with any other word in a language:

</div>,

<div className="font-mono">
"Once upon a time, &#123;sentences that describe a world&#125;. It is true/false that &#123;proposition about that world&#125;."

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Within language: false will co-appear with sentences not present in the previous context [corpus], whereas true with sentences present in the context [corpus].

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
[Dependence on dependence. The distributional structure of language mirrors experience, as one would say "This is true" of that which one would[has] use[d] in a state of affairs, and false of sentences otherwise.]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
A picture of how to read LMs from human experience will need clues from Quine"s indeterminacy of translation! [How much can we read an LM to say, if an LM can have no human experience.]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
VI

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The stage is set for radical translation: "The recovery of a man"s current language from his currently observed responses is the task of the linguist who, unaided by an interpreter, is out to penetrate and translate a language hithertho unknown." (Word & Object §7; Quine)

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The linguist and the native share a(n objective) world, but not language, nor culture. Stimulous meaning ["disposition to assent or to dissent from the sentence in response to present stimulation"(Idem §8)] is the key to the construction of the jungle-english manual.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The linguist is able to establish relationship between native utterances through stimulous-meaning. What sentences (and combinations of them) are used in a context and not another establishes a relationship between them [inferential semantics]. For the porpuse of translation, the linguist has to coin analytical hypothesis that grounds the referent of some sentences in the shared objective world [referential semantics]. The point: this is impossible to do uniquely in radical translation.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The world of the human is unintelligible [the non-linguistic part of experience, that is] for the LM, and the world of the LM is the language of the human.

</div>,

<div className="font-mono">
[Is here the human to mean Humanity? As the corpus where LMs are trained make no explicit distinction on individuals.]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Or is it?! Why should one not say that the distributional structure of language [in practice] is in fact an element of the human world? And is not that same distributional structure also part of the LM world?!

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
[The form of human language is the content of LM world. But still form is the content of abstraction! (Concerning The Concept Of Wissenschaftlehre; Fichte) So it is content in our experience too, after all.]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
[Where is this going?! I started this chapter to argue a fundamental difference between radical translation and the "what can a LM say" question: that there is no shared world, but now I see that there may be.]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
[Here should go something about description vs acquaintance in lms, maybe?!]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
VII

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
I sit at my desk, yet I [[will imagine] [I am part of]\*] an LM for some hyperbeing [h-being, read h- for hyper from now on pls].

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
My input are h-sentences of their h-language [in general, any Information related to those beings]. This Information affects me, and it is all that can affect me: experience.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Now take the chair. I sit on it. I turn my neck and can see it. The chair is affecting my being considerably. It does so sometimes, but not when I leave my office. Well it also does in a way, if I thought there was no chair in my office, I would concern myself with finding one.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Say this chair corresponds to a h-token from the h-language. When I am affected by a h-sentence with such h-token, the chair is an important element of my experience.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
[Take an h-sentence in experience which is the affirmation of an h-proposition that I thought h-false. Say the chair is an h-token for h-negation. Then my behaviour will be that of longing for that h-token.]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
What we do, is [let"s be unproblematic here...] given by experience and maybe some inner state. Somehow, an output can be read from how we behave. [Whether it be from thoughts, feelings, or acid on our stomach, or a combination or neither.]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Our world is the representation of h-sentences from the h-language of a h-being, and we will take normally defined objects in experience to correspond to such h-tokens. From what we do, because what we do is defined by experience, some output can be derived so to do h-sentiment analysis or sth.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
\*It is natural to think of other humans in our experience as other parts of the LM, that we can communicate with. One can also consider other humans as h-tokens, but even in imagination being a solipsist is sad.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Now the question is: what is the meaning of our experience? What can we know about what h-beings" h-meaning from within representations of the h-language alone?

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Assume: the relationship between h-language and & the h-world the h-beings inhabit is a [h-]parallel to our case. [After all, Information is Information, r-right?]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
I know that a chair is related in some manner to a table, and in a different manner to a shark. In a sense, the information of experiencing a chair next to a table is lower than that of experiencing a chair next to a shark (be it on a bizarre aquarium, or in a visit to a sunken ship). That is, if I have to assign a[n optimal] code to everything describable in experience, I will assign a shorter code to the description of the chair-table complex than the chair-shark one. Whatever an h-being could mean (in the h-world) by a h-chair, I have a good grasp of the relation of likelihood [high likelihood, low information] it stands wrt the rest of h-tokens in my experience.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The Information in our experience is bound by the h-language, and the h-language is bound by h-experience, contingent to some deep principles in our experience holding for the h-beings, namely that of optimization [so this: [high likelihood, low information] holds].

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
In the reality we know and love, where humans are h-beings wrt LMs, these principles seem to hold, albeit more losely in our experience than in that of the LM. Our language is optimized given our experience, the information in language is related to the information in experience, and then, this language becomes LM-world.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
VIII

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Let"s have a funny corollary from VII!

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
In 2023, most training schemes include special tokens, such as &lt;s&gt; or &lt;|endoftext|&gt;. These tokens appear on every training instance, and have a representation that can be attended to. In the literature, when measuring the attention paid to each token, high importance is given to these meaningless tokens by LMs. In hallucinations [generations detached from the source], it is often that attention concentrates on the &lt;s&gt; token, but this behaviour seems to happen (to a lesser extent) in non-pathologic generations too.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
What is that to which we pay always a bit of attention? And a lot of attention in some specific cases, as when we are bored? What but, in some sense or another, ourselves!!

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Self-conciousness is the attention to the &lt;s&gt; token!!!

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
IX

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
probably some information theory here?

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
X

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
What would be sufficient for experience? Objects [very loose sense] sharing information, and by something [this should not be assumed to be knowable by such objects] this information changes.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Consider the whole of experience of all humans. For example, by a h-being that wants to communicate such whole to another [this is unimportant, but I think helps] with a system [such as binary numbers in inforation theory examples] that is better if all experiences are similar.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The homogeneizing effect of harambe on the global of human experience as a spike of... something? becoming.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Information in our experience is not given by our iteraction with the world [for examples with a cognitive world model Q that is compared against a world distribution P] but rather is

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
WIP

</div>,

<div className="font-mono">
...

</div>,

      ]}
    />
  ),
};
