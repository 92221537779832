
import { EntryBody } from "../components/Entry/EntryBody";

export const phonetics_and_embeddings: { [id: string]: any } = {

    id: "phonetics_and_embeddings",
    title: <>Phonetics are a Good Way to Picture Embeddings! :)</>,
    date: "Oct23",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
Phonetics is a representation of an utterance based on subword segmentations (morphemes) in a continuous space (that of sound).

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
This is a very natural and intuitive mental image of what embeddings are in nlp.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Token embeddings are contextual (they draw from the information of other tokens in the utterance), similarly, pronunciation of the same word in an interrogative, assertive or inquisitive expression changes.

</div>,

      ]}
    />
  ),
};
