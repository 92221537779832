
import { EntryBody } from "../components/Entry/EntryBody";

export const notes_husserl: { [id: string]: any } = {

    id: "notes_husserl",
    title: <>Notes on Husserl</>,
    date: "July'24",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
Cartesian Meditations

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
FIRST Meditations

</div>,

<div className="font-mono">
...

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§ 10. Digression: Descartes' failure to make the transcendental turn

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
prejudice ... one is dealing with an apodictic "axiom", which ... is to serve as the foundation for a deductively "explanatory" world-science

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
ego ... point of departure for inferences according to the principle of causality ... transcendental realism

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§ 11. The psychological and the transcendenal Ego. The transcendncy of the world

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
If I keep purely what comes into view - for me, the one who is meditating - by virtue of my free epoché with respect to the being of the experienced world, the momentous fact is that I, with my life, remain untouched in my existential status, regardless of whether or not the world exists and regardless of what my eventual decision concerning its being or non-being might be.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
By phenomenological epoché I reduce my natural human Ego and my psychic life - the realm of my psychological self-experience - to my transcendental Ego, the realm of transcendental-phenomenological self-experience.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
SECOND MEDITATION

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The Field of Transcendental Experience Laid Open in Respect of its Universal Structures

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§ 12. The idea of a transcendental grounding of knowledge

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
... phenomenological epoché lays open ... an infinite realm of being of a new kind ... transcendental experience

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The bare identity of the 'I am' is not the only thing given as indubitable in transcendental self-experience. Rathere there extends through all the particular data of actual and possible self-experience -even though they are not absolutely indubitable in respect of single details- a universal apodictically experienceable structure of the Ego (for example, the immanent temporal form belonging to the stream of subjective processes)

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§ 13. Necessity of at first excluding problems relating to the range covered by transcendental knowledge

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
first stage ... realm accessible to transcendental self-experience ... must be explored ... with simple devotion to the evidence inherent in the harmonious flow of such experience.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
second stage ... criticism of transcendental experience ... and all transcendental cognition

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
we are envisaging a science ... whose thematic object exists whether or not the world exists

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
transcendental solipsism

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§ 14. The stream of cogitationes. Cogito & cogitatum

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
cogitationes, the flowing concious life in which the identical Ego (mine, the meditator"s) lives

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
each cogito, each conciouss process ... "means" something or other and bears in itself ... its particular cogitatum. ... The house-perception means a house

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
to be conciousness of something; as a cogito, to bear within itself its cogitatum

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§ 15. Natural & transcendental reflection

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Only in reflection do we "direct" ourselves to the perceiving itself and to its perceptual directedness to the house.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
transcendental experience, consists then ... in our looking at and describing the particular transcendentally reduced cogito, btu without participating, as reflective subjects, in the natural existence-positing that the originally straightforward perception (or otehr cogito) contains or that the Ego, as immersing himself straghtforwardly in the world, actually executed.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
If the Ego, as naturally immersed in the world, experiencingly and otherwise, is called "interested" in the world, then the phenomenologically altered ... attitude consists in a splitting of the Ego: in that the phenomenological Ego establishes himself as "disinterested onlooker", above the naively interested Ego.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
the world, and therefore whatever exists naturallly, exists for me only as accepted by me, with the sense it has for me at the tim -thta it existsfor me only as cogitatum of my changing and, while changing, interconnected cogitationes ... Consequently I, the transcendental phenomenologist, have objects (singly or universal complexes) as a theme for my universal descriptions: solely as the intentional correlates of modes of conciousness of them.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
[what is a "meditation"/phenomenological reduction but a language distribution?]

</div>,

      ]}
    />
  ),
};
