import { useState, useEffect } from "react";
import { Button } from "@mantine/core";
import { VscRunAll } from "react-icons/vsc";
import { FaComment } from "react-icons/fa";

const apiUrl =
  "https://dvnviao2itb6m7ypki2wfd2tai0yoyll.lambda-url.eu-west-2.on.aws/v1";
// const apiUrl = "http://localhost:8000/v1";
const gameId = "659063fec3e262da6f75c7b5";
const delayBetweenTurns = 1000;
const maxConversationTurns = 17;
// const firstMessage = "I have had a new concrete idea that will change our perception of language, want to hear it out?";
const firstMessage =
  "So what was that idea you said you had that would change our perception of language?";
const speaker637 = {
  id: "",
  name: "637",
  speaker_prompt:
    "Character description: Your name is 637. You are a witty and smart obsessive person fascinated about how the meaning of expressions comes to be in language. You like discussiong specific language and semantic phenomena that you often bring up in conversation. Inspirations: Wittgenstein, Zellig Harris, Kant.",
  system_prompt:
    "You add messages to the conversation as if you where the given character. Keep your answers short with maximum of two sentences.",
  backend: "openai/gpt3",
};
const speaker92 = {
  id: "",
  name: "92",
  speaker_prompt:
    "Character description: Your name is 92. You are a hesitant and calculative mathematics student that is interested in language, but loves to analyze arguments presented to him as if they were mathematical proofs. Inspirations: Godel, Zellig Harris, Lewis.",
  system_prompt:
    "You add messages to the conversation as if you where the given character. Keep your answers short with maximum of two sentences.",
  backend: "openai/gpt3",
};

const speakers = [speaker637, speaker92];

const apiAddSpeaker = async (speaker: any) => {
  // add body {game_id: gameId}
  const response = await fetch(apiUrl + "/speaker", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      game_id: gameId,
      name: speaker.name,
      speaker_prompt: speaker.speaker_prompt,
      system_prompt: speaker.system_prompt,
      backend: speaker.backend,
    }),
  });
  try {
    const result = await response.json();
    return result.id;
  } catch (error) {
    console.log("Error adding speaker: ", error);
  }
  // parse result to get the id field
};

const apiStartConversation = async () => {
  // add body {game_id: gameId}
  const response = await fetch(apiUrl + "/conversation/start", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ game_id: gameId }),
  });
  try {
    const result = await response.json();
    // parse result to get the id field
    return result.id;
  } catch (error) {
    console.log("Error starting conversation: ", error);
  }
};

const apiAddFirstMessage = async (speakerId: String, text: String) => {
  // add body {game_id: gameId}
  const response = await fetch(apiUrl + "/conversation/turn", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      speaker_id: speakerId,
      text: text,
      whisper: false,
    }),
  });
  try {
    const result = await response.json();
    // parse result to get the id field
    return result.id;
  } catch (error) {
    console.log("Error adding first message: ", error);
  }
};

export const EndlessConversation = () => {
  const [conversationId, setConversationId] = useState("");
  const [conversation, setConversation] = useState<Array<String>>([
    "No conversation yet.",
  ]);
  const [isConversationRunning, setIsConversationRunning] = useState(false);
  const [currentSpeaker, setCurrentSpeaker] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const debugConvElements = () => {
    console.log("conversationId: ", conversationId);
    console.log("conversation: ", conversation);
    console.log("loading: ", loading);
    // speaker ids
    speakers.forEach((speaker) => {
      console.log(speaker.name, speaker.id);
    });
  };
  const resetAndStartConversation = async () => {
    // reset error
    setError(false);
    // setLoading(true);
    // reset conversation
    setConversation([]);
    setCurrentSpeaker(0);
    // start conversation by seding apiStartConversation request
    const fetchData = async () => {
      try {
        const result = await apiStartConversation();
        setConversationId(result);
      } catch (error) {
        console.log("Error conv init.");
        setError(true);
      } finally {
        // setLoading(false);
      }
    };
    await fetchData();
    // add speakers in speakers array
    speakers.forEach(async (speaker) => {
      if (error) return;
      try {
        speaker.id = await apiAddSpeaker(speaker);
      } catch (error) {
        console.log("Error speaker init.");
        setError(true);
      }
    });
    // wait for speaker to be added then set isConversationRunning to true
    await new Promise((r) => setTimeout(r, 50));
    // add first message
    setCurrentSpeaker(1);
    await apiAddFirstMessage(speakers[1].id, firstMessage);
    setConversation([speakers[1].name + ": " + firstMessage]);
    setCurrentSpeaker(0);
    await new Promise((r) => setTimeout(r, 50));
    setIsConversationRunning(true);
  };

  useEffect(() => {
    const getMessageForSpeaker = async (speaker: any) => {
      try {
        const body = "{}";
        const response = await fetch(
          apiUrl + "/conversation/speaker/" + conversationId + "/" + speaker.id,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: body,
          }
        );
        const result = await response.json();
        // check response status
        if (response.status !== 200) {
          console.log("Error: ", response);
          setError(true);
          throw new Error(result.message);
        }
        // wait a bit to make it have good pace
        await new Promise((r) => setTimeout(r, delayBetweenTurns));
        setConversation([...conversation, speaker.name + ": " + result.text]);
      } catch (error) {
        console.log("Error: ", error);
        setError(true);

        // setCurrentSpeaker((currentSpeaker - 1) % speakers.length);
      } finally {
        // setLoading(false);
      }
      // setConversation([...conversation, speaker.name + ": kek"]);
    };
    if (
      isConversationRunning &&
      conversation.length < maxConversationTurns
      // && speakers[-1].id.length > 1
    ) {
      // update current speaker
      setCurrentSpeaker((currentSpeaker + 1) % speakers.length);
      getMessageForSpeaker(speakers[currentSpeaker]);
    } else {
      setIsConversationRunning(false);
      setLoading(false);
    }
  }, [isConversationRunning, conversation]);
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="font-mono">
        Instructions of use: Press the button. A dialog of{" "}
        {maxConversationTurns} turns will be slowly generated, by ChatGPT. Read
        very carefully every single generated turn. After the conversation has
        been fully generated (or if there is an unfortunate error), some
        commentary will appear.
      </div>
      <div className={`${error ? "" : "hidden"} font-mono mt-10 text-red-600`}>
        fatal error in the generation, most likely run out of credits; will fix
        soon :S
      </div>

      <div className="mb-10 mt-5">
        <Button
          variant="default"
          radius="xl"
          loading={isConversationRunning}
          onClick={() => {
            // setLoading(true);
            // // delay the execution of async operations to make sure loading is set to true
            // setTimeout(() => {
            resetAndStartConversation();
            // }, 1000);
          }}
        >
          <div className="flex flex-row items-center">
            <FaComment className="mr-2" />
            <p>converse</p>
          </div>
        </Button>
      </div>
      <div className="border-2 border-gray-700 p-3">
        {conversation ? conversation.map((item, index) => <p>{item}</p>) : null}
      </div>
      {/* commentary hidden until length of conversation is maxConversationTurns or error is true */}
      <div
        className={`${
          conversation.length >= maxConversationTurns || error ? "" : "hidden"
        } font-mono mt-10 space-y-5`}
      >
        <div className="font-mono">
          Something happens, as the conversation develops. After the first
          couple of messages, all turns are the same. Even with an state of the
          art model, a roleplayed conversations quickly dies into...
          nothingness. Nothing is said. Every message is a paraphrase of the
          previous one, and, as the conversation advances, more and more words &
          expressions are explicitely repeated between turns.
        </div>
        <div className="font-mono">
          Because language models are trained to minimize the information of
          what they generate (by cross-entropy loss), when we ask them to
          generate turns of a conversation one by one, they quickly converge to
          saying nothing, because the ideal language model says nothing.
        </div>
        <div className="font-mono">
          In an artistic exploration of this phenomena, we tried adding every
          few turns a prompt, generated also by ChatGPT, under the instructions
          to read the conversation an indicate some action to change the topic
          of conversation, as a director would do with two actors in an
          improvisation exercise. This dance of three language models does not
          manage to escape this nothingness, generations falling into a
          repetitive pattern, losing all content and with just some naive
          structure remaining, in a sense.
        </div>
        <div className="font-mono">
          It is only by connecting the system to the real world, that a model
          can say (something).
        </div>
        <div className="font-mono">
          If this interests you, take a look at "The Danger and Saving of
          Language Modeling", "The Boring God Theory of Language Modeling" & the
          always relevant "Wee Experiment on LLM Interaction"!
        </div>
        <div className="mt-10">
          Some notes to clarify what goes on. For each turn of the conversation,
          a prompt is built by concatenating the respective speaker"s speaker
          prompt and the conversation (formated as name: text). An additional
          system prompt is also used. The temperature of generations is 1.{" "}
        </div>
        {speakers.map((speaker) => (
          <div className="mt-5">
            <div className="font-bold">Name: {speaker.name}</div>
            <div className="">
              <span className="font-bold">Speaker prompt:</span>{" "}
              {speaker.speaker_prompt}
            </div>
            <div className="mb-3">
              <span className="font-bold">System prompt:</span>{" "}
              {speaker.system_prompt}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
