
import { EntryBody } from "../components/Entry/EntryBody";

export const generics_as_auxiliary_positions: { [id: string]: any } = {

    id: "generics_as_auxiliary_positions",
    title: <>Generics: (as) auxiliary positions in a language game</>,
    date: "26 Oct 24",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
So first here are paragraphs §25 & §26 from "Some Reflections on Language Games" by Stellas:

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§25. In 19 it was claimed that there are at least two ways of properly coming to be at a postion in a language game. Two ways were thereupon discussed which can be idicated by the words 'observation' & 'inference'. There is, however, a third way of properly coming to be at a position. Here onw comes to be at certain positions without having moved to them from other positions (in which position resembles observation), & without having made a language entry transition (in which respect it resembles inference).

</div>,

<div className="font-mono">
The positions in question are "free" positions which can properly be occupied at any time if there is any point to doing so. Obviously what I have in mind are the sentences the status of which, when used in a rule obeying manner, is specified as that of "primitive sentence" (i.e. as unconditionally assertable) by a rule in the metalanguage. (Thus 'All A is B' might be specified as a primitive sentence of language game L).

</div>,

<div className="font-mono">
Are such sentences properly called _positions_? Their "free" status and their "catalytic" function make them a class apart, yet it is less misleading to call them positions than it would be to call sensations functioning in observation positions. Let us call them "auxilliary positions".

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§26. We now notice that a language game which contains the auxiliary position 'All A is B' provides the move from 'This is A and All A is B' to 'It is B' as a special case of syllogistic move. An alternative way of going from 'This is A' to 'It is B' would exist if the game included a direct move from the form '... is A' to positions of the form '... is B'. We thus notice a certain equivalence between _auxiliary positions_ and _moves_. We also notice that while it is conceivable that a language game might dispense with auxiliary positions altogether, thought at the expense of multiplying moves, it is not conceivable that moves be completely dispensed with in favor of auxiliary positions. A game without move is Hamlet without the prince of Denmark indeed!

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Here the running example is an universal generalisation: 'All A is B'. But universals are hardly free in (current human) language games: rather the easiest generlisation to challenge. It is hard to find anything A which applies to every single instance in a B. In such cases, it is clearly not a 'free' move, but rather an inference (move) from those justifications. The cases where 'All A is B' cannot be challenged and is accepted in the manner of a 'free' move, is when the relationship is definitional, but in that case it does not seem that interesting that one goes from 'This is A' to 'It is B': it is an explicit rule (observable, for example, in a dictionary entry).

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Also 'All A is B' does not feel free. Explicit quantification in general does not feel free. It has a heavy inferential flavour, as any quantifier could be justified by counting things in a set. Some, most, all, many...

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Ah, but what about the generic 'As are B'!? Now generics _do_ feel free, they feel so free the mainstream theoretical analysis is to posit them the lingusitic manifestation of _cognitive defaults_, because in relation to the rest of the language they seemed too inexplainable. There is not a move more 'free' in a language game than one that is made by default.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Here more on 'free moves' by Brandom in 'Making it explicit':

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p222 There are sentence types that would require a great deal of work for one to get into a position to challenge, such as "Red is a color", "There have been dogs", "Lightning frequently precedes thunder", and similar commonplaces. These are treated as "free moves" by members of our speech community -they are available to just about anyone any time to use as premises, to assert unchallenged.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
(Obs: "by members" is a non-quantified generalisation (generic) and "available to just about anyone" comes shy of the universal! Even here, the use of "all" is too challengeable, and the generic and then something that is 'all with some exceptions you"ll surely disregard as edge cases :)' are used to talk about what is introduced as a suuurely universal characteristic.)

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Some characteristics of generics:

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
i. Generics express essence or law-like relations. Upon reading this sentence, one cannot but get that it is in the essence of a generic to 'express essence or law-like relations'. This may not be the case and generics could express quantificational relations, but reading a generic makes it feeeel as it is of essence. And what could qualify more as a free move in a language game than something expressing the essence of something else?

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
ii. Generics are hard to challenge. This is the classic example of generics in politics or racism: generics are used even in presence of obvious and even many counter-examples (e.g. in weak generics 'ticks carry lyme'), yet they survive (are still used after) exceptions (as opposed to universals). There is something about the relations we express with generics that relates to the lawlike feel, 'ravens are black' but not 'ladybirds are black'. But even then, how does one justify 'ravens are black'?! Even thinking of it in syntactical terms: what sentence could justify 'ravens are black'? The sentence 'in nature, as well as in fiction, the majority of ravens are black, and white ravens are the result of an unlikely mutation' seems like justifies 'most ravens are black' but it feels like it would not even be that strong of an argument for 'ravens are black'. Certainly not a "syntactic" argument because many generics express non-majority characteristics in the kind. If something is weird to challenge and to justify... is it free?

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
iii. Generics are premises for inference. Even if defeasible and with some specific patterns, generics are used to justify moves (claims) ('Check your legs after you search for water beetles in a wetland because ticks carry lyme!'). They are positions in a language game.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
iv. Generics transmit knowledge. Take any technical literature. Generics are widespread there. In things like medicine or physics. And it"s weird because its an non-quantifying non-specifying way of expressing relations in "sciences" that are quite based around quantifying and specifying. ...?

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
v. Generics are about kinds, which are abstract and not something considered observable. While it seems like generics are hard to move to by inference, do they relate to language entry transitions? Also weird: there are no examples where the world-context where a generic could come from 'observation' as a kind cannot be observed. Of course its members can.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
So generics seem to be in many of the grey areas characteristic of these "auxiliary positions" that are freee. The next step is: can this be _observed_ in the dynamics of disocurse with language models?

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
If we take information and attention based metrics (here i am thinking entropix, which is something happening now) and track them across discourse that has generics, are there any particular dynamics? Should free sentences be slight oasis of low-entropy in a high-entropy discourse? If a sentence is free, it should be used quite a bit or otherwise be obvious, formally or materially, which should mean lower entropy on a good language model.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
---

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Also in the Sellars paper:

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§71. But philosophically more interesting are those cases where we decide to indtroduce new material moves into _non-teoretical_ discourse. Thus, suppose that "Φ" and "Ψ" are empirical and that their conceptual meaning isconstituted, as we have argued, by their role in a network of material (and formal) moves. Suppose that these moves do not include the move from "x is Φ" to "x is Ψ". Now suppose tha we begin to discover (using this frame) that many Φ"s are Ψ and that we discover no exceptions. At this stage the sentence, "All Φ's are Ψ" looms as an "hypothesis", _by which is meant that it has a problematical status with respect to the categories of explanation_. In terms of these categories we look to a resolution of this problematical situation along one of the following lines.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
(a) We discover that we can derive "All Φ's are Ψ" from already accepted nomologicals. (Compare the development of early geometry.)

</div>,

<div className="font-mono">
(b) We discover that we can derive "If C, then all Φ's are Ψ" from already accepted nomologicals, where C is a circumstance we know to obtain.

</div>,

<div className="font-mono">
(c) We decide to adopt -and teach ourselves- the material move from "x is Φ" to "x is Ψ". In other words, we accept "All Φ's are Ψ" as an unconditionally assertable sentence of L, and reflect this decision by using the mdoal sentence "Φ's are \_necessarily Ψ". This constitutes, of course, an enrichment of the conceptual meanings of "Φ" and "Ψ".

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§72. But it may be long before we arrive at a _decision_, and in the interim (always supposing that no exceptions turn up), we will say that "it is probable that all Φ is Ψ". The importnat thing is to realize that instead of "probable hypothesis" or "mere inductive generalization" being a _terminal_ category, it is an interim category. And if we were to say (as it is often sensible to say) "It is probable that Φ's are necessarily Ψ", we should be giving notice that we expected a resiltuion of the problematic situation along the lines of either (a) or (c).

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
In this genesis of material inferences as free in a langauge game, what is the role of the generic? Because the generic _is_ how knowledge is transmitted and stored in sciences (language games) such as medicine, which are very contingent (there are barely any 'all').

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The generic feels like the linguistic move that takes something observational (this tiger has stripes) to something material and free (tigers have stripes) through the grammar of the expression.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Those two paragraphs read from a generics perspective really disclose nickels sentence 'between logic & the world' imo

</div>,

      ]}
    />
  ),
};
