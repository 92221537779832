
import { EntryBody } from "../components/Entry/EntryBody";

export const wee_experiment_llm_interaction: { [id: string]: any } = {

    id: "wee_experiment_llm_interaction",
    title: <>Wee Experiment on LLM Interaction</>,
    date: "Sept23",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
Take a minute to complete a typing test, for example here: https://10fastfingers.com/typing-test/english

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
It is important you complete at least 20 seconds, this is a practical entry to the journal!

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Did you notice it? Did you feel them? The slivers of meaning pushing in the space between words? The necessity to posit meaning (that is, causality in the characters)?

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Every group of 2-3 words fights for coherency, and I think of contexts that would make them come to life (aka be used correctly aka have meaning), only to feel disappointment when the next word breaks that coherency (or causality!?) and returns the sequence to random words, the sentence vanishes.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
What is to notice here is that we strive to make meaningful (provide possible contexts) to even random sequences of words we know have not been chosen to communicate ("have meaning"), but rather as a mechanical typing exercise.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
I know, yet I fight for look young no new page more important each let such family sentence few number another were face we then example her side what could country and I am sad when, for example, sentence appears after each let such family.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Now interact with your favourite LLM. Oh! How easy it is now to posit all the meaning!! And more!! Moreee meaniiiing baby it don"t stoop!!!

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Suddenly words are not random samples from the top200 most common but rather the most likely sentence to follow the context we established as derived from titanic corpora containing virtually all recent written language.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
I like to keep this in mind when talking to LLMs. Not to discredit their beauty, but rather to explore my own.

</div>,

      ]}
    />
  ),
};
