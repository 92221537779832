
import { EntryBody } from "../components/Entry/EntryBody";

export const language_as_calculus_without_reference: { [id: string]: any } = {

    id: "language_as_calculus_without_reference",
    title: <>Language as Calculus Without Reference</>,
    date: "21 Marx 2024",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
Language is how it is because of how it is used, optimized against experience, but it is nevertheless a calculus quite independent from it.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
If one has 3 boxes & someone else shows up wth 4 boxes, we have 7 boxes. While we use the sum to find out things about experience, the sum as an operation does not express or refer to the boxes. But the sum is how it is because how experience is (Kant?!).

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Same with language and word co-occurence.

</div>,

      ]}
    />
  ),
};
