
import { EntryBody } from "../components/Entry/EntryBody";

export const misleading_parallels_language_and_experience: { [id: string]: any } = {

    id: "misleading_parallels_language_and_experience",
    title: <>Misleading Parallels: language & experience</>,
    date: "August 2023",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
Consider saying "The floor is flat" & then consider walking over a flat floor (therefore maybe in an effortless manner).

</div>,

<div className="font-mono">
The action, in experience, is.

</div>,

<div className="font-mono">
What we like to call the statement (as in the soul of the language), is it true or false?

</div>,

<div className="font-mono">
One could say, as in experience the floor is flat, it is logical to say that "The floor is flat" is a true statement.

</div>,

<div className="font-mono">
But isn"t logic here trying to make language into experience?

</div>,

<div className="font-mono">
Taking the "grammar" of experience (where everything either is or is not; true or false) to statements in language.

</div>,

<div className="font-mono">
The only logical language is experience.

</div>,

<div className="font-mono">
But aren"t statements about experience? Well, are they? Don"t they look like another part of experience, rather than something "above" it.

</div>,

<div className="font-mono">
Take the walking over the floor and the statement about the floor: they are not the same.

</div>,

<div className="font-mono">
I have to think more on this but logic is starting to seem like a smelly cope.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Note: from common sense, we find paradoxical and weird how logic can accomodate undecidability or godel type problems. We do not find these problems on experience (where everything is, or is not). Consider the sentence "Good morning baby! ^^", valid simple unproblematic language, but nor true nor false. Language is not experience!! Not only in terms of content but particularly in terms of form!! (here there are nuances on the utterance vs proposition vs "meaning", ik)

</div>,

      ]}
    />
  ),
};
