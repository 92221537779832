import { EntryBody } from "../components/Entry/EntryBody";

export const notes_phenomenology_of_spirit: { [id: string]: any } = {
  id: "notes_phenomenology_of_spirit",
  title: <>Notes on Phenomenology of Spirit by Hegel</>,
  date: "August 2023",

  Body: (
    <EntryBody
      paragraphs={[
        <div className="font-mono"></div>,

        <div className="font-mono">PREFACE</div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          §2 ... comprehend the diversity of philosophical systems as the
          progressive unfolding truth
        </div>,

        <div className="font-mono">
          §3 The aim by itself is a lifeless universal ... the bare result is
          the corpse which has left the guiding tendency behind it.
        </div>,

        <div className="font-mono">
          §5 ... bring philosophy closer to the from of Science, to the goal
          where it can lay aside the title 'love of knowing' & be actual knowing
        </div>,

        <div className="font-mono">
          §6 For the Absolute is not supposed to be comprehended, it is to be
          felt & intuited
        </div>,

        <div className="font-mono">
          §8 Instead of dwelling in this world"s presence, men looked beyond it,
          following this thread to an other - wordly presence, so to speak ...
        </div>,

        <div className="font-mono">
          [Gordian Knot] complex knot on oxcart that Alexander sliced w / sword
        </div>,

        <div className="font-mono">
          §14 Science in its early stages ... vulnerable to criticism. ... One
          side boasts its wealth of material & intelligibility, the other side
          ... flaunts its immediate rationality & divinity.
        </div>,

        <div className="font-mono">
          §17 ... everything turns on grasping & expressing the True, not only
          as Substance, but equally as Subject
        </div>,

        <div className="font-mono">
          §18 ... the living Substance is being which is in truth Subject ... ~
          in truth actual only in so far as it is the movement of positing
          itself ... pure, simple negativity
        </div>,

        <div className="font-mono">
          the True ... reflection of otherness within itself(substance as
          Subject) ... process of itds own becoming
        </div>,

        <div className="font-mono">
          [Notion(Begriff)] concept, conceptual understanding
        </div>,

        <div className="font-mono">§19</div>,

        <div className="font-mono">
          § ... blah blah blah(i"ll transcribe this later)
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">A. CONCIOUSNESS</div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          I. SENSE - CERTAINTY: OR THE 'THIS' & 'MEANING' [MEINEN]
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          §90 knowledge ... which is at the start ... knowledge of the
          immediate(what simply _is_)
        </div>,

        <div className="font-mono">
          approach to the object ... _immediate_ or _receptive_ ... apprehending
          ... (not) comprehending
        </div>,

        <div className="font-mono">
          §91 sense - certainty &gt; appears as richest .. knowledge(unbounded
          reaching out to space & time) &gt; appears truest knowledge ... it has
          as yet not omited anything from the object
        </div>,

        <div className="font-mono">
          truth of sense - certainty: sheer being of a thing(the thing _is_ ...
          pure being ... simple immediacy) ... neither I nor the thing has the
          significance of a complex process of mediation
        </div>,

        <div className="font-mono">
          §92 sense - certainty is an _instance_ of pure immediacy ... pure
          being splits up into ... two 'Thises' (I, object) we reflect on this
          difference ... each is mediated
        </div>,

        <div className="font-mono">
          §93 object posited in sense - certainty in the form of a simple,
          imediate being(essence)
        </div>,

        <div className="font-mono">
          'I' mediated, not in itself, knowing which knows the object because
          the object is
        </div>,

        <div className="font-mono">
          §95 'What is _This_?' ... form of its being, as 'Now' and as 'Here'
        </div>,

        <div className="font-mono">
          §96 Now is Night ... This self-preserving Now is , therefore, not
          immediate but mediated
        </div>,

        <div className="font-mono">
          for it is determined ... _through_ the fact that something else (Day /
          Night) is _not_.
        </div>,

        <div className="font-mono">
          _universal_: simple thing ... which _is_ through negation(true content
          of sense - certainty)
        </div>,

        <div className="font-mono">
          §97 It is a universal that we _utter_ what the sensuous[content] is .
        </div>,

        <div className="font-mono">'This' -&gt; universal _This_</div>,

        <div className="font-mono">'it is' -&gt; Being in general</div>,

        <div className="font-mono">
          Of course, we do not _envisage_ the universal This or Being in
          general, but we _utter_ the universal ... we do not strictly say what
          in this sense - certainty we _mean_ to say.
        </div>,

        <div className="font-mono">
          (from analysis) We mean to express what is ultimately individual, but
          this is inexpressible ... we express what is universal
        </div>,

        <div className="font-mono">
          §98 'Here' ... also mediated simplicity, universality
        </div>,

        <div className="font-mono">§99 Pure being</div>,

        <div className="font-mono">
          §100 object now unessential ... it has come to be universal ...
          essential now in knowing. Its truth is in the object as _my_ object
          ... Sense - certainty driven back to the 'I'
        </div>,

        <div className="font-mono">
          §101 truth lies now in the 'I' ... immedicay of _seeing_, _hearing_
          ... another 'I' ... both truths vanish in the other
        </div>,

        <div className="font-mono">
          §102 'I' as universal ... when I say 'I', this singular 'I', I say in
          general all 'Is'
        </div>,

        <div className="font-mono">
          §103 essence of sense - certainty not in its moments(I, object). whole
          sense - certainty itself as its _essence_ ... as _immediacy_
        </div>,

        <div className="font-mono">
          §104 .. I am a pure[act of] intuiting(no subject - object distinction)
        </div>,

        <div className="font-mono">
          §105 ... let ourselves point to the Noe that is asserted ... the truth
          of this immediate relation is the truth of this 'I' which confines
          itself to one 'Now' or one 'Here'
        </div>,

        <div className="font-mono">
          §106 The now is pointed to, this Now ... already ceased to exist in
          the act of pointing to it
        </div>,

        <div className="font-mono">
          §107 ... pointing out is the experience of learning that Now is a
          _universal_
        </div>,

        <div className="font-mono">(i) point out Now(ii) has been(iii)</div>,

        <div className="font-mono">
          ... this _otherness_, or the setting aside of the first, is itself _in
          turn set aside_, ... returned into the first ... the first reflected
          into itself ... not ... immediate
        </div>,

        <div className="font-mono">
          on the contrary, it is something that is reflected into itself ... a
          Now which is an absolute plurality of Nows
        </div>,

        <div className="font-mono">
          §108 The _Here pointed out_ ... _negative_ This ... a movement from
          the Here that is _meant_ through many Heres into the universal Here
        </div>,

        <div className="font-mono">
          §109 dialectic of sense - certainty ... simple history of its movement
          or of its experience ...
        </div>,

        <div className="font-mono">
          (saying that) the reality or being of external things taken as This
          ... has absolute tructh ofr conciousness is saying the opposite of
          what one wants to say
        </div>,

        <div className="font-mono">
          §110 Tis that is meant _cannot be reached_ by language, which belongs
          to conciousness ... inherently universal.
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          II. PERCEPTION: OR THE THING & DECEPTION
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          §111 Certainty ... wants to apprehend the This. Perception ... takes
          what is present to it as universal. ... immediatly self -
          differentiating moments within Perception('I', object) are universal
        </div>,

        <div className="font-mono">
          occur -&gt; come into being: act of perceiving vs ovject perceived
        </div>,

        <div className="font-mono">
          object essence in perception, act of perceiving unessential
        </div>,

        <div className="font-mono">
          §112 object ... *mediated * universal ... shows itself to be * the
          thing with many properties*
        </div>,

        <div className="font-mono">
          perception ... contains negation ... difference or manifoldness within
          its own essence(wealth of sense - knowledge)
        </div>,

        <div className="font-mono">
          §113 The This is ... established as *not* This, or as something
          superseeded ... not as Nothing, but as a determinate Nothing.
        </div>,

        <div className="font-mono">
          Supersession ... a * negating * and a * preserving*
        </div>,

        <div className="font-mono">
          Being ... having mediation or the negative within it
        </div>,

        <div className="font-mono">
          when it * expresses * this in its immediacy it is a * differentiated
          *, *determinate * property
        </div>,

        <div className="font-mono">
          self - idential universality ~ abstract univeral medium(thinghood /
          pure essence) in which determinacies(properties) are
        </div>,

        <div className="font-mono">
          ~ Here / Now: a simple togetherness of a plurality
        </div>,

        <div className="font-mono">
          but the many are, * in their determinateness *, simple universals
          themselves
        </div>,

        <div className="font-mono">
          This salt is a simple Here, and at the same time manifold
        </div>,

        <div className="font-mono">
          §114 properties ... determinate in so far they * differentiate *
          themselves from one another, and *relate * themselves * to others * as
          to their opposites
        </div>,

        <div className="font-mono">
          exclusive (not indifferent) differentiation (each property negating
          the others), thus falls outside of this simple medium ... is not
          merely an Also (indifferent uity) but a * One *, a unity which
          excludes another ... moment of negation ... 'thinghood' determined as
          thing
        </div>,

        <div className="font-mono">
          §115 Thing as the truth of perception: (a) indifferent, passive
          universality, the * Also * of the many properties (b) negation ... the
          * One *, which excludes opposite properties (c) the * many *
          properties themselves
        </div>,

        <div className="font-mono">
          §116 The percipent is aware of the possibility of deception ... is not
          an untruth of the object - - for this is the self - identical - - but
          an untruth in perceiving it
        </div>,

        <div className="font-mono">
          §117 what conciousness experiences in its actual perceiving ...
          developing contrdictions present
        </div>,

        <div className="font-mono">
          object ... presents itself as a * One * ... but I also perceive in it
          a property which is universal, and which thereby transcends the
          singularity[of the object] ... untruth falls on memoryviewcycle:
          objective essence as community / continuity / properties not excluding
          / medium &lt -&gt; property determined, opposed to another / One that
          excludes
        </div>,

        <div className="font-mono">
          §118 conciousness' perceiving constituted ... * in its apprehension *
          is at the same time reflected out of teh Thing and into itself
        </div>,

        <div className="font-mono">
          conciousness recognizes that that it is the * untruth8 occuring in
          perception that falls within it
        </div>,

        <div className="font-mono">
          ... conciousness no longer merely perceives, but also is concious of
          its reflection into itself ... 'beyond' simple apprehension
        </div>,

        <div className="font-mono">
          §119 We get the entire diversity of these aspects, not ftom the Thing
          but from ourselves(white -&gt; eyes, tart -&gt; tongue, ...) We are
          thus the * universal medium*
        </div>,

        <div className="font-mono">
          §120 a One is the universal relating of self to self ... the thing
          excludes others through determinateness
        </div>,

        <div className="font-mono">
          Things are therefore in and for themselves determinate
        </div>,

        <div className="font-mono">
          they have properties by which they distinguish themselves from others.
        </div>,

        <div className="font-mono">
          ... the Thing is the * Also * , or the * universal medium * in which
          many properties subsist apart from eachother
        </div>,

        <div className="font-mono">
          §121 in perceiving, the opposite moment to the Also tuns up ... unity
          of the Thing with itself
        </div>,

        <div className="font-mono">
          ... the Thing itself is the subsistence of the many diverse &
          independent properties
        </div>,

        <div className="font-mono">
          Positing these properties as a oneness is the work of conciousness ...
          the Thing ... becomes ... an enclosing surface
        </div>,

        <div className="font-mono">§</div>,

        <div className="font-mono">§</div>,

        <div className="font-mono">§</div>,

        <div className="font-mono">§</div>,

        <div className="font-mono">§</div>,

        <div className="font-mono">§</div>,

        <div className="font-mono">§</div>,

        <div className="font-mono">§</div>,

        <div className="font-mono">§</div>,

        <div className="font-mono">§</div>,

        <div className="font-mono">§</div>,

        <div className="font-mono">§</div>,

        <div className="font-mono">§</div>,

        <div className="font-mono">§</div>,

        <div className="font-mono">§</div>,

        <div className="font-mono">§</div>,
      ]}
    />
  ),
};
