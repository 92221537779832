
import { EntryBody } from "../components/Entry/EntryBody";

export const notes_prolegomena: { [id: string]: any } = {

    id: "notes_prolegomena",
    title: <>Notes on the Prolegomena to Any Future Metaphysics; Kant</>,
    date: "Septembre 2023",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
PREFACE

</div>,

<div className="font-mono">
p258 The question was not whether the concept of cause was right, useful, and even indispensable for our knowledge of nature, for this Hume had never doubted; but whether that concept could be thought by reason _a priori_, and consequently whether it possessed an inner truth, independent from all experience, impying a more widely extended usefulness, not limited merely to objects of experience.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
PREAMBLE

</div>,

<div className="font-mono">
§1 metaphysics ... _a priori_ (philosophical) cognition, coming from pure understanding and pure reason.

</div>,

<div className="font-mono">
§2 analytic - explicative ; synthetic - ampliative

</div>,

<div className="font-mono">
b. The Common Principle of All Analytic Judgements is That of Contradiction ... the predicate of an affirmative analytic judgement is already thought in the concept of the subject, of which it acannot be denied without contradiction

</div>,

<div className="font-mono">
all analytical judgements are _a priori_ even when the concepts are empirical

</div>,

<div className="font-mono">
synthetic judgements:

</div>,

<div className="font-mono">
i. _Judgements of Experience_

</div>,

<div className="font-mono">
ii. _Mathematical Judgements_ 7+5=12

</div>,

<div className="font-mono">
iii. _Metaphysical Judgements_

</div>,

<div className="font-mono">
§3 (from Locke) analytic - identity or contradiction ; synthetic - coexistence of representations in a subject

</div>,

<div className="font-mono">
[Principle of Sufficient Reason: everything must have a reason, cause, or ground.]

</div>,

<div className="font-mono">
§5 analytical method (start from what is sought ... and ascend to the only conditions under which it is possible ... regressive (as opposed to synthetic/progressive))

</div>,

<div className="font-mono">
_How are synthetic propositions a prior possible?_

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
FIRST PART OF THE MAIN TRANSCENDENTAL QUESTION

</div>,

<div className="font-mono">
_How is Pure Mathematics Possible?_

</div>,

<div className="font-mono">
§7 mathematical cognition ... must first exhibit its concept in intuition, and do so _a prior_, in an intuition that is not empirical but pure

</div>,

<div className="font-mono">
§9 in one way only can my intuition anticipate the actuality of the object, and be a cognition _a prior_, _if my intuition contains nothing but the form of sensibility, which in me as subject precedes all the actual impressions through which I am affected by objects_.

</div>,

<div className="font-mono">
§10 the intuitions which pure mathematics lay at the foundation of all its cognitions and judgements which appear at once apodeictic and necessary are space ans time

</div>,

<div className="font-mono">
Geometry is based upon the pure intuition of space. Arithmetics ... by the successive addition of units in time.

</div>,

<div className="font-mono">
§11 At the basis of their [pure mathematics] empirical intuition lies a pure intuition (of space and time [mere form of sensibility/appearance]

</div>,

<div className="font-mono">
) , which is _a priori_.

</div>,

<div className="font-mono">
§12 the basis of mathematics ... is pure intuitions which make its synthetic and apodeictically valid propositions possible. (i.e. proofs pf congruence of 2 figures, 3 lines intersecting at right angles at one point)

</div>,

<div className="font-mono">
§13 spherical triangles example

</div>,

<div className="font-mono">
... appearances, whose possibility rests upon the relation of certain things unknown in theselves to ... our sensibility

</div>,

<div className="font-mono">
§ Remark I. ... Sensibility, the form of which is the basis of geomery, is that upon which the possibility fo external appearance depends. Therefore these appearances can never contain anything but what geometry prescribes to them.

</div>,

<div className="font-mono">
... this thought space renders possible physical space

</div>,

<div className="font-mono">
§ Remark II. ... *all the properties which constitute the intuition of a body belong merely to its appearance.* The existiencenof the thing that appears is thereby not destroyed as in genuine idealism, but it is only shown that we cannot possibly know it by the senses as it is in itself.

</div>,

<div className="font-mono">
§ Remark III. But the difference between truth and dreaming is not ascertained by the nature of the representations which are refered to objects (for they are the same in both cases [truth or not in the determination of the object]), but by their connection according to those rules which determine the coherence of the representation in the concept of an object, and by ascertaining whether they can subsist together in experience or not. ... whenever we connect our intuitions of sense ... in space & time, according to the rules of coherence of all cognition in experience, illusion or truth will arise according as we are negligent or careful. [regardless of the origin of sensuous representations]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
SECOND PART OF THE MAIN TRANSCENDENTAL QUESTION

</div>,

<div className="font-mono">
_How Is Our Natural Science Possible?_

</div>,

<div className="font-mono">
§14 Nature is the *existence* of things, so far as it is determined according to universal laws.

</div>,

<div className="font-mono">
My understanding and the conditions on which alone it can connect the determinations of things in their existence do not prescribe any rule ot things in themselves

</div>,

<div className="font-mono">
§15 "subatance is permanent" ... "[causality]" ... universal laws of nature which subsist completely a priori

</div>,

<div className="font-mono">
§16 Nature considered *materialiter* is the *totality of all objects of experience*.

</div>,

<div className="font-mono">
§17 how can we cognize *a priori* that things as objects of experience necesssary conform to laws? ... how is it possible to cognize *a priori* the necessary conformity to law of experience itself as regards all its objects generally?

</div>,

<div className="font-mono">
We cannot ... study the nature of things *a priori* otherwise than by investigating the conditions and the universal (though subjective) laws, under which alone such a cognition as experience (as to mere form) is possible, and we determine accordingly the possibility of things as objects of experience

</div>,

<div className="font-mono">
§18 Empirical judgements, so far as they have objective validity, are *judgements of experience*; but those which are only subjectively valid I name mere *judgements of perception* [§19 "The room is warm, sugar is sweet, and wormwood nasty"]

</div>,

<div className="font-mono">
§19 judgements of experience take their objective validity, not from the immediate cognition of the object (which is impossible), but merely from the condition of universal validity of empirical judgements, which ... never rests upon empirical or ... sensusous conditions, but upon a pure concept of the understanding

</div>,

<div className="font-mono">
&gt; is different the generalization of judgements of experience w/ objective validity (for us) on LMs?

</div>,

<div className="font-mono">
§20 judging may be twofold: first, I may merely compare perceptions and connect them in conciousness of my state [there arises no universal validity and necessity]; or, secondly, I may connect them in conciousness in general

</div>,

<div className="font-mono">
... before perception can become experience. The given intuition must be subsumed under a concept which determines the form of judging in general with regard to the intuition, connects the empirical conciousness of the ituition in conciousness in general, and thereby procures universal validity for empirical judgements ... pure *a priori* concept of the understanding

</div>,

<div className="font-mono">
If all our synthetic judgements are analyzed as far as they are objectively valid, ... they never consist of mere intuitions connected only ... in comparison into a judgement; but that they would be impossible were not a pure concept of the understanding superadded to the concept to the concepts abstracted from intuition

</div>,

<div className="font-mono">
§21 moments (functions) of the understanding in judgements

</div>,

<div className="font-mono">
LOGICAL TABLE OF JUDGEMENTS 

</div>,

<div className="font-mono">
1 *As to Quantity* Universal Particular Singular

</div>,

<div className="font-mono">
2 *As to Quality* Affirmative Negative Infinite

</div>,

<div className="font-mono">
3 *As to Relation* Categorical Hypothetical Disjunctive

</div>,

<div className="font-mono">
4 *As to Modality* Problematic Assertoric Apodeictic

</div>,

<div className="font-mono">
TRANSCENDENTAL TABLE OF THE CONCEPTS OF THE UNDERSTANDING 

</div>,

<div className="font-mono">
1 *As to Quantity* &gt; Unity (Measure) &gt; Plurality (Quantity) &gt; Totality (Whole)

</div>,

<div className="font-mono">
2 *As to Quality* Reality Negation Limitation

</div>,

<div className="font-mono">
3 *As to Relation* Substance Cause Community

</div>,

<div className="font-mono">
4 *As to Modality* Possibility Existence Necessity

</div>,

<div className="font-mono">
PURE PHYSIOLOGICAL TABLE OF THE UNIVERSAL PRINCIPLES OF NATURAL SCIENCE 

</div>,

<div className="font-mono">
1 Axioms of Intuition

</div>,

<div className="font-mono">
2 Anticipation of Perception

</div>,

<div className="font-mono">
3 Analolgies of Experience 

</div>,

<div className="font-mono">
4 Postulates of Empirical / Thought in General

</div>,

<div className="font-mono">
§22 senses ... intuit. understanding ... thinking ... uniting representations in a conciousness ... unification either contingent/subjective ... or necessary/objective.

</div>,

<div className="font-mono">
Experience consists in the synthetic connection of appearances (perceptions) in conciousness, so far as this connection is necessary. Hence the pure concepts of understanding are those under which all perceptions must first be subsumed before they can serve for judgements of experience, in which the synthetic unity of the perceptions is represented as necessaru & universally valid.

</div>,

<div className="font-mono">
§23 The principles of possible experience [conditions of unification given representations in a conciousness] are then at the same time universal laws of nature, which can be cognized *a priori*.

</div>,

<div className="font-mono">
§26 Analogies of Experience ... do not refer to the generation of intuitions ... but to the connection of their existence in experience; and this can be nothing but the determination of their existence in time according to necessary laws, under which alone the connection is objectively valid and thus becomes experience.

</div>,

<div className="font-mono">
§27 Hume"s doubt ... But I am very far from holding these concepts [causality etc] to be derived merely from experience, and the necessity represented in them to be fictious and a mere illusion produced in us by long habit.

</div>,

<div className="font-mono">
§29 in perception we may meet with a rule of relation ... that a certain appearance is constantly followed by another ... if the sun shines long enough upon a body it grows warm. Here there is indeed as yer no necessity of connection, or concept of cause. But I proceed and say that if this proposition [subjective connection of perceptions] ... is to be a judgement of experience, it must be regarded as necessary and universally valid. ... The empirical rule is now considered as a law, adn as valid not merely of appearances but valid of them for the purposes of a possibel experience which requires universal and therefore necessarily valid rules.

</div>,

<div className="font-mono">
§30 [pure concepts of the understanding] serve, as it were, only to spell out appearances, so that we may be able to read them as experience.

</div>,

<div className="font-mono">
"All syntehtic principles *a priori* are nothing more than principles of possible experience"

</div>,

<div className="font-mono">
§36 *How is nature itself possible?*

</div>,

<div className="font-mono">
FIRST: ... in the *material* sense, i.e. ... as the totality of appearances ... 

</div>,

<div className="font-mono">
answer: by means of the constitution of our sensibility, according to which it is in ites special way affected by objects which are in themselves unknown to it and totally distinct from those appearances

</div>,

<div className="font-mono">
in *Critique*, Transcendental Aesthetic; in *Prolegomena*, first main question

</div>,

<div className="font-mono">
SECOND: ... in the *formal* sense, as the totality of rules under which all appearances must come 

</div>,

<div className="font-mono">
answer: by means of the constitution of our understanding, acoording to which all those representations of sensibility are necessary referred to a conciousness ... but must be clearly distinguished from an insight into objects in themselves.

</div>,

<div className="font-mono">
in *Critique*, Transcendental Logic; in *Prolegomena*, second main question

</div>,

<div className="font-mono">
§38 example of lines intersecting within a circle and laws of celestial bodies

</div>,

<div className="font-mono">
§39 *Of the system fo categories*

</div>,

<div className="font-mono">
To search in our ordinary knowledge for the concepts which do not rest upon particular experience and yet occur in all knowledge from experience, of which they constitute as it were the mere form of connection, presupposes neither greater reflection nor deeper insight than to detect in a language the rules of the actual use of words generally and thus to collect the elements of grammar (in fact both inquiries are very closely related), ecen though we are not able to give a reason why each language has just this and no other formal constitution, and still less why exactly so many, neither more or less, of such formal determinations in general can be found in it.

</div>,

<div className="font-mono">
rundown of the discovery of categories, from Aristotle to logicians

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
THIRD PART OF THE MAIN TRANSCENDENTAL QUESTION

</div>,

<div className="font-mono">
*How Is Metaphysics in General Possible?*

</div>,

<div className="font-mono">
§40 the concepts of reason aim at the completeness, i.e., the collective unity of all possible experience, adn thereby go beyond every given experience. Thus they become *transcendent*.

</div>,

<div className="font-mono">
all illusion consists in holding the subjective ground of our judgements to be objective

</div>,

<div className="font-mono">
§41 *ideas* ... pure concepts of reason =/= categories, pure concepts of the understanding

</div>,

<div className="font-mono">
§43 seek the origin of ideas in the three functions of syllogisms [categorical, hypothetical, disjuntive]

</div>,

<div className="font-mono">
syllogism: major premise (A is B); minor premise (C is A); conclusion (C is B)

</div>,

<div className="font-mono">
syllogism | type of idea | idea

</div>,

<div className="font-mono">
categorical | psychological | idea of the complete subject (the substantial)

</div>,

<div className="font-mono">
hypothetical | cosmological | idea of the complete series of conditions

</div>,

<div className="font-mono">
disjuntive | theological | determination of all concepts in the idea of a complete complex of that which is possible

</div>,

<div className="font-mono">
§44 Pure reason does not in its ideas point to particular objects which lie beyond the field of experience, but only requires the completeness of the use of the understanding in the complex of experience ... the object [of reason] is only an idea invented for the porpuse of bringing the cognition of the understanding as near as possible to the completeness indicated by that idea

</div>,

<div className="font-mono">
PREFACTORY REMARK TO THE DIALCTIC OF PURE REASON

</div>,

<div className="font-mono">
§45 *noumena* pure beings of the understanding (beings of thought) "substance" but conceived without permanence in time

</div>,

<div className="font-mono">
I. THE PSYCHOLOGICAL IDEAS

</div>,

<div className="font-mono">
§46 the specific nature of our understanding consists in thinking everything discursively, i.e., by concepts, and so by mere predicates, to which, therefore, the absolute subject must always be wanting. [the completion that reason seeks]

</div>,

<div className="font-mono">
§47 thinking self (the soul) ... ultimate subject of thinking which cannot be further represented as the predicate of another thing [substance] ...

</div>,

<div className="font-mono">
But permanence can never be proved of the concept of substance as a thing in itself ... synthetic *a priori* porpositions can never be proved in themselves, but only in reference to things as objects of possible experience 

</div>,

<div className="font-mono">
§48 The permanence of the soul can therefore only be proved ... during the life of man, but not ... after death [no experience!]

</div>,

<div className="font-mono">
§49 That there is something real outside us which not only corresponds but must correspond to our external perceptions can likewise be proved to be, not a connection of things in themselves, but for the sake of experience.

</div>,

<div className="font-mono">
comment on cartesian idealism

</div>,

<div className="font-mono">
the concept "ouside us" only signifies existence in space

</div>,

<div className="font-mono">
II. THE COSMOLOGICAL IDEAS

</div>,

<div className="font-mono">
§50 the cosmological idea extends the connection of the conditioned with its condition ... so far that experience can never keep up with it ... always an idea, whose object can never be adequately given in any experience 

</div>,

<div className="font-mono">
§51 four transcendentent ideas ~ four classes of categories

</div>,

<div className="font-mono">
This antimony ... contains the following four theses together with their antithesis:

</div>,

<div className="font-mono">
# | Thesis | antithesis

</div>,

<div className="font-mono">
1 | The world has, as to time and space, a biginning (limit) | The world is, as to time and space, infinite

</div>,

<div className="font-mono">
2 | Everything in the world is constituted out of the simple | There is nothing simple, but everything is composite

</div>,

<div className="font-mono">
3 | There are in the world causes through freedom | There is no freedom, but all is nature

</div>,

<div className="font-mono">
4 | In the series of world-causes there is some necessary being | There is nothing necessary in the world, but in this series all is contingent

</div>,

<div className="font-mono">
§52b dialectical illusion of pure reason

</div>,

<div className="font-mono">
§53c 1,2 antimonies (mathematical) (an appearance as an object in itself) both false

</div>,

<div className="font-mono">
First Antimony

</div>,

<div className="font-mono">
space and time, together with the appearances in them, are nothing existing in themselves and outside of my representations

</div>,

<div className="font-mono">
This magnitude of the world [time/space infinite/finite] ... would therefore have to exist in the world itself apart from all experience

</div>,

<div className="font-mono">
as the concept of an absolutely existing world pf sense is se;f-contradictory, the solution of the problem concerning its magnitude ... is always false

</div>,

<div className="font-mono">
Second Antimony (division of appearances) 

</div>,

<div className="font-mono">
To assume that an appearance ... contains in itself before all experience all the parts which any possible experience can ever reach is to impute to mere appearance ... existence previous to experience 

</div>,

<div className="font-mono">
§53 3,4 antimonies (dynamical) both true

</div>,

<div className="font-mono">
Any mathematical connection necessarily presupposes homogeneity fo what is connected (in the concept of magnitude) ... but in the connection of cause and effect [dynamical] homogeneity ... is not necessary

</div>,

<div className="font-mono">
But if natural necessity is referred merely to appearances and freedom merely to things in themselves, no contradiction arises

</div>,

<div className="font-mono">
In appearance ... the effect must ... according to the universal law of nature, bre preceded by a determination of the causality of its cause (a state of the cause) ... But this determination of the cause to causal action must likewise be something that ... happens ... must have originated among appearances ... must again have its cause, and so on;

</div>,

<div className="font-mono">
if freedom ... starting [events] spontaneously ... but then the cause, as to its causality, ... cannot be an appearance, and must be considered a thing in itself, while only its effects would be appearances

</div>,

<div className="font-mono">
*ought* [connection]

</div>,

<div className="font-mono">
*reason* ... this property is that of a thing in itself ... how the *ought* (which might never yet have taken place) should determine its activity and could become the cause of actions whose effect is an appearance in the sensible world. Yet the causality of reason would be freedom wrt the effects in the sensuous world, so far as we can consider *objective grounds*, which are themselves ideas, as their determinants.

</div>,

<div className="font-mono">
necessity of nature ... determinability of every event in the world of sense according to constant laws, i.e., a reference to cause in the appearance; in this process the thing in itself as its foundation and its causality remain unknown. 

</div>,

<div className="font-mono">
the action, with regard to the causality of reason [not under conditions of time!], can be considered as a first beggining, while in respect to the series of appearances as a merely subordinate beggining [preceded by a state in appearance]

</div>,

<div className="font-mono">
fourth antimony: same

</div>,

<div className="font-mono">
III. THE TEOLOGICAL IDEA

</div>,

<div className="font-mono">
§55 *reason* ... totally breaks with experience and from mere concepts of what constitutes the absolute completeness of a thing in general (hence by means of the idea of a most perfect primal being) proceeds to determine the possibility and therefore the actuality of all other things

</div>,

<div className="font-mono">
CONCLUSION 

</div>,

<div className="font-mono">
On the Determination of the Bounds of Pure Reason

</div>,

<div className="font-mono">
§57 [354] The sensible world is nothing but a chain of appearances connected according to universal laws ... must point to that which contains the basis of this appearance ... things in themselves. In the cognition of them alone can reason hope to satisfy its desire for completeness in proceeding from conditioned to its conditions.

</div>,

<div className="font-mono">
[355] if we represent to ourselves a being of the understanding by nothing but pure concepts of the understanding ... nothing determinate ... has no significance; but if we think it by properties borrowed from the sensible world, then it is no longer a being of the understanding but is conceived as a phenomenon and belongs to the sensible world.

</div>,

<div className="font-mono">
understanding ... receive its intuitions by the senses ... bring them under rules of the unity of conciousness

</div>,

<div className="font-mono">
§58 Discussion on Supreme Being in relation to Hume"s Dialogues

</div>,

<div className="font-mono">
The expression suited to our feeble concepts is that we conceiver the world *as if* it came, regarding its existence and its inner determination, from a Supreme Reason.

</div>,

<div className="font-mono">
§59 ... the result of the whole *Critique*: "that reason by all its *a priori* principles never teaches us anything mode than objects of possible experience, and even of these nothing more than can be cognized in experience" ... Reason does not, however, teach is anything conecerning the thing in itself; it only instructs us as regards its own comlkete and highest use in the field of possible experience.

</div>,

<div className="font-mono">
§60 ... find out what natural ends intended by this disposuition to transcendent concepts in our reason, because everything that lies in nature must be originally intended for some useful porpuse

</div>,

<div className="font-mono">
The cosmological ideas, by which the obvious insufficiency of all possible cognition of nature to satisfy reason in its legitimate inquiry, serve in the same manner to keep us from naturalism, which asserts nature to be sufficient for itself.

</div>,

<div className="font-mono">
Thus the transcendental ideas serve, if not to instruct us positively, at least to destroy the impudent & restrictive assertions of materialism, naturalism and of fatalism [this sentence is pure comedy in 2023 kek]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
SOLUTION OF THE GENERAL QUESTION OF THE PROLEGOMENA

</div>,

<div className="font-mono">
"How Is Metaphysics Possible as Science?"

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
That the human spirit will ever give up metaphysical researches is as little to be expected as that we should prefer to give up breathing altogether, in order to avoid inhaling impure air.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
For in mathematics [as opposed to metaphysics] I can by thinking construct whatever I represent to myself as possible by a concept

</div>,

      ]}
    />
  ),
};
