import { useState, useEffect } from "react";
import { EntryBody } from "../components/Entry/EntryBody";
import { EndlessConversation } from "../components/EndlessConversation/EndlessConversation";

export const endless_conversation: { [id: string]: any } = {
  id: "endless_conversation",
  title: <>Saying Nothing</>,
  date: "Dec 2023",

  Body: (
    <EntryBody
      paragraphs={[
        <div className="font-mono">
          Practical entry! Practical entry! Everybody gather around!!!
        </div>,
        <div className="font-mono">
          <EndlessConversation />
        </div>,

        <div className="font-mono"> </div>,
      ]}
    />
  ),
};
