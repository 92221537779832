
import { EntryBody } from "../components/Entry/EntryBody";

export const implications_of_generics_removal: { [id: string]: any } = {

    id: "implications_of_generics_removal",
    title: <>The implications of removing the bias of generics from a LM language distribution</>,
    date: "22 Feb 24",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
Humans treat striking characteristic generics weirdly, even more weirdly than minority ones. This weirdness comes from comparing it to quantifiers such as "all", "some" and "most". Since generics has no operator, and dangerous properties are difficult to label as dangerous without access to experience, modelling them to having the same bias seems non-trivial (from the distributional structure of language). Regardless, current LMs seem to show those bias, especially during pre-training, although they may be removed on some generation cases with instruction tuning (still the different treatment of striking generics is apparent in perplexities). Funnily enough the minority ones seem to be just as majority (when ot taking the literature cases)

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
This default generalisation mood expressed by generics in language is said to be part of prejudiced language. So I can understand removing the prejudiced language, but what if you were to remove this rich dynamics with striking property generics? This would be a strategy to, "at depth", tackle that problem.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
But is it not these striking generics, as a way of thinking, something that Makes us human?

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
LMs are injected to where humans are softest: language games. We learn to learn, and to learn from others, and to change views etc on language games. We are so gullible and naive in language games, we accept rules because to create the language common world, the acceptance of rules into practice is at its core. For us to engage in a language game with a language model, the language model has to be passable, at first. But once the distribution of generated language adjusts to the rules of practice, there is still so much wiggle room, because these rules of practice are greatly cosmetic. Consider what makes a conversation a conversation: how things are said rather than what it is said. What makes asking help asking help? What makes flirting flirting?

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
We are now removing something so human from the language of a speaker in a language game. "So human" in a very pure sense, because it is trivial to understand in everydayness but hellish on all the formalisms we created to understand ourselves. If an accepted and acceptable speaker does not have this, it is possible that removes this bias from the rest of speakers in the language game, especially because this speaker has not the possibility of dynamically changing their rules, while we are primed to do it, as a condition for understanding.

</div>,

      ]}
    />
  ),
};
