
import { EntryBody } from "../components/Entry/EntryBody";

export const cover: { [id: string]: any } = {

    id: "cover",
    title: <>Sigueu benvingudes!</>,
    date: "7 Oct 2024",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
This is my cybernetic space.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Chapter . is a collection of typewritten delirious thoughts had during summer 2022.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
I would recomment reading random exerts from Chapter I.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Chapter II is the new stuff!

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Thanks for visiting!

</div>,

      ]}
    />
  ),
};
