
import { EntryBody } from "../components/Entry/EntryBody";

export const notes_critique: { [id: string]: any } = {

    id: "notes_critique",
    title: <>Notes on the Critique of Pure Reason; Kant</>,
    date: "Oct 2023",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
The notes from my first reading are in a notebook I have not transcribed yet, these are notes from specific parts of interest.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
A537/B565 If ... appearances count as .... mere presentations connected according to empirical laws, then they must themselves still have bases that are not appearances. But such an intelligible cause is not, as regards to its causality, determined by appearances, although its effects appear and thus can be determined by other appearances. Hence this cause, along with its causality, is outside the series of empirical conditions, whereas its effects are encountered within the series.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
A538/B566 What in an object of the senses is not itself appearance I call intelligible.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
A544/B572 And is it not possible, rather, that although every effect in [the realm of] appearance does indeed require a connection wit its cause according to laws of empirical causality, yet this epirical causality itself could nonetheless, without in the least interrupting its connection with natural causes, be an effect of a causality that is not empirical but intelligible?

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
A546/B574 The appearances must be capable of being explained by pursuing, as the supreme basis of explanation, their merely empirical character, and by enterely bypassing as unknown the intelligible character that is the empirical character"s transcendental cause -- except insofar as this intelligible character is indicated by the empirical character as the intelligible"s sensible sign.

</div>,

      ]}
    />
  ),
};
