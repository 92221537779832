
import { EntryBody } from "../components/Entry/EntryBody";

export const notes_mallory: { [id: string]: any } = {

    id: "notes_mallory",
    title: <>Notes on writtings by Fintena Declan Mallory</>,
    date: "July 24",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
Wittgenstein, the Other, & Large Language Models

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
1. Large Language Models

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Taking an old but concrete example, BERTLARGE is a neural network with 24 layers, 340 million parameters and is trained on a corpus containing 320 million words (Devlin et al., 2018). Before being $netuned, it is trained on next-word prediction and next-sentence prediction tasks. [this is not true, bert is MLM]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
2.2 Semantic anti-foundationalism

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
A common form of semantic foundationalism is the idea that the foundational language game we play is the assertion of true or false declarative sentences.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Truth-theoretic foundationalism provides the strongest criticism of contemporary language models.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The idea that language has a descriptive function that underlies all others is one of Wittgenstein’s main targets in the Philosophical Investigations.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
2.3 The Wittgenstein Benchmark

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
In a sense, semantic anti-foundationalism is akin to the claim that no language game is ‘AI-complete’.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
2.4 Normativity of meaning

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
They simply predict the most probable output token in a deterministic fashion. [false in most decoding algorithms, distinction between LM & LM-system?]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
but in such a network, there is no single vector that corresponds to the meaning of a sentence. [the whole space is needed? to understand a sentence is to understand the language]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The Wittgensteinian moral is that, just because we can’t point to ‘the meaning’ in the system, this doesn’t mean that the output is meaningless.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
2.5 Understanding

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
It may involve granting that LLMs produce meaningful text but denying that this is an indication of any intelligence on their part.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
3. Responses

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Hubert Dreyfus’s criticisms of ‘Good Old Fashioned Arti$cial Intelligence’

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
a grasp of semantic content, even if this doesn’t involve appeal ‘meanings’ requires something like human-like experience

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
At present LLMs are trained on datasets of human-generated texts with the hope that they will acquire natural languages from this, but they don’t have the distinctly human experiences which connect these texts to the world.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Some semantic externalists have argued that a machine would require ‘direct causal interaction with the environment’ for their words to stand in the appropriate causal chains to refer to kinds within the world (Schweizer, 2012).

</div>,

<div className="font-mono">
[does not LM-generated text impact quite directly the development of new LM-systems? code-completion, instruction-datasets, social-acceptance? if we consider LMs under some sort of unity, i guess]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Perhaps a better goal than producing machines with ‘linguistic understanding’, a notion which Wittgenstein called into question, might be to aim for arti$cial ‘linguistic agency’.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
As long as language models fail to be members of our linguistic communities, they are not bound by these norms and so not entitled to ascriptions of content. One might phrase this thought as follows: You shouldn’t play the languagegame of meaning and understanding unless you are also willing to play the language game of responsibility and we can’t play the language game of responsibility with LLMs because LLMs are responsible to no one.

</div>,

      ]}
    />
  ),
};
