import { Entry } from "../../components/Entry/Entry";
import { useScrollIntoView } from "@mantine/hooks";
import { useDebugValue, useRef, useCallback } from "react";
import { JournalIndexHover } from "../../components/JournalIndexHover/JournalIndexHover";
// ENTRIES
import { lorem_ipsum } from "../../entries/lorem_ipsum";
import { not_instructions } from "../../entries/not_instructions";
import { pictures_of_god_moneymaker_title } from "../../entries/pictures_of_god_moneymaker_title";
import { notes_philosophical_investigations } from "../../entries/notes_philosophical_investigations";
import { anthropomorphising_llms } from "../../entries/anthropomorphising_llms";
import { notes_wsl } from "../../entries/notes_wsl";
import { misleading_parallels_language_and_experience } from "../../entries/misleading_parallels_language_and_experience";
import { notes_on_memorisation } from "../../entries/notes_on_memorisation";
import { notes_phenomenology_of_spirit } from "../../entries/notes_phenomenology_of_spirit";
import { a_funny_reflection_on_cells } from "../../entries/a_funny_reflection_on_cells";
import { nets_as_metaphysical } from "../../entries/nets_as_metaphysical";
import { useParams } from "react-router-dom";
import { groundwork_discussion_causality_lms } from "../../entries/groundwork_discussion_causality_lms";
import { notes_enquiry_concerning_human_understanding } from "../../entries/notes_enquiry_concerning_human_understanding";
import { wee_experiment_llm_interaction } from "../../entries/wee_experiment_llm_interaction";
import { notes_prolegomena } from "../../entries/notes_prolegomena";
import { notes_on_grokking } from "../../entries/notes_on_grokking";
import { notes_aspects_chomsky } from "../../entries/notes_aspects_chomsky";
import { notes_frege } from "../../entries/notes_frege";
import { notes_russell } from "../../entries/notes_russell";
import { topology_of_experience } from "../../entries/topology_of_experience";
import { notes_critique } from "../../entries/notes_critique";
import { phonetics_and_embeddings } from "../../entries/phonetics_and_embeddings";
import { notes_theory_of_language_harris } from "../../entries/notes_theory_of_language_harris";
import { notes_harris_comments } from "../../entries/notes_harris_comments";
import { notes_strawson } from "../../entries/notes_strawson";
import { notes_lewis } from "../../entries/notes_lewis";
import { notes_quine } from "../../entries/notes_quine";
import { boring_god_theory } from "../../entries/boring_god_theory";
import { generics_hot_takes } from "../../entries/generics_hot_takes";
import { language_games_for_meaning } from "../../entries/language_games_for_meaning";
import { danger_and_saving_of_language_modeling } from "../../entries/danger_and_saving_of_language_modeling";
import { conversation_and_information } from "../../entries/conversation_and_information";
import { endless_conversation } from "../../entries/endless_conversation";
import { tools_in_the_world } from "../../entries/tools_in_the_world";
import { seeing_and_reading } from "../../entries/seeing_and_reading";
import { alpaca_dataset } from "../../entries/alpaca_dataset";
import { implications_of_generics_removal } from "../../entries/implications_of_generics_removal";
import { dialectic_disentanglement_generics } from "../../entries/dialectic_disentanglement_generics";
import { language_as_calculus_without_reference } from "../../entries/language_as_calculus_without_reference";
import { blender_i } from "../../entries/blender_i";
// import { ReactComponent as InfluenceHessian } from "../../assets/equations/memorisation/influence-hessian.svg";
// <InfluenceHessian className="w-full" />
import { VideoBody } from "../../components/Entry/VideoBody";
import { video_forms_tractatus } from "../../entries/video_forms_tractatus";
import { video_sycorax } from "../../entries/video_sycorax";
import { notes_heidegger } from "../../entries/notes_heidegger";
import { video_being_time_i } from "../../entries/video_being_time_i";
import { infinite_mesh_manual } from "../../entries/infinite_mesh_manual";
import { ontology_early_language_modeling } from "../../entries/ontology_early_language_modeling";
import { notes_understanding_understanding } from "../../entries/notes_understanding_understanding";
import { bellybutton_theory } from "../../entries/bellybutton_theory";
import { notes_mallory } from "../../entries/notes_mallory";
import { notes_husserl } from "../../entries/notes_husserl";
import { notes_bosse } from "../../entries/notes_bosse";
// Used by JournalIndexHover, careful if moving
export const entries = [
  infinite_mesh_manual,
  video_sycorax,
  // lorem_ipsum,
  danger_and_saving_of_language_modeling,
  nets_as_metaphysical,
  wee_experiment_llm_interaction,
  groundwork_discussion_causality_lms,
  endless_conversation,
  conversation_and_information,
  language_games_for_meaning,
  phonetics_and_embeddings,
  video_forms_tractatus,
  bellybutton_theory,
  topology_of_experience,
  generics_hot_takes,
  dialectic_disentanglement_generics,
  blender_i,
  anthropomorphising_llms,
  not_instructions,
  ontology_early_language_modeling,
  pictures_of_god_moneymaker_title,
  misleading_parallels_language_and_experience,
  boring_god_theory,
  a_funny_reflection_on_cells,
  video_being_time_i,
  tools_in_the_world,
  seeing_and_reading,
  alpaca_dataset,
  implications_of_generics_removal,
  language_as_calculus_without_reference,
  //
  notes_prolegomena,
  notes_heidegger,
  notes_husserl,
  notes_theory_of_language_harris,
  notes_harris_comments,
  notes_philosophical_investigations,
  notes_on_memorisation,
  notes_on_grokking,
  notes_phenomenology_of_spirit,
  notes_critique,
  notes_enquiry_concerning_human_understanding,
  notes_wsl,
  notes_quine,
  // notes_lewis,
  notes_aspects_chomsky,
  notes_russell,
  notes_strawson,
  notes_frege,
  notes_understanding_understanding,
  notes_mallory,
  notes_bosse,
];

export const Chapter_One = () => {
  // if path is /entry/:entryId, show only that entry
  // else show all entries
  let { entryId } = useParams();
  // check if entryId is not undefined
  let singleEntry;
  entryId
    ? (singleEntry = entries.find((entry) => entry.id === entryId))
    : (singleEntry = entries[0]);

  return (
    <div className="flex flex-col ">
      <div>
        <JournalIndexHover entries={entries} chapter="chapter_one" />
      </div>
      <div>
        {entryId ? (
          <div className="flex flex-col items-center w-full h-full  p-5 pr-2 pl-2 sm:pr-5 sm:pl-5 space-y-1">
            <Entry
              title={singleEntry ? singleEntry.title : "Lorem Ipsum"}
              date={singleEntry ? singleEntry.date : "now!"}
              Body={singleEntry ? singleEntry.Body : <>"Lorem Ipsum"</>}
            />
          </div>
        ) : (
          <div className="flex flex-col items-center w-full h-full p-5 pr-2 pl-2 sm:pr-5 sm:pl-5 space-y-10 ">
            {/*loop through the entries array to create an entry for each element*/}
            {entries.map((entry, index) => (
              <Entry title={entry.title} date={entry.date} Body={entry.Body} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
