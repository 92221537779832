
import { EntryBody } from "../components/Entry/EntryBody";

export const topology_of_experience: { [id: string]: any } = {

    id: "topology_of_experience",
    title: <>The Puzzling Topology of Experience</>,
    date: "Oct 2023",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
Experience feels continuous & at the same time so seamlessly discrete.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
[An object feels as natural as an infinite.]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
In a kantian sense, understanding as discrete and reason as completeness (continuous).

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
I like this idea of the discrete being a necessity to build up experience per se, and the continuous as the space where we reflect on such experience.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Now how do we imagine this tension from within a language model.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Language is tokenized [basically split into (sub)words] for the LM.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Yet internal representations [dense vectors] are in a continuous space.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
This space of model parameters is continuous because we take derivatives [infinitessimal change] during backpropagation.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The change in model parameters comes from a loss [a comparison of the distribution of next words the model seems possible and the next word in the training data].

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
A priori: the data is discrete [words or tokens], but the internal representations of the model [where algorithms are implemented during training] are in a continuous space so that they can change. What these algorithms ought to do, is determined by the loss and the data.

</div>,

      ]}
    />
  ),
};
