
import { EntryBody } from "../components/Entry/EntryBody";

export const notes_russell: { [id: string]: any } = {

    id: "notes_russell",
    title: <>Notes on different readings; Russell</>,
    date: "sept-oct23",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
II - ON DENOTING

</div>,

<div className="font-mono">
https://www.jstor.org/stable/3840617

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p479 Thus a phrase is denoting solely in virtue of its _form_.

</div>,

<div className="font-mono">
The distinction between _acquaintance_ and _knowledge about_ is the distinction between the things we have presentations of, and the things we only reach by means of denoting phrases.

</div>,

<div className="font-mono">
p480 "C(x)" proposition in which x is a constituent, where x, the variable, is essentially & wholly undetermined

</div>,

<div className="font-mono">
everything, nothing, something ... most primitive of denoting phrases

</div>,

<div className="font-mono">
C(everything) means "C(x) is always true"

</div>,

<div className="font-mono">
C(nothing) means "'C (x) is false' is always true"

</div>,

<div className="font-mono">
C(something) means "It is false that 'C (x) is false' is always true"

</div>,

<div className="font-mono">
"C(x) is always true" taken as ultimate and indefinable

</div>,

<div className="font-mono">
occur. This is the

</div>,

<div className="font-mono">
denoting phrases [i.e. everything, nothing, something] never have any meaning in themselves, but that every proposition in whose verbal expression they occur has a meaning

</div>,

<div className="font-mono">
p481

</div>,

<div className="font-mono">
"All men are mortal" means "'If x is human, x is mortal' is always true" ... "C(all men)" means "'If x is human, then C(x) is true' is always true".

</div>,

<div className="font-mono">
Now *the*, when it is strictly used, involves uniqueness

</div>,

<div className="font-mono">
p482 The above gives a reduction of all propositions in which denoting phrases occur to forms in which no such phrases occur.

</div>,

<div className="font-mono">
The evidence for the above theory is derived from the difficulties which seem unavoidable if we regard denoting phrases as standing for genuine constituents of the propositions in whose verbal expressions they occur.

</div>,

<div className="font-mono">
p483 Frege's theory ... in a denoting phrase, two elements, ... the *meaning* & the *denotation*

</div>,

<div className="font-mono">
p485 three puzzles:

</div>,

<div className="font-mono">
(1) a is identical with b ... either may be substituted for the other in any proposition without altering the truth or falsehood ... George IV wished to know whether Scott was the author of Waverley ... George IV wished to know whether Scott was Scott

</div>,

<div className="font-mono">
(2) excluded middle ... "A is B" or "A is not B" ... "the present King of France is (not) bald" 

</div>,

<div className="font-mono">
(3) "A differs from B" ... "the difference between A and B subsists". But how can a non-entity be the subject of a proposition?

</div>,

<div className="font-mono">
p486 to speak about the meaning of a denoting phrase, as opposed to its denotation, the natural mode of doing so is by inverted commas

</div>,

<div className="font-mono">
when C occurs it is the denotation that we are speaking about; but when " C " occurs, it is the meaning.

</div>,

<div className="font-mono">
logical relationship between meaning and denotation ... the meaning denotes the denotation

</div>,

<div className="font-mono">
But the difficulty which confronts us is that we cannot succeed in *both* preserving the connexion of meaning and denotation *and* preventing them from being one and the same

</div>,

<div className="font-mono">
p488 According to the view which I advocate, a denoting phrase is essentially part of a sentence, and does not, like most single words, have any significance on its own account.

</div>,

<div className="font-mono">
"Scott was the author of Waverley" -&gt; "One and only one entity worte Waverley, adn Scott was identical with that one" -&gt; "It is not always false of x that x wrote the Waverley, that it is always true of y that if y wrote the Waverley y is identical with x, and that Scott is identical with x."

</div>,

<div className="font-mono">
Thus if "C" is a denoting phrase, it may happen that there is one entity x (there cannot be more than one) for which the proposition "x is identical with C" 

</div>,

<div className="font-mono">
&gt; wtf does "there cannot be more than one" mean?! *some kid comes down the street in a strawhat and a red shirt "the king of the pirates" i say... double denotation?!*

</div>,

<div className="font-mono">
Thus Scott is the denotation of "the author of Waverley ". The "C" in inverted commas will be merely the phrase, not anything that can be called the meaning. The phrase per se has no meaning

</div>,

<div className="font-mono">
p489 A secondary occurrence of a denoting phrase may be defined as one in which the phrase occurs in a proposition p which is a mere constituent of the

</div>,

<div className="font-mono">
proposition we are considering, and the substitution for the denoting phrase is to be effected in p, not in the whole proposition concerned.

</div>,

<div className="font-mono">
p490 "the King of France is not bald " is false if the occurrence of " the King of France" is primary, and true if it is secondary

</div>,

<div className="font-mono">
primary: "There is an entity which is now King of France and is not bald"

</div>,

<div className="font-mono">
secondary: "It is false that there is an entity which is now King of France and is bald"

</div>,

<div className="font-mono">
p491 A proposition about Apollo means what we get by substituting what the classical dictionary tells us is meant by Apollo, say " the sun-god". ... If "Apollo" has a primary occurrence, the proposition containing the occurrence is false; if the occurrence is secondary, the proposition may be true.

</div>,

<div className="font-mono">
p492 when there is anything with which we do not have immediate acquaintance, but only definition by denoting phrases, then the propositions in which this thing is introduced by means of a denoting phrase do not really contain this thing as a constituent, but contain instead the constituents expressed by the several words of the denoting phrase. Thus in every proposition that we can apprehend (i.e. not only in those whose truth or falsehood we can judge of, but in all that we can think about), all the constituents are really entities with which we have immediate acquaintance. 

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
V. - KNOWLEDGE BY ACQUAINTANCE AND KNOWLEDGE BY DESCRIPTION

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p108 What do we know in these cases, where the subject is merely described?

</div>,

<div className="font-mono">
I say that I am acquainted with an object when I have a direct cognitive relation to that object, i.e. when I am directly aware of the object itself. ... S has acquaintance with O is essentially the same thing as to say that O is presented to S

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p111 Awareness of universal is called conceiving, and a universal of which we are aware is called a concept.

</div>,

<div className="font-mono">
And universal relations, too, are objects of awarenesses

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p112 Among particulars I include all existents, and all complexes of which one or more constituienlts are existents, such as this-before-that, this-above-that, the-yellowness-of-this. Among universals I include all objects of which no particular is a constituent. 

</div>,

<div className="font-mono">
universal-particular/abstract-concrete

</div>,

<div className="font-mono">
[we are no acquainted with physical objects or minds]

</div>,

<div className="font-mono">
By a " description" I mean any phrase of the form "a so-and-so" [ambiguous] or "the so-and-so" [definite].

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p113 I shall say that an object is "known by description" when we know that it is "the so-and-so," i.e. when we know that there is one object, and no more, having a certain property; and it will generally be implied that we do not have knowledge of the same object by acquaintance.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p114 the thought in the mind of a person using a proper name correctly can generally only be expressed explicitly if we replace the proper name by a description

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p117 The fundamental epistemological principle in the analysis of propositions containing descriptions is this: Every proposition which we can understand must be composed wholly of constituents with which we are acquainte

</div>,

<div className="font-mono">
judgment, as an occurrence, I take to be a relation of a mind to sevelal entities, namely, the entities which compose wlhat is judge

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p118 whenever a relation of supposing or judging occurs, the terms to which the supposing or judging mind is related by the relation of suppossing or judging must be terms with which the mind in question is acquainted

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p127 The actual object (if any) which is the denotation is not (unless it is explicitly mentioned) a constituent of propositions in which descriptions occur; and this is the reason why, in order to understand such propositions, we need acquaintance with the constituents of the description, but do not need acquaintance with its denotation
</div>,

      ]}
    />
  ),
};
