import { EntryBody } from "../components/Entry/EntryBody";

export const anthropomorphising_llms: { [id: string]: any } = {
  id: "anthropomorphising_llms",
  title: <>Anthropomorphising LLMs is great!! (no cap)</>,
  date: "22.07.23",

  Body: (
    <EntryBody
      paragraphs={[
        <div className="font-mono"></div>,

        <div className="font-mono">
          Consider the following sentences taken from random papers:
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          &gt; To achieve a good performance, the model capacity of LLMs in
          understanding the question and generating the response ...
        </div>,

        <div className="font-mono">
          &gt; Table 3 demonstrates that models are significantly more prone to
          hallucinate when translating out of English.
        </div>,

        <div className="font-mono">
          &gt; We test whether GPT-2 has begun to learn how to translate from
          one language to another.
        </div>,

        <div className="font-mono">
          &gt; To spot-check whether the model is simply memorizing specific
          arithmetic problems ...
        </div>,

        <div className="font-mono">
          &gt; ... we find that GPT-4 demonstrates a significant improvement
          over ChatGPT: GPT-4 shows a deeper understanding of the problem
        </div>,

        <div className="font-mono">
          &gt; and is able to apply the appropriate reasoning in many
          complicated problems
        </div>,

        <div className="font-mono">
          &gt; Our findings suggest that GPT-4 has a very advanced level of
          theory of mind.
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          Okay yeah last 2 I went easymode on the sparks paper sorry but you get
          the gist
        </div>,

        <div className="font-mono">
          Some expressions in these sentences have something like a scientific
          definition, but for the most part, they seem to be everyday words as
          used in contexts where humans behave in a certain way. Hallucinate,
          for example, can have a clear technical definition (detached
          generation) but... understanding? This word we use so much when
          talking about NLP models seems to elude any sort of scientific
          formalization.
        </div>,

        <div className="font-mono">
          Now how much of a problem is this? Using these words usually reserved
          for humans to describe how models do, is it problematic? Where does
          the confusion originate?
        </div>,

        <div className="font-mono">
          Day 1 of english class at school (in a non-english speaking country):
          kids are clueless.
        </div>,

        <div className="font-mono">
          Day 100 of english class: The teacher gives the kid a sentence in
          spanish and the kid translates it to english, maybe just word by word
          at the beggining.
        </div>,

        <div className="font-mono">
          The teacher then says "This kiddo has begun to learn how to translate
          from one language to another". Utterly unproblematic.
        </div>,

        <div className="font-mono">
          A more psychology infatuated teacher may say: "The kid remembers what
          english words are in spanish, and says the sentence to themselves in
          spanish trying to see if it sounds correct, therefore they have begun
          to learn how to translate" Or you get it, any statement that conveys
          some sort of theory or assumption of the process that happened before,
          during and after the kid translated "Vull dinar una truita." with "I
          want an omelette for lunch."
        </div>,

        <div className="font-mono">
          Yet both sentences show up in virtually the same context, therefore
          they should mean quite similar things.
        </div>,

        <div className="font-mono">
          Here the observation is the context of the expression "learning to
          translate". On the one hand, we have purely the input-output of the
          kid and its characteristics. For example, some of the translations
          might not be very good (as per the bilingual teacher) and over time
          the will get better. But also (and quite frequently, in common
          situations) in this same context we talk about different psychological
          processes that may be involved. Therefore in a way when we say "learn
          to translate" we also refer to description of these processes.
        </div>,

        <div className="font-mono">
          There is a reason why these pseudotechnical terms are so successful in
          NLP practice, both in formal and more informal contexts (and no, this
          is not gonna be a jab at the quality of research in the field!! ;)):
        </div>,

        <div className="font-mono">
          They are way more precise than what one would expect from terms
          directly "borrowed" from common language. Why? Because models work
          with textual language, and it is very clear for us what "learning to
          translate" means in terms of the progressive quality of translations
          (in the form of text).
        </div>,

        <div className="font-mono">
          Similarly, we know when we say somebody is hallucinating (in a chat
          conversation, for example), as they say things that are unrelated with
          the current context. Note how in the technical NLP case, detached
          generation refers to examples exactly like this.
        </div>,

        <div className="font-mono">
          These "antropomorphised" words are very accurate descriptions of the
          textual behaviour of the model.
        </div>,

        <div className="font-mono">
          The problem comes when we also consider other aspects of the contexts
          in which we use these words, for example, the hidden and mysterious
          processes of the mind, and try to apply them to the LLM case.
        </div>,

        <div className="font-mono">
          For example, take the friend "hallucinating" in a group chat example,
          we may say "He is on drugs", or "He thinks he is somebody else", etc.
          All these references to processes of the mind are also an aspect of
          the meaning of the word "hallucinate".
        </div>,

        <div className="font-mono">
          Conclusion: antropomorphising LLMs is unironically great, because
          gives (an otherwise hardly achievable) concise description of the
          model textual behaviour. The only issue is if we take these words to
          mean mental processes rather than textual behaviour.
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          Posterior Note: An example of this happened when talking to a (non-NLP
          researcher friend). He said something along the lines of: "Models
          undoubtedly think!". Let"s follow a "§66 ... Don"t think, but look!"
          (Philosophical Investigations, W) approach. If we think (about the
          meaning of "think") we get tangled on many metaphysical theories and
          mystical impossibles about what thought, or thinking, is. This
          what-i-understand-the-mind-does-when-it-thinks does not transfer well
          to talking about LMs, as these are speciic theories of the mind that
          are unlikely to have anything to do with a model in a GPU. If we look,
          the context of the word think usually refers to a time between
          (communication) events: a language/context in -&gt; think -&gt;
          language/context out sort of process. This is something easily
          translatable to what LLMs do, specially or language. In order to dodge
          the mind-theory connotations of think, I suggested that maybe we
          should say that LLMs _behave_, especially to highlight that what is
          language for us is Experience or World for the LLM, not Langauge.
        </div>,
      ]}
    />
  ),
};
