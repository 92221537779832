import { FC, ReactNode } from "react";
import { Text } from "@mantine/core";
interface Props {
  title: ReactNode;
  date: string;
  Body: ReactNode;
}

export const Entry: FC<Props> = ({ title, date, Body }) => {
  return (
    <div
      className="lg:w-2/3 flex flex-col font-mono text-justify cursor-not-allowed"
      key={date}
    >
      <div className="flex flex-row justify-between mb-7 border-b-2 space-x-10">
        <div className="text-xl">{title}</div>{" "}
        <div className="text-lg">{date}</div>
      </div>
      <div>{Body}</div>
      <div className="text-center mt-5">* * *</div>
    </div>
  );
};
