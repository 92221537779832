
import { EntryBody } from "../components/Entry/EntryBody";

export const notes_strawson: { [id: string]: any } = {

    id: "notes_strawson",
    title: <>Notes on different readings; Strawson</>,
    date: "",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">
ON REFERING

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
I

</div>,

<div className="font-mono">
p320 uniquely referring use: use expressions of certain kinds to mention or refer to some individual person or single object or particular event or place or proces

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p321 What question or questions about phrases of the form "the so-and-so" was the Theory of Descriptions designed to answer? [go get them strawhat!!]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
In fact S ["The king of France is wise"], although grammatically it has a singular subject and a predicate, is not logically a subject-predicate sentence at all. The proposition it expresses is a complex kind of existentital proposition, part of which might be described as a " uniquely existential " proposition. To exhibit the logical form of the proposition, we should re-write the sentence in a logically appropriate grammatical form; in such a way that the deceptive similarity of S to a sentence expressing a subject-predicate proposition would disappear, and we should be safeguarded against arguments such as the bad ones I outlined above.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p323 Russell recognises only two ways in which sentences which seem, from their grammatical structure, to be about some particular person or individual object or event, can be significant:

</div>,

<div className="font-mono">
(1) The first is that their grammatical form should be misleading as. to their logical form, and that they should be analysable, like S, as a special kind of existential sentence;

</div>,

<div className="font-mono">
(2) The second is that their grammatical subject should be a logically proper name, of which the meaning is the individual

</div>,

<div className="font-mono">
thing it designates.

</div>,

<div className="font-mono">
I think that Russell is unquestionably wrong in this, and that sentences which are significant, and which begin with an expression used in the uniquely referring way fall into neither of these two classes

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p324 According to Russell, anyone who asserted S would be asserting that:

</div>,

<div className="font-mono">
(1) There is a king of France.

</div>,

<div className="font-mono">
(2) There is not more than one king of France.

</div>,

<div className="font-mono">
(3) There is nothing which is king of France and is not wise.

</div>,

<div className="font-mono">
... The way in which he arrived at the analysis was clearly by asking himself what would be the circumstances in which we would say that anyone who uttered the sentence S had made a true assertion.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
II

</div>,

<div className="font-mono">
p326 we cannot talk of the sentence being true or false, but only of its being used to make a true or false assertion

</div>,

<div className="font-mono">
"Mentioning", or " referring", is not something an expression does; it is something that some one can use an expression to do.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p327 The expression "I" may correctly be used by (and only by) any one of innumerable people to refer to himself. To say this is to say something about the expression " I ": it is, in a sense, to give its meaning. This is the sort of thing that can be said about expressions. But it makes no sense to say of the expression " I " that it refers to a particular person. This is the sort of thing that can be said only of a particular use of the expression.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Meaning (in at least one important sense) is a function of the sentence or expression; mentioning and referring and truth or falsity, are functions of the use of the sentence or expression. To give the meaning of an expression (in the sense in which I am using the word) is to give general directions for its use to refer to or mention particular objects or persons; to give the meaning of a sentence is to give general directions for its use in making true or false assertions.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p328 Hence the troublesome mythology of the logically proper name.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
I explain and illustrate the conventions govermng the use of the expression. This is giving the meaning of the expression. It is quite different from giving (in any sense of giving) the object to which it refers; for the expression itself does not refer to anything; though it can be used, on different occasions, to refer to innumerable things.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p329 The question whether the sentence is significant or not is the question whether there exist such language habits, conventions or rules that the sentence logically could be used to talk about something

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
III

</div>,

<div className="font-mono">
"Implies " in this sense is certainly not equivalent to "entails" (or "logically implies")

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p333 It is precisely because tlhe significance of the word "this" is independent of any particular reference it may be used to make, though not independent of the way it may be used to refer, that I can, as in this example, use it to pretend to be referring to something.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The general moral of all this is that communication is much less a matter of explicit or disguiised assertion than logicians used to suppose.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
IV

</div>,

      ]}
    />
  ),
};
