import { VideoBody } from "../components/Entry/VideoBody";

export const video_forms_tractatus: { [id: string]: any } = {
  id: "video_tractatus",
  title: <></>,
  date: "",

  Body: (
    <VideoBody url="https://api-dump-v2.s3.eu-west-2.amazonaws.com/movie/660748c9498542e7cf29e646.mp4" />
  ),
};
