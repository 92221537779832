import { Chapter_One } from "./Chapter_One";
import { Chapter_Two } from "./Chapter_Two";
import { Chapter_Zero } from "./Chapter_Zero";
import { Cover } from "./Cover";

import { useParams } from "react-router-dom";
export const Journal = () => {
  const { chapterId } = useParams();
  // return chapter === "chapter_one" ? <Chapter_One /> : <Chapter_Two />;
  switch (chapterId) {
    case "chapter_zero":
      return <Chapter_Zero />;
    case "chapter_one":
      return <Chapter_One />;
    case "chapter_two":
      return <Chapter_Two />;
    default:
      return <Cover />;
  }
};
