
import { EntryBody } from "../components/Entry/EntryBody";

export const notes_aspects_chomsky: { [id: string]: any } = {

    id: "notes_aspects_chomsky",
    title: <>Notes on Aspects of the Theory of Syntax; Chomsky</>,
    date: "1965",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
Preface

</div>,

<div className="font-mono">
the central role of grammatical transformations in any empirically adequate generative grammar seems to me to be established quite firmly

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
I. Methodological Preliminaries

</div>,

<div className="font-mono">
§1. GENERATIVE GRAMMARS AS THEORIES OF LINGUISTIC COMPETENCE

</div>,

<div className="font-mono">
syntactic component of a generative grammar: ... the rules that specify the well-formed strings of minimal syntactically functioning units (formatives) and assign structural information of various kinds both to these strings and to strings that deviate from well-formedness in certain respects

</div>,

<div className="font-mono">
competence: the speaker-hearer's knowledge of his language

</div>,

<div className="font-mono">
performance: the actual use of language in concrete situations

</div>,

<div className="font-mono">
The problem for the linguist, as well as-for the child learning the language, is to determine from the data of performance the underlying system of rules that has been mastered by the speaker-hearer and that he puts to use in actual performance

</div>,

<div className="font-mono">
A grammar of a language purports to be a description of the ideal speaker-hearer's intrinsic competence. If the grammar is ... explicit ... a generative grammar

</div>,

<div className="font-mono">
A ... grammar must assign to each of an infinite range of sentences a structural description indicating how this sentence is understood by the ideal speaker-hearer

</div>,

<div className="font-mono">
Thus an essential property of language is that it provides the means for expressing indefinitely many thoughts and for reacting appropriately in an indefinite range of new situations

</div>,

<div className="font-mono">
It is only when supplemented by a universal grammar that the grammar of a language provides a full account of the speaker-hearer's competence

</div>,

<div className="font-mono">
Thus a generative grammar attempts to specify what the speaker actually knows, not what he may report about his knowledge

</div>,

<div className="font-mono">
insane clashes with W jeeeesus

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§2 TOWARD A THEORY OF PERFORMANCE

</div>,

<div className="font-mono">
concrete results ... theory of performance ... from studies that have been based on assumptions about underlying competence.

</div>,

<div className="font-mono">
"acceptable" to refer to utterances that are perfectly natural and immediately comprehensible without paper-and-pencil analysis, and in no way bizarre or outlandish

</div>,

<div className="font-mono">
Acceptability is a concept that belongs to the study of performance, whereas grammaticalness belongs to the study of competence

</div>,

<div className="font-mono">
generative rules of the language assign an interpretation to them ---- generative rules _interpret_ sentences?!?!?! i thought they would just present all possible sentences without any sort of "interpretation" attached, in an (almost) combinatorial sense? mh

</div>,

<div className="font-mono">
The most obvious formal property of utterances is their bracketing into constituents of various types, that is, the "tree structure" associated with them

</div>,

<div className="font-mono">
(i) nested constructions ... if A falls totally within B, with some nonnull element to its left within B and some nonnull element to its right within B

</div>,

<div className="font-mono">
(ii) self-embedded constructions ... if A is nested in B and, furthermore, A is a phrase of the same type as B

</div>,

<div className="font-mono">
(iii) multiple-branching constructions ... [[A][B] ... [M]]

</div>,

<div className="font-mono">
(iv) left-branching constructions [[[...]...]...]  

</div>,

<div className="font-mono">
(v) right-branching constructions [this is [the cat that caught [the rat that stole the cheese]]]

</div>,

<div className="font-mono">
an optimal perceptual device, even with a bounded memory, can accept unbounded left-branching and right-branching structures, though nested (hence ultimately self-embedded) structures go beyond its memory capacity

</div>,

<div className="font-mono">
It is the descriptivist limitation-in-principle to classification and organization of data, to "extracting patterns" from a corpus of observed speech, to describing "speech habits" or "habit structures," insofar as these may exist, etc., that precludes the development of a theory of actual performance

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§3 THE ORGANIZATION OF A GENERATIVE GRAMMAR

</div>,

<div className="font-mono">
§

</div>,

<div className="font-mono">
§

</div>,

<div className="font-mono">
§

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
---

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Notes on "What is Language?" article; Chomsky

</div>,

<div className="font-mono">
p647 Basic Property: each language provides an unbounded array of hierarchically structured expressions that receive interpretations at two interfaces, sensorimotor for externalization and conceptual-intentional for mental processes

</div>,

<div className="font-mono">
each language incorporates a computational procedure satisfying the Basic Property ... generative grammar

</div>,

<div className="font-mono">
I-language - "I" standing for internal, individual, and intensional

</div>,

<div className="font-mono">
E-language ... external language ... cidentified with corpus of data, or with some infinite set that is weakly generated

</div>,

<div className="font-mono">
("weak generation" refers to the concept that a specific grammar generates a certain set of sentences and assigns to each a structural description)

</div>,

<div className="font-mono">
p651 Universal Grammar ... genetic endowment that yields the unique human language capacity and its specific instantiations on I-languages

</div>,

<div className="font-mono">
"insitinctively, eagles that fly swim" ... "can eagles that fly swim"

</div>,

<div className="font-mono">
the association of the clause-initial elements "instinctively" or "can" to the verb is remote and based on structural properties, rather than proximal and based solely on linear properties, a far simpler computational operation and one that would be optimal for processing

</div>,

<div className="font-mono">
p652 minimal structural distance vs minimal linear distance

</div>,

<div className="font-mono">
linear order is simply not available to the language learner confronted with such examples, who is guided by a deep principle that restricts search to minimal structural distance

</div>,

<div className="font-mono">
Moro, et al., "Broca's Area and the Language Instinct"

</div>,

<div className="font-mono">
p653 Why does language invariably use the complex computational property of minimal structural distance in the relevant cases, while always disregarding the far simpler option of minimal linear distance?

</div>,

<div className="font-mono">
p654 fundamental language design ignores order and other external arrangements

</div>,

<div className="font-mono">
Basic Property is generation of an unbounded array of hierarchically structured expressions mapping to the conceptual-intentional interface, providing a kind of "language of thought" (LOT)

</div>,

<div className="font-mono">
p655 Languages are not tools that humans design, but biological objects

</div>,

<div className="font-mono">
p656 The principle of Minimal Computation dictates that neither X nor Y is modified by Merge, and that they appear in Z unordered. Hence Merge (X, Y) = &#123;X, Y&#125;. That does not of course mean that the brain contains sets ... but rather that whatever is going on in the brain has properties that can properly be characterized in these terms

</div>,

<div className="font-mono">
Internal & External Merge (?)

</div>,

<div className="font-mono">
p657 External and Internal Merge are the only two possible cases of binary Merge ... Displacement is what we should expect on the simplest assumptions.

</div>,

<div className="font-mono">
p660 The interesting cases are those in which there is a direct conflict between computational and communicative efficiency. In every known case, the former prevails; ease of communication is sacrificed ... structural ambiguities and "garden path sentences" ... _islands_ ... Empty Category Principle ...

</div>,

<div className="font-mono">
p661 strong minimalist thesis SMT

</div>,

      ]}
    />
  ),
};
