
import { EntryBody } from "../components/Entry/EntryBody";

export const notes_theory_of_language_harris: { [id: string]: any } = {

    id: "notes_theory_of_language_harris",
    title: <>A Theory of Language and Information; Harris</>,
    date: "O23",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
This is a very generous [many paragraphs included] extractive summary of the book.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
1. Overview

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
1.0 Introduction

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
when the constraint-seeking methods used to obtain the structure of a whole language are applied to the notes and articles of a single sub-science, they yield not the grammar of the language, but a somewhat different kind of grammar which has some of the properties of mathematical notation

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
1.1 A theory in terms of constraints

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
all occurrences of language are word-sequences which satisfy certain combinatory constraints;

</div>,

<div className="font-mono">
The first constraint (3.1) is what gives a word-sequence the capacity to express fixed semantic relations among its words: it consists in a partial ordering on words (or morphemes, 7.4), whereby a word (or morpheme) in a sentence serves as ‘operator’ on other words, called its ‘argument’, in that sentence. (In Sheep eat grass, we take eat as operator on the argument pair sheep, grass.)

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The second constraint (3.2) reflects the specific meaning in a sentence, by recognizing that for each argument word certain words are more likely than others to appear as operator on it. (Eat is more likely than drink and than breathe as operator on the pair sheep, grass.)

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The third constraint (3.3) on word-sequence makes sentences more compact: it results from reducing—e.g. to an affix or to zero—certain words which have highest likelihood, and thus contribute little or no information, in a particular operator-argument sequence. (Pronouning and zeroing of repeated words under and reduces Sheep eat grass and sheep eat weeds to Sheep eat grass and they eat weeds, and to Sheep eat grass and weeds.)

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
the partially ordered operator-argument words are put into one or more linear forms (3.4), needed since speech is an event in the single dimension of time. (Thus ‘eat operating on sheep, grass' becomes Sheep eat grass.)

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Given a sentence, one can recognize from its structural traces whether or not it has undergone reduction, and if so which reduction, up to this ambiguity.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
For example, sleep is an operator on one ‘noun’-type argument, eat an operator on two: this produces not only Sheep sleep but also Grass sleeps, and not only Sheep eat grass but also Sheep eat sheep. Grass eats sheep, etc.; but all of these are syntactically possible sentences.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The grammar of a language is then the specification of these word classes in their partial ordering, and a specification of the reductions and linearizations.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
1.1.0 Example: The set-vs.-member connectives

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
(3) Everyone (else) came, only John didn’t come. In (3) the whole first component sentence is zeroable (reducible to zero) on grounds of low information in respect to the set-vs.-member selection of the bi-sentential operator (conjunction) only.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
(4) Everyone came, except that John didn't come. What is zeroable in the contrasted sentences around except is just the not (optionally) plus verb plus that (here, that. . . didn't come) of the second component sentence, more rarely the verb alone (here, came). Then (4) reduces to (5) Everyone came except John

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
(6) Everyone (else) came, but John didn't come. Under but, in structurally contrasted sentences such as (6) one can zero the second verb, and if so also the high-expectability not. Then (6) reduces to (7a) Everyone came, but not John or Everyone, but not John, came (separated by commas) and to (7b) Everyone came but John or Everyone but John came (without commas), all preserving the meaning of (6).

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
1.1.2. Example: The passive construction

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
1.2. Mathematical properties

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The grammatical classification of words is defined in general by relations among the words, rather than by particular properties (phonetic, morphological, semantic, or logical categories) that each word has by itself.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
What we find in each language is that large subsets of words act in uniform ways in respect to other large subsets.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
the lack of an external metalanguage in which to conduct the analysis leads us to describe the data in terms of departures from equiprobability of combination

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
transitional probabilities do not yield useful characterizations of words and sentences [this is an LM btw]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
We say that A is operator on ordered classes, say B, C (or: A depends immediately on B, C), to mean that A is in the set of all words which appear in an unreduced sentence only if some word of B and some word of C, in that order, also appear in that sentence; here Box C may be stated to be the same class as the other, or as A, or different classes, or the null set (3.1. l)rln turn, membership in B or C is not given by a list of words or by some property other than this dependence.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The single relation among words is, then, dependence on dependence; and a sentence is any sequence of words—or other objects—in which this relation is satisfied.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Thus the co-occurrences (sequences) of words in unreduced sentences as created by the first constraint is a mathematical object: a system of entities defined only by their relations and closed in respect to them, with operations defined on these entities.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
these [mathematical] structures are not merely describable, but are relevant to the informational function of language (11.5). The dependence-on-dependence relation underlies virtually everything in language structure; and beyond that, the mathematical structures noted here suffice to define almost all the semantically (and grammatically) important classifications and relations

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
1.3. Science sublanguages

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
If the grammar of a language were stated in terms of objects unique to language, such as a particular list of words constituting the class ‘adjectives’, it would not be possible to apply the entities (classes) of a whole grammar when investigating any special classes such as might arise in special conditions of language, unless the special classes were built of the same entities. In contrast, if the grammar consists in stating a relation among words, we may obtain, as carriers of the same relation, one set of classes for the whole language and other, appropriately different, classes for distinguished subsets of the language.

</div>,

<div className="font-mono">
[the structure given in language will be rigid if stated in terms of items of langauge itself, this assumes language as monolithic. in defining the classes of grammar as relationships between objects of language, it is easier to model subsets of language, because the same building blocks og he classes of "all language" (relations), will generate different classes (but still wrt the same relation) when considering subsets]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
One distinguished subset of the utterances of a language is the set of sentence-sequences, each sequence being a set of two or more sentences which have been said in succession, as a single discourse. ... The repetition constraint expresses —or creates— a connectedness among the sentences.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
analyzing various arbitrary collections of utterances in a language leads to much the same grammar, and the grammars of different collections increasingly approach each other the bigger the collections are.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
[class of a word are other words with the same 0-likelihood class]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Thus the grammar of the sub-science is a structural representation of the knowledge and opinions in the field.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
1.4. Information

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The relation of language structure to information thus makes possible the complex processing of information.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
1.5. The nature of language

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
syntactic relations, which carry such fundamental grammatical meanings as being predicate, or being subject, (a) do not have to be invented or discovered as underivable sui generis properties of grammar, but (b) can be derived from constraints on word co-occurrence. In (a) such relations as being subject of a verb are necessarily unique to language and cannot be compared with anything else. In contrast, for (b), the dependence constraint is a kind of dependence that we can seek in other systems and in other types of activity.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
once that constraint exists, its learning requires no unique preconditioning: one need only hear a great number of sentences constructed by this dependence and thereupon construct further juxtapositions of words that satisfy the same dependence.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Throughout it is seen that language is not a single object or a one-time creation, but a system evolving by accretion, one which has grown out of self-organizing factors and out of the language-survival value of effectiveness in transmitting information

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
2. Method

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
2.0 Introduction

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
2.1. The issue to be resolved: Departures from equiprobability

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Within present knowledge, the form of utterances, i.e. the sound-sequences and written marks, is the one aspect of language that is observable precisely, fully, and independently of other features of speech and language.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
when we analyze form, to use such knowledge only where we happen to have it interferes with reaching a systematic analysis of the form and the information it carries (e.g. if we explain the auxiliary verbs by the need to state modalities of actions, in contrast to combinatorial and likelihood factors as in G298).

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
2.2. The relevant method: Least redundancy in description

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
In language such departures from equiprobability are not only universal but necessary, because the whole set of human languages has no external metalanguage.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
when only a small percentage of all possible sound-sequences actually occurs in utterances, one can identify the boundaries of words, and their relative likelihoods, from their sentential environment; this, even if one was not told (in words) that there exist such things as words

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
No one has succeeded in characterizing the departures from equiprobability by fixed transitional probabilities between successive words of an utterance; the reason turns out to be that the overall characterization of word-configurations is a partial ordering of words

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The analysis of a language form should make maximal use of analyses used for other forms, avoiding as far as possible recourse to new (ad hoc) classes, rules, domains. To explain a form, then, is to find its maximal similarity to the other forms, with the whole grammar thus becoming a best fit for the data.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
In all this, it should be noted that no assumption is made that there exists a structure in language, and no appeal is made to any particular principles of structuralism. Such structure as is found comes out in the process of making a least description.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
2.3 Simplicity

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The simplicity of the system is greater if any property or analysis that is stated at one point in the description of a construction is preserved under later developments in it.

</div>,

<div className="font-mono">
[examples: "A whale is not a fish"]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
A more complicated case of this parsimony of analysis is the avoidance, in analyzing a form, of going beyond what the language itself has distinguished.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
This means that fixed antecedents are provided for by creating special easily recognized relative locations; and where this has not been provided, it may be judged that the uncertainty as to antecedent is not due to there being a disjunction of determined antecedents, but rather to there not being determined antecedents at all in the given grammatical case. He then means ‘someone mentioned’ rather than ‘this or that one or the other’.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
By the same token, although language has in principle the addressing capacity of stating the relative linear location of every phoneme and word in a sentence, this capacity is not part of a grammar, because universally it is not used. Indeed, languages use words for referring to easily recognized locations, e.g. latter, without any system of overall addressing. To reject a description that overreaches language means that a theory and notation should at no point produce forms of distinctions beyond what language can contain, just as it must not produce less than what language contains.

</div>,

<div className="font-mono">
[is this relevant on the cases of llms choking hard on positional tasks? or is that just a matter of (i) architecture (ii) use being "hyperdimensional" wrt language?]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
2.4. Can meaning be utilized?

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
one might think that the forms—the words and constructions—of language, and the meanings which they carry, can be described each independently of the other. However, there is a feature of language which requires that the two be interconnected. This is again the absence of an external metalanguage. It has been seen in 2.2 that this lack makes it impossible to identify all of the elements in the flow of speech without noting the constraints on their combination.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The meaning of such words as time, consider, the, of, and the secondary- sentence meaning of the relative clause structure, cannot be adequately gathered from the real-world situations in which they are used.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
We cannot say a priori what objects or actions or relations are included in one meaning as against another: we have to see what words combine with chair as against bench ...

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
in no way is meaning being excluded here from the study of language, but that it is not being used as a criterion in determining the analysis of forms. Indeed, general considerations of meaning will be obtained from considerations of form

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
2.5. Properties of the data

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
2.5.0. Introduction

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Language has several universal properties (4.4, 6.1), in addition to the constraints on word combinations on sentences.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
2.5.1. Unbounded language, finite speakers

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Many things in language—what sounds are distinguished phonetically, what phoneme-sequences are pre-set as words or morphemes, what kinds of word combination are used—depend upon a finite body of public experiences on the part of speakers and hearers.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
2.5.2 Discrete elements

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The success of writing systems, all of which use discrete marks, as carriers of language suggests that language can be represented with discrete elements.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
2.5.3. Linearity

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The linear representation, however, does not mean that the elements of language are linearly ordered in respect to structure: they may be partially ordered with a linear projection, as indeed is proposed here.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
2.5.4. Contiguity

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Talk or writing is not carried out with respect to some measured space. The only distance between any two words of a sentence is the sequence of other words between them. There is nothing in language corresponding to rhyme, meter, or beat, which defines a space for poetry and music, or to the bars in music notation which make it possible, for example, to distinguish rests of different time-lengths.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Any well-formedness for sentence structures must therefore require in the base a contiguous sequence of (discrete) objects

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The only property that makes this sequence a construction of the grammar is that the objects are not arbitrary words but words of particular classes.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
if language can have a constructive grammar, then there must be available some characterization of its sentences which is based on purely contiguous relations. The contiguity of the successive words is related to this situation, but does not in itself satisfy this requirement since the sentence has been found here to be characterized by a partial ordering of its words of which the linear order is merely a projection (3.1, 4).

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
2.5.5. Variety within a class

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The forming of classes is thus not a ‘similarity’-based grouping of the data but a complex way of finding the most regular way of attributing properties to entities, that is, of defining properties and their domains (2.3).

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
2.5.6. Synchronic single-language coexistence of forms

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
2.5.7. Diachronic change

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
2.6. From method to theory

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
3. A Theory of Sentences

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
3.0. Constraints on equiprobability

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
For the given language, then, we make a distinction between the combinations that have zero probability and those that have any positive probability. It will be seen below that the zero probability characterizes what is outside of syntax (3.1), while the differences in positive probability characterize the differences in word meaning within syntax (3.2).

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
In contrast with list-making, it is possible to characterize the word combinations by stating a set of constraints each of which precludes particular classes of combination from occurring in utterances of a given language

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
3.1. Zero vs. non-zero likelihood

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
3.1.0. Introduction

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The crucial property of language is that the presence of words in a sentence depends on how other words in the sentence depend on yet other words in it.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
3.1.1. Dependence

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
A depends on (or requires) b, . . ., e if and only if for every utterance in the language, if A is in the utterance then there occurs in the utterance a sequence of simple words B ... E (members of h, . . ., e), any of which may be in a reduced (including zeroed or permuted) [in the base, these are explicit, no reductions]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Dependence is a relation between a word and an ordered set of word classes

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
when we compare, in any large corpus of data, the lists for sleep with the lists for certain other words, such as fall or sit, we find similarities, though with different frequencies, in what words do not co-occur.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
[Examples deducing O (sleep, fall), N, O_nn (wear), O_...]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
We thus find support for a class of words postulated to have zero likelihood of occurring with the sleep and wear set in the shortest sentences of the language—shortest either observably, or after correcting for the reductions presented in 3.3. This class will henceforth be marked O (operator), while the residue class, whose members have some positive likelihood no matter how small under the sleep and wear classes, will be marked N because most of them are simple (unimorphemic) nouns. The sleep set, a subset of O, will be marked On, and the wear subset Onn, to indicate that they occur with one or two members of N respectively.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The class of is probable is then marked O0, since its co-occurrent (argument) is an O (which then brings its own co-occurrents into the sentence).

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
3.1.2. Dependence on dependence

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
We now consider the word classes on which a word depends, i.e. what classes appear in argument position. The crucial finding here is that each of these argument classes is characterizable not by a list of members (which would be difficult, since words can be added to a class or lost from it), but by what that argument class in turn depends on.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
In John asserts Mary came, we say that asserts (Ono) depends on the pair John (A), came (O,,); and came depends on Mary (A).

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
the ability of an A word to occur alone as an utterance differs from that of all the O words

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
3.1.3. Partial order

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The dependence relation imposes a partial ordering of word classes in respect to their definitions.

</div>,

<div className="font-mono">
This yields a partition of the words of the language into the three levels:

</div>,

<div className="font-mono">
Zero-level N - First-level O_n...n - Second-level O...o...

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
A&gt;B, when B is in the dependence chain of A (that is, B is the immediate argument, or argument of the argument to any depth, of A). When A&gt;B and there is no C such that A&gt;C&gt;B, we say that A is the operator on B (A covers B), and B is the (immediate) argument of A.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The similarity of operators to functors in categorial grammar within logic is evident.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
If one wishes to think of the making of a sentence as a process, one can view the semilattice, that is the dependence order of the words in a sentence, as the partial order of entry of the words into the making of the sentence. No word can enter into a sentence, which is being constructed out of words and previously made (component) sentences, unless its prerequisite is available.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
3.1.4. Predication

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The major semantic yield of the dependence relation is that it carries inherently the meaning of ‘predication’. If there is a word A which is not said except in company of one or another word B of a set 6, then when A is said with some B it has perforce the effect of saying something about that B.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Sentences are, first of all, word combinations in which something is said (predicated) about another thing. Predication inheres not in co-occurrence per se but in the co-occurrences which are required.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
[comment on the impossibility of predicating as an a priori]

</div>,

<div className="font-mono">
sentencehood can only have arisen not as a vehicle for an otherwise conceptualizable relation of predicating, but as a concomitant of some other, more behavioral, relation which then yielded predication as an interpretation.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
3.2. Inequalities of likelihood

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
3.2.0. Introduction

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
the dependence of a word on a class states only that every member of the class has a positive likelihood, no matter how small, of occurring with that word

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
3.2.1. Likelihood constraints

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
If we consider the various words which have the same argument class, e.g. see, eat, etc. in Onn, we find that they have different inequalities of likelihood in respect to the individual argument words in the class

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The dependence of an operator on an argument is thus a construct. It is based on a particular criterion, of what arguments they in turn depend on,

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
In contrast to this construct, the likelihoods of an operator in respect to its argument words are the direct data of word-combination frequency within the dependence structures, in (a sample of) the sentences of a language

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
transformations can be phenomenologically defined as subsets of sentences throughout which the likelihood gradings are preserved

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
the reductions will be seen to be based on extremely high likelihood (or, equivalently, low information) in the operator-argument relation

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
the likelihood of an operator can be affected not only by its immediate argument but also by some distinguished word further down in the dependence chain—e.g. an argument of its argument to some depth. ... In this kind of situation, a word with modifier (the latter being reduced from a conjoined sentence) can carry a different selection, which may approximate the selection of a different word [house of snow ~ igloo example]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The consistency of selection breaks down in negation and question ... negation and question are not operators (on eat in this case), but are part of the metalinguistic performatives in which each sentence is encased

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Perhaps every word has a unique selection of normal co-occurrences in the argument positions under it or in the operator position over it, and almost every word has unique inequalities or grading, from most likely to least likely co-occurrents in each of these positions. Nevertheless, words that are close (or opposite) in meaning have largely overlapping selections

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Without attempting the impossible task of describing the network of selections among the words of a language [we now can do thiss babyyy]

</div>,

<div className="font-mono">
we can recognize in a rough way that each word has (or shares in) what we may call a clustered (‘coherent’) selection. Operators share in a clustered selection if their argument selection has a large overlap and if many words in the selection of one which are not in the selections of the others have, in turn, large overlaps in their operator selections

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The greater variety in O_n...n meanings supports our formulating the likelihood and dependence relations as being in the first place a restriction on the operator in respect to the argument words rather than the converse (3.1);

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
3.2.2. Likelihood types

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
One case is that of unrestricted selection. [say, deny, ask, request, exist]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
a small closed set of ‘indefinites’ whose union has all operators in its selection [something, somebody]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
[examples of types of words defined distributionally]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
3.2.3. Reconstructed likelihoods

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
[examples of expect zeroing to come/to be here and met (reciprocal verb) zeroing each other]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
A major type of high likelihood in reconstructed sentences is to be found in the reconstruction of word-repetition

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
When we consider bisentential operators (O_oo), we find what seem to be semantic differences, between arbitrary-sounding or nonsensical sentences such as I sharpened my pencils because the snow was soft on Tuesday and ones that sound ordinary, like People are corrupted by (their) having access to power. It is notable that in many cases the latter, more ‘acceptable’ kind of sentence has a word repeated in its two component sentences (e.g. people and they) while the former kind does not. The relevance of this observation is supported when it turns out that the former lose their eyebrow-raising effect when a third component sentence is adjoined which creates a word-repetition bridge between the original component sentences: e.g. I sharpened my pencils, having promised him a pencil sketch of a snowy tree, because the snow was soft on Tuesday.

</div>,

<div className="font-mono">
[Language is not about transmitting a lot of information, but rather about transmitting the least amount possible?!]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
When one of the arguments of the lower O is the same as one of the earlier argument words under the ‘higher’ operator (the O_no or O_nno), the argument of the lower operator is zeroable.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
In the O_oo, O_no, and O_nno cases here we have seen that various omissions in the lower sentence have to be reconstructed (by such evidence as -self) as being repetitions of an earlier argument of the higher operator. When we add these frequent omissions to the occurrence of observable repetitions, it is seen that the (original) repeated material has a high likelihood, higher in the positions noted than any one other word in those positions. The positions of this high-likelihood repetition differ under different higher operators, in a way that conforms to (or affects) the meaning of the higher operators (and that does not in general vary with the meanings of the lower operators).

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
3.2.4. Likelihood restricted to zero

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
the occurrence of very low-likelihood argument words does not make an impossible sentence, only a nonsensical one (e.g. The vacuum conversed with the ether), or a'non-reducible one (e.g. He is coming; that he is coming is false not reducible to He is falsely coming, 3.2.2 end).

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Alternatively, we may say that can and the others are O_no operators of the prefer, want, type above, in which the subject of the lower operator is omitted when it repeats the subject of the higher operator. Then the only serious difference is that under can, etc., for the subject of the lower operator to be different from the subject of the higher operator has not just a lower probability but zero probability

</div>,

<div className="font-mono">
... a few words within an established operator class may lower to zero the probability for part of their argument class

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
3.2.5. Word subsets

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The various special selections noted above yield certain subsets of words, mostly such that the words in a subset have selections that are similar, complementary

</div>,

<div className="font-mono">
[examples of duratives & similars]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
For example, an operator which is not in the selection of throughout may enter that selection if it has a plural subject (first argument): there is no The guest arrived throughout the afternoon, but there is The guests arrived throughout the afternoon.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
almost all cases the selectional subsets are fuzzy [difficult to perceive; indistinct or vague]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The difficulty in establishing word subsets shows that in general languages have few if any definite subsets in respect to likelihoods (3.2). In science sublanguages (10.3-5) it will be seen that selectional subsets are possible and of great importance, when subject matter is restricted.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
3.2.6. Frequency; low information

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
the upper and lower extremes of likelihood determine the domains of reduction (3.3), which is a universal process in language.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
We thus have not a random distribution of N as argument words of sleep, but inequalities of likelihood, and to some extent a grading [the selection], of N under sleep

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
To a first approximation, likelihood is relative frequency.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Nor is the likelihood measured relative to the whole sentence; if it were, we would have the impossible task of considering each sentence as a different environment in which to count the word likelihoods at each position.

</div>,

<div className="font-mono">
[we can do this now!!! locoooooo]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
likelihood as used here is the frequency of each word in a given argument or operator class (A, On, etc.) relative to a given operator word over it or argument word under it (or co- argument under be, as in the classifier is an insect, below)

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
the frequency has to be counted in respect to the reconstructed sentences

</div>,

<div className="font-mono">
[this is very very problematic, as LMs are trained on reductions, so will not give the likelihoods we would want]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
certain words of low frequency relative to a given operator or argument may have a classificand or synonymy or definitional relation to words that are more frequent relative to that operator or argument

</div>,

<div className="font-mono">
The rock weighed 3.073 kilograms ... The rock weighed 2 kilograms

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The term likelihood is used here, then, for the frequency of occurrence of a word as specified above: relative to a particular operator or argument word of it, in respect to the reconstructed base form of any sentence which is in reduced form, and with allowance for classifier relations and the like.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
a word that has broad selection and high total likelihood carries less information than other words, and a specified word (or locally synonymous set words) that has highest likelihood in a given environment can be considered to contribute no information in that environment

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
3.3. Reduction

</div>,

      ]}
    />
  ),
};
