
import { EntryBody } from "../components/Entry/EntryBody";

export const seeing_and_reading: { [id: string]: any } = {

    id: "seeing_and_reading",
    title: <>Seeing & Reading</>,
    date: "2 Febs 24",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
So this kid turns 4 yo & now is talking & talking... The parents maybe taught them a few words, by ostension. Then they babbled for a bit, maybe they overheard some conversations, regardless of the mechanisms, from being in contact relatively few words, and even less words in explicit learning, the kid talks.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The classic .poverty of stimulus.: presented as the complete obliteration of discovering anything about .language. in LMs. It is striking and paradoxical: "For example, Chinchilla sees 1.4 trillion words during training---well over 10000 words for every one word a 13 year old child has heard in their entire life." [taken from https://babylm.github.io, Chinchilla are a family of LMs]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Reading is here coming into contact with some text, and afterwards generating other language that is somehow related to that text (so as to prove that one has read it), without any psychologistic assumptions or weight the term may carry. The kid reads much less than the LM, well over four or five orders of magnitude less. Yet a kid is much better at language than an LM.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
But what is this surgical separation of language & experience?! A dualistic divide: we learn language by reading, and only through reading. But if language is to carry information about a world, any being in the world is an observation for language. All experience has to count towards gaining a use of language, not just the reading (listening, speaking) of words themselves, again: the co-occurence of a shark and a chair tells us about the co-occurence of "shark" and "chair".

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Let"s approximate the seeing of a kid by movie that plays 8 hours a day at 24 frames per second. In 13 years of life, the kid has seen 8x3600x24x365x13 = 3279744000 frames. We can compare this to a trillion by dividing: 1000000000000/3279744000 = 304.9. So, if we relax the saying "an image is worth more than 1000 words" to "an image is worth 300 words", both the kid & the model are trained on roughly the same amount of data.

</div>,

      ]}
    />
  ),
};
