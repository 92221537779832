
import { EntryBody } from "../components/Entry/EntryBody";

export const notes_quine: { [id: string]: any } = {

    id: "notes_quine",
    title: <>Notes on Word & Object; Quine</>,
    date: "Octo23",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
CHAPTER ONE. Language and Truth

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§1. BEGGINING WIT ORDINARY THINGS

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Entification begins at an arm"s length; the points of condensation in the primordial conceptual scheme are things glimpsed, not glimpses.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Linguistically, and hence conceptually, the things in sharpest focus are the things that are public enough to be talked publicly, common and conspicuous enough to be talked often, and near enough to sense to be quickly identified and learned by name; it is to these that words apply first and foremost.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§2. THE OBJECTIVE PULL; OR, E PLURIBUS UNUM

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
the correct use of language generally, is inculcated in the individual by training on the part of society; ans society achieves this despite not sharing the individual"s pain. Society"s method is in principle that of rewarding the utterance of 'Ouch' when the speaker shows further evidence of sudden discomfort ...

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Society, acting solely on overt manifestations, has been able to train the individual to say the socially proper thing in response even to socially indetectable situations.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
A partial symmetry obtains between the subject"s cue for utterance and the critic"s cue for approval in the case of 'Red', which, happily for the critic, was lacking in the case of 'Ouch'.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p7 Words being social tools, objectivity counts toward their survival.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
if a term is to be learned by induction from observed instances ... the instances have to resemble one another in two ways: they have to be enough alike from the learner"s point of view ... they have to be enough alike from a simultaneous distinct points of view [for teacher, square example]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
[Objective pull ~ low information, high likelihood]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Different persons growing up in the same language are like different bushes trimmed & trained to take the shape of identical elephants. The anatomical details of twigs and branches will fulfill the elephantine form differently from bush to bush, but the overall outward results are alike.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§3. THE INTERANIMATION OF SENTENCES

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
"Some Indian theories of meaning" Brough

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
[to sully: damage the purity or integrity of]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Association of sentences is wanted not just with non-verbal stiulation, but with other sentences, if we are to exploit finished conceptualizations and not just repeat them.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p11 "There was copper in it" ... the sentence is elicited by a non-verbal stimulus ... the verba network of an articulate theory has intervened to link the stimulus to the response

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
"Fundamentals of Concept Formation" Hempel

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
... a transitivity of conditioning

</div>,

<div className="font-mono">
What comes of the association of sentences with sentences is a vast verbal structure which, primarly as whole, is multifariously linked to non-verbal stimulation.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
[beautiful picture of network of verbal associations, with empirical force at the border and logical force inside]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§4. WAYS OF LEARNING WORDS

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
learning a word in isolation ... learning it contextually, or by abstraction, as a fragment of sentences learned as wholes. ... [learned by] description of the intended objects [molecule]

</div>,

<div className="font-mono">
What makes insensible things intelligibly descrbable is analogy, notably the special form of analogy known as extrapolation.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p15 One must see the molecular doctrine at work in physical theory to get a proper notion of molecules, and this is not a matter of analogy, nor of description at all. It is a matter of learning the word contextually as a fragment of sentences which one learns to bring forth as wholes under appropiate circumstances.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
[discussion on neutrinos]

</div>,

<div className="font-mono">
p16 The division between the words that are to be viewed as referring to objects of some sort, and the words that are not, is not to be drawn on grammatical lines. ... the differences in ways of learning words cuts across the grammatical differences and also across the referential ones.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§5. EVIDENCE

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
What we are doing when we ammas circumstancial evidence [if something spotted from a train was a paper or a rock] is to let ourselves be actuated as sensitively as possible by chain stimulations as they reverberate through our theory, from present sensory stiulations, via the interanimation of sentences.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Prediction is in effect the conjectural anticipation of further sensory evidence for a foregone conclusion ... [wrong preidiction] ... extinguish the sentence-to-sentence conditionings that led to the prediction.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p19 Observation serves to test hypothesis after adoption; simplicity prompts their adoption for testing.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The neurological mechanism of the drive for simplicity is undoubtedly fundamental though unknown, and its survival value is overwhelming. [is there an apriori that can justify this simplicity? and in language models?!]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§6. POSITS AND TRUTH

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p22 Actually the truths that can be said even in common-sense terms about ordinary things are themselves, in turn, far in excess of any available data. The incompleteness of determination of molecular behaviour by the behaviour of ordinary things is hence only incidental to this mode basic indeterminacy: _both_ sorts of events are less than determined by our surface irritations.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Everything to which we concede existence is a posit from the standpoint of a description of the theory-building process, and simultaneolsy real from the standpoint of the theory that is being built.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p23 ... we have no reason to suppose that man"s surface irritations even unto eternity admit of any one systematization that is scientifically better or simpler than all possible others.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
CHAPTER TWO. Translation and Meaning

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§7. FIRST STEPS OF RADICAL TRANSLATION

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
... how surface irritations generate, through language, one"s knowledge of the world

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p27 the infinite totality of sentences of any given speaker"s language can be so permuted, or mapped onto itself, that (a) the totality of the speaker"s dispositions to verbal behaviour remains invariant, and yet (b) the mapping is no mere correlation of sentences with _equivalent_ sentences. ... The firmer the direct links of a sentence with a non-verbal stimulation, of course, the less that sentence can diverge from its correlate under any such mapping.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
... manuals for translating one language into another can be set up in divergent ways, all compatible with the totality of speech dispositions, yet incompatible with one another.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p28 Reckon a man"s current language by his current dispositions to respond verbally to current stimulation, and you automatically refer all past stimulation to the learning phase. ... bound we set to the length of stimulations counted as current ... modulus of stimulation

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The recovery of a man"s current language from his currently observed responses is the task of the linguist who, unaided by an interpreter, is out to penetrate and translate a langauge hithertho unknown.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
... radical translation, i.e., translation of the language of hitherto untouched people

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p29 So we have the linguist asking "Gavagai?" in each of various stimulatory situations, and noting each time whether the native assents, dissents, or neither.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p30 ... we speak in a more causal vein of stimulations that will _prompt_ the native to assent or dissent to the queried sentence

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§8. STIMULATION AND STIMULUS MEANING

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p32 the affirmative stimulus meaning of a sentence ..., for a given speaker, as the class of all stimulations (hence evolving ocular irradiation patterns between properly timed blindfoldings) that would prompt his assent.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p34 The stimulus meaning of a sentence for a subject sums up his disposition to assent or to dissent from the sentence in response to present stimulation. The stimulation is what activates the disposition, as opposed to what instills it

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§9. OCCASION SENTENCES. INTRUSIVE INFORMATION

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Occasion sentences, as against standing sentences, are sentences ... which command assent or dissent only if queried after an appropiate prompring stimulation.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p37 sameness of stimulus meaning has its shortcomings as a synonymy relation ... the informant "s assent to or dissent from 'Gavagai?' can depend excessively on prior collateral information [rabbit-fly, earlier observation, bystander saying 'Gavagai']

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p38 transitivity shortcuts changes of meaning or condensations of proof ... unreal question

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p39 stimulus meaning ... falls short in various ways of one"s intuitive demands on 'meaning' as undefined, & that sameness of stimulus meaning is too strict a relation to expect between a native occasion sentence and its translation ... still as the objective reality that the linguist has to probe when he undertakes the radical translation

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p42 As one says in the uncritical jargon of meaning, the trouble with 'Bachelor' is that its meaning transcends the looks of the prompting faces and concerns matters that can be known only through other channels.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Occasion sentences whose stimulus meanings vary none under the influence of collateral information may naturally be called observation sentences [~'Red']

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p45 What makes an occasion sentence low on observtionality is, by sefinition, wide intersubjective variability of stimulus meaning.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§11. INTRASUBJECTIVE SYNONYMY OF OCCASION SENTENCES

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Stimulus synonymy, or sameness of stimulus meaning, is as good a standard for synonymy for non-observational occasion sentences as for observation sentences as long as we stick to one speaker.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p59 By a construction ... any fixed way of building a composite expression from arbitrary components of the appropiate sort, one or more at a time.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§12. SYNONYMY OF TERMS

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Stimulus synonymy of the occasion sentences 'Gavagai' and 'Rabbit' does not even guarantee that 'gavagai' and 'rabbit' are coextensive terms. [terms true of the same things.]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p52 The distinction between concrete and abstract object, as well as that between general and singular term, is independent of stimulus meaning.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p53 We cannot even say what native locutions to count as analogues of terms as we know them, much less equate them with ours term for term

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
the use of a word as an occasion sentence, however determinate, does not fix the extension of the word as a term.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p55 a sentence [is] stimulus-analytic for a subject if he would assent to it, or nothing, after every stimulation (within the modulus)

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p56 We learn 'bachelor' by learning appropiate associations of words with words, and 'Indian Nickel' by learning directy to associate the term with sample objects. It is the difference, so central to Russell"s philosophy, between description & acquaintance.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§13. TRANSLATIONG LOGICAL CONNECTIVES

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
we accounted for radical translation occasion sentences, by approximate identification of stimulus meaning.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Any construction for compounding sentences from sentences is counted in logic as expressing a truth function if ... the compound has a unique truth value ... for each assignment of truth values to the components.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p59 The maxim of transation underlying all this is that assertion startingly false ... are likely to turn on hidden differences of language. ... one"s interlocutor"s silliness, beyond a certain point, is less likely than bad translation ... or ... linguistic divergence.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p60 categoricals A, E, I, O. A: all are (All rabbits are timid.) one are, some are, some are not

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
... the suggested semantic criterion [the compound commands assent iff affirmative stimulus meaning of first component is a subclass of the second] is at odds with "All Fs are Gs" in that it goes beyond extension. ... The difficulty is fundamental. The categoricals depend for their truth on the objects, however external and however inferential, of which the components are true; and what those objects are is not uniquely determined by stimulus meaning.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Goodman & Leonard, Structure of Appearance

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§14. SYNONYMOUS AND ANALYTIC SENTENCES

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p62 [broad, narrow synonymy] ... the two sentences command assent concomitantly adn dissent concomitantly, and this concomitance is due strictly to word usage rather than how things happen in the world.nOne usually hears the matter described in terms rather of truth values than of assent and dissent; but I wrap it over to the latter terms in order to maximize chances of making sense of the relation on basis of verbal behaviour.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
When the sentences are occasion sentences, teh envisaged notion of synonymy is pretty well realized in intrasubjective stimulus synonymy, especially as socialized.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p65 sentences are synonymous iff their biconditional (formed by joining them with iff) is analytic, and a sentence is analytic iff synonymous wth self-conditionals (If p then p)

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p66 Philosophical tradition hints of three nested categories of firm truths: the analytic, the a priori and the necessary

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p67 ... it would be a mistake to look to them for a sweeping epistemological dichotomy between analytic truths as by-products of langauge and synthetic truths as reports of the world.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§15. ANALYTICAL HYPOTHESES

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p69 ... the mre absurd or exotic the beliefs imputed to a people, the more suspicious we are entitled to be of the translations; the myth of the prelogical people marks only the extreme. For translation theory, banal messages are the breath of life. [this is related to the context fixing the information of an utterance, if the information is low (banal message), the utterance is, in a way, contained in the context, therefore there are less possible utterances (and less possible translations, since we would expect information is a constant across translation)]

</div>,

<div className="font-mono">
p70 The method of analytical hypothesis is a way of catapulting oneself into the jungle language by the momentum of the home langauge. ... stimulus meanings never suffice to determine even what words are terms, if any, much less what terms are coextensive.

</div>,

<div className="font-mono">
p71 .... the linguist"s finished jungle-to-english manual has as ites net yield an infinite _semantic correlation_ of sentences: the implicit correlation of an English sentence ... for every one of the infinetely many possible jungle sentences. Most of the semantic correlation is supported only by analytical hypothesis ... unverifiable translations ... Gavagai as rabbit vs parts of rabbit example of indeterminacy

</div>,

<div className="font-mono">
p72 Sentences translatable outright, translatable by independent evidence of stiulatory occasions, are sparse and must woefully under-determine the analytical hypotheses on which the translation of all further sentences depends.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§16. ON THE FAILURE TO PERCEIVE THE INDETERMINACY

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p73 ... rival systems of analytical hypotheses can conform to all speech dispositions within each of the languages concerned and yer dictate, in countless cases, utterly disparate translations; not mere mutual paraphrases, but translations each of which would be excluded by the otehr system of translation.

</div>,

<div className="font-mono">
p75 The indefinability of synonymy by reference to the methodology of analytical hypothesis is formally the same as the indefinability of truth by reference to scientific method.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
CHAPTER THREE. The Ontogenesis of Reference

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§17. WORDS AND QUALITIES

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
p80 The babbling is a case of what Skinner calls _ooperant behaviour_, "emitted rather than elicited" ... can be selectively reinforced ... by quick reward.

</div>,

<div className="font-mono">
p81 What had been stimulation accidentally attending the act, becomes transmutated, by the reward, into a stimulus _for_ the act.

</div>,

<div className="font-mono">
p82 Mimicry, which we already saw foreshadowed within the mechanism of reinforced operant behaviour, deveops to the point where ant new utterance from someone else becomes a direct stimulus for a duplicate.

</div>,

<div className="font-mono">
p83 If the child is to be amenable to such training, however, what he must have is a prior tedency to weight qualitative differeces unequally ... sensen more resemblance between some stimulations tha between others.

</div>,

<div className="font-mono">
... prelinguistic quality space ... was molded mainly by the progressive effects on the child of our tests themselves.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
§18. PHONETIC NORMS

</div>,

      ]}
    />
  ),
};
