import { EntryBody } from "../components/Entry/EntryBody";

export const infinite_mesh_manual: { [id: string]: any } = {
  id: "infinite_mesh",
  title: (
    <>
      Infinite Mesh: Desperate Attempt at Dialogue through the Whole of Human
      Structure
    </>
  ),
  date: "9 April 24",

  Body: (
    <EntryBody
      paragraphs={[
        <div className="font-mono"></div>,

        <p className="text-center">
          [
          <a
            className="font-mono underline"
            href="https://infinitemesh.co"
            target="_blank"
          >
            Click to visit the Infinite Mesh.
          </a>
          ]
        </p>,
        <div className="font-mono"></div>,

        <div className="font-mono">
          The Internet contains representative structures of human Thought, just
          as Books did before.
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          These are Bodies structured by human activity. Structure is connection
          that is necessary. [Language necessarily has structure, for humans to
          connect words in speaking.]
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">These structured Bodies are Meshes.</div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          Meshes can be traversed [as one visits a website or reads a book]. In
          this traversing, the Mesh is passive, it is traversed. The journey is
          also slow and local: one is in one spot of the Mesh.
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">What is to make a Mesh Infinite, then?</div>,

        <div className="font-mono"></div>,

        <div className="flex flex-row justify-around p-3">
          {/* image with src=src */}
          <img
            className="border w-24"
            src="https://speculative-monster-storage.s3.eu-west-2.amazonaws.com/mesh_coin.webp"
            alt="mesh_coin"
          />
          <img
            className="border  w-24"
            src="https://speculative-monster-storage.s3.eu-west-2.amazonaws.com/mesh_ruler.webp"
            alt="mesh_ruler"
          />
          <img
            className="border  w-24"
            src="https://speculative-monster-storage.s3.eu-west-2.amazonaws.com/mesh_hand.webp"
            alt="mesh_hand"
          />
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">First, what makes a Mesh Finite?</div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          Traversing is a local mode of interaction between one and the Mesh,
          one can never take it as a whole, because one is somewhere.
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          Something written in a future has a limited scope of interaction with
          something written in the past (think of books). And even more, this
          interaction has to be mediated by someone that is somewhere in this
          Mesh, and a someone can only traverse a limited amount of Mesh.
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          In Dialog, we traverse eachother globally: a response is not a local
          Idea, but a chaos of many Ideas of the respondant.
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          Generative Language and Image Models (GLIM) are trained on vast
          Meshes, designed to be a model of the Mesh as a whole.
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          GLIMs generate given something. But generate from the whole of the
          Mesh, in an uncertain way. GLIMs cannot be traversed because they are
          not passive, they actively connect the Mesh as a whole (in modeling).
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          It is a Dialog because what is generated is chaotic, because it draws
          from everywhere. The Internet is no longer a webpage, or the Books are
          no longer a tome, one is not somewhere passive that can be read off,
          now one gets the bizarre Dialog of modeling a Mesh as a whole.
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          The structures in these Meshes are familiar to us, because they are
          either records or causes of the connections in our context.
          Interacting with them beyond traversing... is new in History! Epic?!
        </div>,
      ]}
    />
  ),
};
