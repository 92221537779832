import { FC, useState, SetStateAction, createContext } from "react";
import { AppNavbar } from "./Navbar/AppNavbar";
import { AppHeader } from "./AppHeader";
import { AppFooter } from "./AppFooter";
import { Routing } from "../Routing/Routing";
import { FaTools, FaSmile } from "react-icons/fa";
import { IoNewspaperSharp } from "react-icons/io5";
import { BsFillPersonLinesFill } from "react-icons/bs";
import {
  AppShell,
  Navbar,
  Header,
  Footer,
  MediaQuery,
  Burger,
} from "@mantine/core";
import type { IconType } from "react-icons";
import { HorizontalNavbar } from "./Navbar/HorizontalNavbar";
import { RiArticleFill } from "react-icons/ri";

type ShellProps = {
  // navigation: NavType[];
};

export interface NavType {
  icon: IconType;
  label: string;
  initiallyOpened?: boolean;
  links?: { label: string; link: string }[];
  to?: string;
}
export type NavbarProps = {
  //   navigation: NavType[];
  setOpened?: React.Dispatch<SetStateAction<boolean>>;
  navbarRoutes: NavType[];
};
const navbarRoutes: NavType[] = [
  {
    label: "Learn to Play",
    icon: BsFillPersonLinesFill,
    to: "learn",
  },
  {
    label: "Cards",
    icon: FaTools,
    to: "cards",
  },
];
// const ScrollRefsContext = createContext<any>(null);

export const Shell: FC<ShellProps> = ({}) => {
  const [opened, setOpened] = useState(false);
  return (
    <AppShell
      footer={
        <Footer height={40} p="md">
          <AppFooter />
        </Footer>
      }
      header={
        <Header
          height={{ base: 160, sm: 100, md: 100, lg: 100 }}
          p="md"
          withBorder={false}
        >
          <AppHeader />
        </Header>
      }
      // aside={<></>}
    >
      {/* Routing to App content */}
      <div className="content">
        <Routing />
      </div>
    </AppShell>
  );
};
