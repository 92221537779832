
import { EntryBody } from "../components/Entry/EntryBody";

export const talking_and_walking: { [id: string]: any } = {

    id: "talking_and_walking",
    title: <>Talking & Walking</>,
    date: "6 October 2024",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
Note: Verbatim transcription of notebook notes written during a delayed night flight

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
language models, especially through ai-chat interfaces, generate language that takes into account that which is implicated in the linguistic context

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
one example; one can paste a chunk of non-functioning code without any instructions attached to it & most SOTA systems will generate text that at least attempts to solve it.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
it is clear the task of fixing broken code was never made explicit, but rather was 'implicated' maybe in the combination of the system prompt ('be helpful') & the user prompt ('broken code')

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
how do examples like these affect the Gricean picture of langauge?

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
much of such pragmatic work _could_ be done just by modeling language, it is not needed to have access to the rich contect in which utterances are uttered

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
of course it could be the case tha humans _do_ rely on a rich context to resolve, for example. implicatures, rather than "simply" relying on a linguistic-muscle-memory we can call model

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
which is the case?

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
consider walking. one hardly has any conciouss recollection of any walking one does in a day but if we want to design a walking robot, close examination of a rich-walking-context seemsa a need

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
now what if there is a new puddle on the way to work, sidestepping it may require one"s attention, maybe jumping a bit even

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
there walking has seemed a conciouss activity

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
but what if the puddle stays for several days on the daily route? sidestepping then becomes an activity relegated to walking-muscle-memoty, one loses the recollection of sidestepping the puzzle [i keep this fun typo, should be puddle]

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
here the point i am getting at is that it is not clear to me there is anything of what is traditionally understood as 'reasoning' or 'thinking' even when playing a language game

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
at least, most language games seem to be played by linguistic-muscle-memory alone. when _leanring_ a language game, things are different, as we seem to be (i) more attentive and reliant on the rich context (ii) subject to a quick feedback loop on our language use.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
here reliance means maybe a time consuming linguistic explicitation that includes words related to the context but not the query, maybe mediated by a question.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
if my buddy sends me a chunk of code without context, the first tie i will mediate my response with questions 'what?' 'why are they sending me this?' 'oh they had a deadline innit?' 'i"ll see what this code is about' this will be time-consuming, even if not explicit, and it is the process of making language _explicitly dense_ around a situation, precisely to be able to leverage the linguistic-muscle-memory.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
next time my buddy hits me with a contextless chunk of code i may (i) attempt to solve it (ii) tell him i ain"t got time for that but either response will be part of the language game of asking-for-code-help, which is not makred by any linguistic token whatsoever

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
these subsequent requests, whether they are met with (i) or with (ii), will require less time, language will be sparse, and the denseness of the time we leaarnt the language game is precisely what we call 'knowing how to play the language game!

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
inner monologue feels like a densification of language around the trying to unveil the rules of an unknown language game

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
in humans we find a curious capacity to intuitively understand or grasp many things that upon attempts to formalization are complicated, for example the trajectories of a basketball or balancing a pen between the fingers or recognizing water beetles from 'normal' beetles

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
it would be consistent to expect something similar in language, & a strong linguistic-muscle-memory on langauge games. think of how one learns the previous activities vs how one does them when one has mastered them.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
as such, it is maybe not so surprising that a language model generates language coherent with implicatures, and similar phenomena not explicited at all in language, but this is not so much a testimony of language models being human-intelligent nor 'reasoning' on some strong sense but rather an indication that humans do not do much 'reasoning' in their language use & get away with some linguistic-muscle-memory in most situations;

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
talking is just like walking

</div>,

      ]}
    />
  ),
};
